import axios, {AxiosResponse} from 'axios'
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {CircularProgress} from "@material-ui/core";
import React from 'react';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    progress: {
        position: 'fixed',
        bottom: '30px',
        right: '30px'
    }
})

const AxiosErrorHandler = () => {
    const dispatch = useDispatch()
    const [fetching, setFetching] = useState(false)
    const classes = useStyles()
    useEffect(() => {
        const interceptor = axios.interceptors.response.use((res) => {
            setFetching(false)
            return res
        }, (err) => {
            const res: AxiosResponse = err.response
            switch(res.status) {
                case 403:
                case 401:
                    throw Error('Non sei autorizzato')
                case 500:
                    throw Error('C\'è stato un errore nel server')
            }
            setFetching(false)
            throw Error(err)
        })

        return () => axios.interceptors.response.eject(interceptor)
    }, [dispatch])

    useEffect(() => {
        axios.defaults.onUploadProgress = () => {
            setFetching(true)
        }
        return () => axios.defaults.onUploadProgress = undefined
    }, [])

    return <span className={classes.progress}>{fetching && <CircularProgress size={16} />}</span>
}

export default AxiosErrorHandler
