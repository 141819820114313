import {AclGroup} from "../types/aclGroup";
import {AppThunk} from "../../App";
import axios from "axios";
import {List} from 'immutable';

export type SetCurrentGroup = {
    type: 'acl-group/setCurrentGroup',
    payload: AclGroup | null
}

export type SetGroups = {
    type: 'acl-group/setGroups',
    payload: List<AclGroup>
}

export const setGroups = (g: List<AclGroup>): SetGroups => {
    return {
        type: 'acl-group/setGroups',
        payload: g
    }
}

export const setCurrentGroup = (g: AclGroup | null): SetCurrentGroup => {
    return {
        type: 'acl-group/setCurrentGroup',
        payload: g
    }
}

export const createGroup = (g: AclGroup): AppThunk => async dispatch => {
    const res = await axios.post('/acl-groups', g)
    dispatch(setCurrentGroup(res.data))
    dispatch(fetchAclGroups())
}

export const deleteGroup = (g: AclGroup): AppThunk => async (dispatch) => {
    await axios.delete(`/acl-groups/${g._id}`)
    dispatch(fetchAclGroups())
}

export const updateGroup = (g: AclGroup): AppThunk => async (dispatch, getState) => {
    const state = getState()
    const group = g || state.auth.me
    if(!group?._id) {
        throw Error('Can\'t modify a group without "_id" ')
    }
    await axios.put(`/acl-groups/${group._id}`, g)
    fetchAclGroups()
}

export const fetchAclGroups = (g: AclGroup | null = null): AppThunk => async (dispatch, getState) => {
    const state = getState()
    const group = g || state.auth.me
    if(!group?._id) {
        throw Error('Can\'t modify a group without "_id" ')
    }

    const res = await axios.get(`/acl-groups`)
    const data: AclGroup[] = res.data

    dispatch(setGroups(List([...data])))
}



export type Actions = SetCurrentGroup | SetGroups
