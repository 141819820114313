import React from 'react'
import {Collapse, createStyles, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useSelector} from "react-redux";
import {getHasFetched, getIsFetching} from "../../store/selectors";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
            borderBottomRightRadius: '0.25rem',
            borderBottomLeftRadius: '0.25rem',
            backgroundColor: 'white',
            color: 'black',
            position: 'absolute',
            top: '50px',
            right: 0,
            width: '70ch',
            maxHeight: '40ch',
            display: 'flex',
            alignItems: 'start',
            flexFlow: 'row',
            overflow: 'auto'
        },
        container: {
            width: '100%',
        },
    })
);

const Index: React.FC = () => {
    const classes = useStyles();

    const hasFetched = useSelector(getHasFetched)
    const isFetching = useSelector(getIsFetching)

    return (
        <Collapse className={classes.wrapper} classes={{wrapper: classes.container}} in={hasFetched && !isFetching}>
            {/*<SearchResults />*/}
        </Collapse>
    )
}

export default Index
