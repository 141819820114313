import {AppBar, IconButton, Toolbar} from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu'
import React from "react";
import Logout from "../../../auth/components/Logout";
import styled from "styled-components";
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {getShowSidebar} from "../../store/selectors";
import {Close} from "@material-ui/icons";
import {setShowSidebar} from "../../store/actions";
import Sidebar from "../Sidebar";
import UserIsAuthenticated from "../../../auth/components/UserIsAuthenticated";
import Search from "../../../search/container/Search";
import {getMe} from "../../../auth/store/selectors";
import NetworkBanner from '../../../network/components/NetworkBanner'
import {useIsOnline} from '../../../network/utils'

const Right = styled.div`
margin-left: auto;
display: flex;
flex-flow: row;

.username {
  display: flex;
  align-items: center;
}
`

const Header: React.FunctionComponent<RouteComponentProps> = ({location}) => {
    const dispatch = useDispatch()
    const showSidebar = useSelector(getShowSidebar)
    const toggleSidebar = () => {
        dispatch(setShowSidebar(!showSidebar))
    }
    const me = useSelector(getMe)
    const online = useIsOnline()

    return <>
        <AppBar>
        <Toolbar>
            {online && <IconButton color='inherit' onClick={toggleSidebar}>
                { showSidebar ? <Close /> : <MenuIcon /> }
            </IconButton>}
            <Right>
                <UserIsAuthenticated>
                    <div className="username">
                        {me && me.username}
                    </div>
                    <Search />
                    <Logout />
                </UserIsAuthenticated>
            </Right>
        </Toolbar>
        </AppBar>
        <NetworkBanner/>
        <UserIsAuthenticated>
            <Sidebar />
        </UserIsAuthenticated>
    </>
}

export default withRouter(Header)
