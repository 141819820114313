import {useDispatch, useSelector} from "react-redux";
import {getEvents, getFrom, getPage, getPerPage, getTo} from "../store/selectors";
import {useCallback, useEffect, useState} from "react";
import {fetchEvents} from "../store/actions";
import {DirectoryEvent} from "../../content-manager/types/directoryEvent";
import {FileEvent} from "../../content-manager/types/fileEvent";
import {List} from 'immutable'


const useEvents = (): [List<FileEvent | DirectoryEvent>, boolean, () => Promise<void>] => {
    const dispatch = useDispatch()
    const from = useSelector(getFrom)
    const to = useSelector(getTo)
    const page = useSelector(getPage)
    const perPage = useSelector(getPerPage)
    const [fetching, setFetching] = useState(false)
    const events = useSelector(getEvents)

    const fetch = useCallback(async () => {
        setFetching(true)
        await dispatch(fetchEvents())
        setFetching(false)
    }, [dispatch])

    useEffect(() => {
        fetch()
    }, [perPage, page, from, to, fetch])

    return [events, fetching, fetch]
}

export default useEvents
