import {RootState} from "../../../../App";
import { createSelector } from 'reselect'
import { List } from 'immutable'
import {IFile} from "../../../types/file";

const state = (state: RootState) => state["file-events"]

export const getEvents = createSelector(
    state,
    state => state.events
)

export const getCurrentFile = createSelector(
    state,
    state => state.currentFile
)

export const getCurrentFileEvents = createSelector(
    [getEvents, getCurrentFile],
    (events, currentFile) => currentFile ? events
        .filter(event => event.file._id === currentFile._id) : List([])
)

export const makeFileEventsSelector = (file: IFile) => createSelector(
    getEvents,
    events => events.filter(i => i.file._id === file._id)
)

export const makeFileEventByIdSelector = (id: string) => createSelector(
    getEvents,
    events => events.find(i => i._id === id)
)
