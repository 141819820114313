import {Permissions} from 'roles-and-permissions/types/permission'
import React, {ChangeEvent, memo} from "react";
import { ExpandMore } from '@material-ui/icons'
import {Accordion, AccordionSummary, Checkbox, Divider, FormControlLabel, Typography} from '@material-ui/core'
import {makeStyles} from "@material-ui/core/styles";

const useStyle = makeStyles({
    wrapper: {
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gridColumnGap: '10px',
        gridRowGap: '10px',
        padding: '10px'
    },
    label :{
        paddingLeft: '10px'
    },
    divider: {
        marginBottom: '10px'
    }
})


const PermissionsForm: React.FC<{permissions: Permissions, className?: string, onChange: (p: ChangeEvent) => any}> = ({ permissions, className, onChange }) => {
    const classes = useStyle()

    return <div className={className}>
        <Typography align='left'>Permessi</Typography>
        {Object.keys(permissions).map(plugin => <Accordion key={plugin}>
            <AccordionSummary
                expandIcon={<ExpandMore />}
            >
                <Typography>{plugin}</Typography>
            </AccordionSummary>
            <>
                {
                    Object.keys(permissions[plugin].controllers).map((controllerName, index) => {
                            return <div key={plugin + controllerName}>
                                {Object.keys(permissions[plugin].controllers).length !== 1 && <Typography className={classes.label} align='left'>{controllerName}</Typography>}
                                <div className={classes.wrapper}>
                                    {Object.keys(permissions[plugin].controllers[controllerName]).map(actionName => <FormControlLabel
                                        key={plugin + controllerName + actionName}
                                        label={actionName}
                                        control={
                                        <Checkbox
                                            checked={permissions[plugin].controllers[controllerName][actionName].enabled}
                                            name={`permissions.${plugin}.controllers.${controllerName}.${actionName}.enabled`}
                                            onChange={onChange}
                                        />
                                    } />)}
                                </div>
                                {Object.keys(permissions[plugin].controllers).length !== 1 &&
                                index !== Object.keys(permissions[plugin].controllers).length - 1 && <Divider className={classes.divider} />}
                                </div>
                    })
                }
                {}
            </>
        </Accordion>)}
    </div>
}

export default memo(PermissionsForm)

