import React, {useCallback, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getCurrentGroup} from "../../store/selectors";
import {Dialog, DialogContent, DialogTitle, Divider} from "@material-ui/core";
import UsersOrGroupsSelector from "../UsersOrGroupsSelector";
import {AclRule, UnsavedAclRule} from "../../../acl.old/types/aclRule";
import {isAclGroup} from "../../../content-manager/helpers/typeGuards";
import {AclGroup} from "../../../acl.old/types/aclGroup";
import {User} from "../../../auth/types/user";
import {addRuleToGroup, setCurrentAclRuleGroupId} from "../../store/actions";
import RulesSelector from "../RulesSelector";
import {makeStyles} from "@material-ui/core/styles";
import RuleEditor from "../RuleEditor";

const useStyles = makeStyles({
    wrapper: {
        width: '400px',
        display: 'flex',
        flexDirection: 'column'
    },
    divTop: {
        marginTop: '10px'
    },
    divBot: {
        marginBottom: '10px'
    },
    list: {
        height: '300px',
        overflow: 'auto'
    }
})

const DirectoryAclControls: React.FC = () => {
    const classes = useStyles()
    const currentGroup = useSelector(getCurrentGroup)
    const [currentRule, setCurrentRule] = useState<AclRule | null>(null)
    const dispatch = useDispatch()
    const onSelect = useCallback((entity: AclGroup | User) => {
        if(currentGroup) {
            const rule: UnsavedAclRule = {
                acl_rule_group: null,
                delete: false,
                manage: false,
                read: false,
                user: isAclGroup(entity) ? null : entity,
                write: false,
                acl_group: isAclGroup(entity) ? entity : null
            }
            dispatch(addRuleToGroup(rule, currentGroup))
        }
    },[currentGroup, dispatch])

    return <Dialog open={!!currentGroup} onClose={() => dispatch(setCurrentAclRuleGroupId(null))}>
        {currentGroup && <>
            <DialogTitle>Gestione Permessi {currentGroup?.directory ? `cartella "${currentGroup?.directory?.name}"`: `file "${currentGroup?.file?.name}"`}</DialogTitle>
            <DialogContent className={classes.wrapper}>
                <UsersOrGroupsSelector onSelect={onSelect} />
                <Divider className={classes.divTop} />
                <RulesSelector className={classes.list} currentRule={currentRule} onSelect={v => setCurrentRule(v)}/>
                <Divider className={classes.divBot} />
                <RuleEditor rule={currentRule}/>
            </DialogContent>
        </>}
    </Dialog>
}

export default DirectoryAclControls
