import React from "react";
import {
    Button,
    Dialog, DialogActions,
    DialogTitle,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText
} from "@material-ui/core";
import GroupIcon from "@material-ui/icons/Group";
import DeleteIcon from "@material-ui/icons/Delete";
import {AclGroup} from "../../types/aclGroup";

type Props = {
    onClick: () => void,
    group: AclGroup,
    onDeleteClick: () => void,
    open: boolean,
    onDeleteClose: () => void,
    onDeleteCancel: () => void,
    onDeleteConfirm: () => void
}

const View = ({group, onClick, onDeleteCancel, onDeleteClick, onDeleteClose, onDeleteConfirm, open}: Props) => {
    return (
        <>
            <ListItem button onClick={onClick}>
                <ListItemIcon><GroupIcon /></ListItemIcon>
                <ListItemText primary={group.name} />
                <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" onClick={onDeleteClick}>
                        <DeleteIcon/>
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            <Dialog open={open} onClose={onDeleteClose}>
                <DialogTitle>{"Sei sicuro di voler eliminare questo gruppo?"}</DialogTitle>
                <DialogActions>
                    <Button onClick={onDeleteCancel} color="primary">
                        No
                    </Button>
                    <Button onClick={onDeleteConfirm} color="primary" autoFocus>
                        Si
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default View
