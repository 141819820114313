import {RootState} from "../../../App";
import {progress} from "../../store/selectors";
import {connect, ConnectedProps} from "react-redux";
import React from "react";
import {CircularProgress, Paper, Snackbar} from "@material-ui/core";
import styled from "styled-components";

const mapState = (state: RootState) => ({
    progress: progress(state)
})

const connector = connect(mapState)

type Props = ConnectedProps<typeof connector>

const Wrapper = styled(Paper)`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  
  .loading-indicator {
    margin-right: 10px;
  }
`

const ProgressIndicator = ({progress}: Props) => {
    return <Snackbar open={progress.show} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
        <Wrapper>
            <CircularProgress className='loading-indicator' size={20} />
            {progress.message}
        </Wrapper>
    </Snackbar>
}

export default connector(ProgressIndicator)
