import React, {useState} from 'react'
import {Directory} from "../../types/directory";
import View from "./view";
import {RootState} from "../../../App";
import {makeChildrenDirectoriesSelector} from "../../store/selectors";
import {connect, ConnectedProps} from "react-redux";
import {setCurrentDirectory} from "../../store/actions";
import {ThunkDispatch} from 'redux-thunk'
import {RouteComponentProps, withRouter} from 'react-router-dom'
import {useDirectoryDrop} from "../../hooks/useDirectoryDrop";


const mapState = (state: RootState, props: ComponentProps) => {
    return {
        childrenDirectories: makeChildrenDirectoriesSelector(props.directory)(state)
    }
}

const mapActions = (dispatch: ThunkDispatch<any,any,any>) => {
    return {
        setCurrentDirectory: (d: Directory) => dispatch(setCurrentDirectory(d)),
    }
}

const connector = connect(mapState, mapActions)

type ComponentProps = { directory: Directory }
type Props =  ComponentProps & ConnectedProps<typeof connector> & RouteComponentProps

const FileTreeDirectoryItem: React.FunctionComponent<Props> = ({ directory, childrenDirectories, setCurrentDirectory, history }) => {
    const [open, setOpen] = useState(false)
    const onCollapseToggle = () => {setOpen(!open)}
    const onClick = () => {
        setCurrentDirectory(directory)
        history.push(`/${directory._id}`)
    }

    const [{ isOver }, drop] = useDirectoryDrop(directory, (monitor) => ({isOver: monitor.isOver()}))

    return (
        <View ref={drop} selected={isOver} directory={directory} open={open} childrenDirectories={childrenDirectories} onClick={onClick} onCollapseToggle={onCollapseToggle} />
    )
}


export default withRouter(connector(FileTreeDirectoryItem))
