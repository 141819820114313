import React, {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getIsFetching, getSearchResults, getSearchText} from "../../store/selectors";
import {
    Card,
    Checkbox,
    List,
    ListItem,
    Size,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Toolbar
} from "@material-ui/core";
import {SearchResult} from "../../store/state";
import FileContextMenu from "../../../content-manager/components/FileContextMenu";
import {getSelectedFiles, makeIsSelected} from "../../../content-manager/store/selectors";
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import {setSelectedFiles, toggleFile} from "../../../content-manager/store/actions";
import {fetchSearchResults, setSearchText} from "../../store/actions";
import {makeStyles} from "@material-ui/core/styles";


const ResultRow: React.FC<{ file: SearchResult}> = ({ file }) => {

    const selected = useSelector(makeIsSelected(file))
    const dispatch = useDispatch()
    const select = useCallback(() => {
        dispatch(toggleFile(file))
    }, [dispatch, file])

    return <FileContextMenu renderTag={'tr'} file={file}>
        <TableCell>
            <Checkbox checked={selected} onChange={() => select()}/>
        </TableCell>
        <TableCell>
            <span>{file.name + file?.file?.ext}</span>
        </TableCell>
         <TableCell>
             <Link to={`/${file.parents[file.parents.length - 1]._id}`}>{'/' + file.parents.map(p => p.name).join('/')}</Link>
         </TableCell>
         </FileContextMenu>
}

const Head = () => {

    const selected = useSelector(getSelectedFiles)
    const dispatch = useDispatch()
    const result = useSelector(getSearchResults)
    const allSelected = selected.size === result.length
    const onToggle = useCallback(() => {
        dispatch(setSelectedFiles( allSelected ? [] : result))
    }, [dispatch, allSelected, result])

    return <TableHead>
        <TableCell>
            <Checkbox onChange={onToggle} checked={allSelected} indeterminate={!allSelected && selected.size !== 0} />
        </TableCell>
        <TableCell>
            Nome
        </TableCell>
        <TableCell>
            Percorso
        </TableCell>
    </TableHead>
}

const useStyle = makeStyles({
    top: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        '&>*:first-child': {
            marginBottom: '5px'
        }
    }
})

const SearchPage = () => {

    const { text } = useParams<{ text: string }>()
    const dispatch = useDispatch()
    const result = useSelector(getSearchResults)
    const fetching = useSelector(getIsFetching)
    useEffect(() => {
        dispatch(setSearchText(text))
        dispatch(fetchSearchResults())
    }, [dispatch, text])
    const searchText = useSelector(getSearchText)
    const { top } = useStyle()

    return fetching ? <span>Cercando...</span> :
        <Card>

            <Toolbar>
                <div className={top}>
                    <span>
                        {`Risultati di ricerca per: ${searchText}`}
                    </span>
                    {result.length > 0 &&
                    <span>Trovati <b>{result.length}</b> risultati</span>
                    }
                </div>
            </Toolbar>
                <Table size={'small'}>
                    <Head />
                    <TableBody>
                        {result.length > 0 ? result.map(f => <ResultRow key={f.id} file={f} />) : <TableRow><TableCell colSpan={3}>Nessun risultato trovato</TableCell></TableRow> }
                    </TableBody>
                </Table>
        </Card>
}

export default SearchPage
