import {AclGroup} from "acl.old/types/aclGroup";
import {User} from "auth/types/user";
import {useSelector} from "react-redux";
import {aclGroups as getAclGroups} from "acl.old/store/selectors";
import {useAvailableUsers} from "../../../content-manager/hook/useAvailableUsers";
import {getCurrentGroup} from "../../store/selectors";
import {AclRule} from "../../../acl.old/types/aclRule";
import {Autocomplete} from "@material-ui/lab";
import React, {useCallback, useState} from "react";
import {isUser} from "../../../auth/helpers/isUser";
import {Button, ListItemIcon, TextField} from "@material-ui/core";
import {People, Person} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import {getMe} from '../../../auth/store/selectors'
import {useCanShowItem} from '../../hooks/useCanShowItem'

interface Params {
    onSelect: (entity: AclGroup | User) => any
}

const useStyles = makeStyles({
    wrapper: {
        display: 'grid',
        gridTemplateColumns: '.6fr .4fr',
        gridColumnGap: '10px'
    },
})

const UsersOrGroupsSelector: React.FC<Params> = ({ onSelect }) => {
    const [pendingValue, setPendingValue] = useState<AclGroup | User | null>(null)
    const rules: AclRule[] = useSelector(getCurrentGroup)?.rules || []

    const aclGroups = useSelector(getAclGroups).filter(g => !rules.some(rule => rule.acl_group === g._id)).toJS()
    const users = useAvailableUsers().filter(u => !rules.some(rule => rule.user === u.id))
    const options = [...aclGroups, ...users]
    const classes = useStyles()
    const showOption = useCanShowItem()
    const onButtonClick = useCallback(() => {
        if(pendingValue) {
            onSelect(pendingValue)
        }
        setPendingValue(null)
    }, [onSelect, pendingValue])

    return <div className={classes.wrapper}>
    <Autocomplete
    value={pendingValue}
    onChange={(_, val) => setPendingValue(val)}
    options={options.filter(showOption)}
    getOptionLabel={(i: User | AclGroup) => isUser(i) ? i.username : i.name}
    renderInput={(params) => {
        return <TextField {...params} />
    }}
    renderOption={option =>
        <>
            <ListItemIcon>
                {isUser(option) ? <Person /> : <People /> }
            </ListItemIcon>
            { isUser(option) ? option.username : option.name}
        </>
    }
    />
    <Button variant='contained' color='primary' onClick={onButtonClick} disabled={!pendingValue}>Aggiungi</Button>
    </div>
}

export default UsersOrGroupsSelector
