import networkStore from './store'
import {Action} from './actions'

export default function (state = networkStore, actions: Action): typeof networkStore {
  switch (actions.type) {
    case 'network/setOnline':
      return state.set('online', actions.payload)
  }
  return state
}
