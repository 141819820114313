import React, {useEffect} from "react";
import {useParams} from 'react-router-dom'
import {useDispatch, useSelector} from "react-redux";
import {fetchUserById, fetchUsers} from "../../userStore/actions";
import {makeStyles} from "@material-ui/core/styles";
import {currentUser} from "../../../auth/store/selectors";
import UsersSidebar from "../../components/UsersSidebar";
import UserForm from "../../components/UserForm";

const useStyle = makeStyles({
    wrapper: {
        display: 'grid',
        gridTemplateColumns: '.3fr .7fr',
        gridColumnGap: '10px',
        paddingTop: '20px'
    }
})

const UsersManager: React.FC = () => {
    const {id} = useParams<{id: string | undefined}>()
    const dispatch = useDispatch()
    const { wrapper } = useStyle()
    const me = useSelector(currentUser)

    useEffect(() => {dispatch(fetchUsers())}, [dispatch])

    useEffect(() => {
        if(id && me && id !== 'create') {
            dispatch(fetchUserById(id))
        }
    }, [dispatch, id, me])

    return <div className={wrapper}>
            <UsersSidebar />
            { id && <UserForm/> }
        </div>
}

export default UsersManager
