import React, {useState} from "react";
import {useSelector} from "react-redux";
import {getUsers} from "../../userStore/selectors";
import {Link, useParams} from 'react-router-dom'
import {Button, List, ListItem, ListItemIcon, TextField} from "@material-ui/core";
import {getMe} from "../../../auth/store/selectors";
import {makeStyles} from "@material-ui/core/styles";
import {Person} from "@material-ui/icons";

const useStyle = makeStyles({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        paddingLeft: '10px',
        '& > ul' : { width: '100%' },
    },
    input: { marginLeft: '10px'}
})

const UsersSidebar: React.FC = () => {
    const { wrapper, input } = useStyle()
    const { id } = useParams<{id: string}>()
    const me = useSelector(getMe)
    const users = useSelector(getUsers).filter(user => me?.role.name === 'Admin' && typeof user.role !== 'string' ? user.role?.type !== 'superadmin' : true)
    const [search, setSearch] = useState('')

    return <div className={wrapper}>
        { id !== 'create' && <Link to='/users/create' ><Button>Crea Utente</Button></Link> }
        <TextField className={input} placeholder='Cerca...' value={search} onChange={e => setSearch(e.target.value)} />
        <List>
            {users
            .filter(i => search.length === 0 || i.username.toLowerCase().includes(search.trim().toLowerCase()))
            .sort((a,b) => (a.username < b.username) ? -1 : (a.username > b.username) ? 1 : 0)
            .map(user => <Link key={user._id} to={`/users/${user._id}`}>
                <ListItem selected={id === user._id}>
                    <ListItemIcon><Person /></ListItemIcon>
                    {user.username} {me?._id === user._id ? <b>(Tu)</b> : ''}</ListItem>
            </Link>)}
        </List>
        </div>
}

export default UsersSidebar
