import React, {memo, useCallback, useEffect, useRef} from 'react'
import {Directory} from "../../types/directory";
import styled from "styled-components";
import FileUpload from "../FileUpload";
import FileItem from "../FileItem";
import Breadcrumbs from "../Breadcrumbs";
import FileViewerActions from "../FileViewerActions";
import {Divider} from "@material-ui/core";
import {IFile} from "../../types/file";

import DirectoryItem from "../DirectoryItem";
import {useDispatch, useSelector} from 'react-redux'
import {highlightedFile, scrollListTo} from '../../store/selectors'
import {highlightFile, setScrollListTo} from '../../store/actions'

type Props = {
    directory: Directory,
    childrenDirectories: Directory[]
    files: IFile[]
}


const ItemsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(6, calc((100% - 50px) / 6));
  @media screen and (max-width: 1024px) {
      grid-template-columns: repeat(3, calc((100% - 20px) / 3));
  }
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  margin: 15px 0;
  margin-right: 10px;
`

const SBreadcrumbs = styled(Breadcrumbs)`
  font-size: 1.2em;
  & ol {
      height: 100%;
  }
`

const TopWrapper = styled.div`
  flex: 0 0 auto;
  margin: 15px 5px 0 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`

const Wrapper = styled.div`
  height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  .items {
    overflow-y: auto;
    flex: 0 1 auto;
  }
`

const View: React.FC<Props> = ({directory, childrenDirectories, files}) => {

    const ref = useRef<HTMLDivElement>(null)
    const dispatch = useDispatch()
    const highlighted = useSelector(highlightedFile)
    const resetHighlighted = useCallback(() => {
      dispatch(highlightFile(undefined))
    }, [dispatch])

    useEffect(() => {
      if(!ref.current) return
      if(highlighted === undefined || highlighted.length === 0) return
      const el = ref.current.querySelector(`#file-item-${highlighted[0]}`)
      if(el === null) return
      const elRect = el.getBoundingClientRect()
      const { top } = ref.current.getBoundingClientRect()
      ref.current.scrollTo({ top: elRect.top - top })
      setTimeout(() => resetHighlighted(), 5000)
    }, [ref, resetHighlighted, highlighted])

    return (
        <Wrapper>
            <TopWrapper>
                <SBreadcrumbs directory={directory}/>
                <FileViewerActions/>
            </TopWrapper>
          <Divider />
          <div className='items' ref={ref}>
            <ItemsWrapper >
              {childrenDirectories
                .sort((a, b) => (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0)
                .map(dir => <DirectoryItem key={dir._id} directory={dir}/>)
              }
            </ItemsWrapper>
            <FileUpload withText directory={directory} >
              <ItemsWrapper>
                {files.sort((a,b) => (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0).map(file =><FileItem key={file._id} file={file} />)}
              </ItemsWrapper>
            </FileUpload>
          </div>
        </Wrapper>
    )
}

export default memo(View)
