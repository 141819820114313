import {RootState} from "../../App";
import {createSelector} from 'reselect'

export const state = (s: RootState) => s.user

export const users = createSelector(
    state,
    (state) => state.get('users')
)

export const getUsers = createSelector(
    state,
    state => state.users
)

export const makeGetUserById = (id: string) => createSelector(
    users,
    users => users.find(i => i._id === id)
)
