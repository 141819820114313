import {useDispatch, useSelector} from "react-redux";
import {users as getUsers} from '../../users/userStore/selectors'
import {useEffect} from "react";
import {fetchUsers} from'../../users/userStore/actions'
import {User} from "../../auth/types/user";

export const useAvailableUsers = (): User[] => {
    const dispatch = useDispatch()
    const users = useSelector(getUsers)
    useEffect(() => {
        if(users.length === 0) {
            dispatch(fetchUsers())
        }
    }, [users, dispatch])
    return users
}
