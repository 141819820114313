import rolesAndPermissionsStore from "./store";
import {Actions} from "./actions";
import {SavedRole} from "../types/role";
import { List } from 'immutable'


const addRole = (roles: List<SavedRole>, role: SavedRole): List<SavedRole> => {
    const index = roles.findIndex(r => r.id === role.id)
    if(index >= 0) {
        return roles.set(index, role)
    }
    return roles.push(role)
}

export default function (state = rolesAndPermissionsStore, action: Actions): typeof rolesAndPermissionsStore {
    switch (action.type) {
        case "getRoles-and-permissions/addRole":
            return state.set('roles', addRole(state.roles, action.payload))
        case "getRoles-and-permissions/removeRole":
            return state.set('roles', state.roles.filter(r => r.id !== action.payload.id))
        case "getRoles-and-permissions/resetStore":
            return state.set('roles', List([]));
        default:
            return state
    }
}
