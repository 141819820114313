import {DropEvent, FileRejection, useDropzone} from "react-dropzone";
import React, {PropsWithChildren, useCallback} from "react";
import Wrapper from './wrapper'
import {Directory} from "../../types/directory";
import {ThunkDispatch} from 'redux-thunk'
import {fetchDirectoryById, uploadFile} from "../../store/actions";
import {connect, ConnectedProps} from "react-redux";
import styled from "styled-components";
import {setProgress} from "../../../ui/store/actions";
import {Button, Divider} from "@material-ui/core";
import {useCan} from "../../../acl/hooks/useCan";
import {useCurrentUserCan} from "../../../roles-and-permissions/hooks/useCurrentUserCan";
import {useIsOnline} from '../../../network/utils'

type onDrop = (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => void;

type ComponentProps = {
    directory: Directory,
    withText?: boolean
}

const mapActions = (dispatch: ThunkDispatch<any,any,any>, props: ComponentProps) => {
    return {
        "uploadFile": (f: File, d: Directory) => dispatch(uploadFile(f, d, false)),
        refreshDirectory: () => dispatch(fetchDirectoryById(props.directory._id)),
        setProgress: (show: boolean, message: string = '') => dispatch(setProgress(show, message))
    }
}

const connector = connect(null, mapActions)

type Props = PropsWithChildren<ComponentProps> & ConnectedProps<typeof connector>

const Borders = styled.div<{show: boolean}>`
  outline: ${props => props.show ? `2px solid ${props.theme.colors.primary}`: ''};
  padding: 5px 0;
  box-sizing: border-box;
`

const SDivider = styled(Divider)`
  margin-bottom: 15px;
`

const FileUpload: React.FC<Props> = ({
     directory,
     uploadFile,
     children,
     refreshDirectory,
     setProgress,
    withText
}) => {
    const can = useCan(directory)
    const canCreateFile = useCurrentUserCan('application', 'file-item', 'create')

    const onDrop = useCallback<onDrop>(async (accepted) => {
        const promises = []
        let counter = accepted.length
        let uploaded = 0
        setProgress(true, `${uploaded}/${counter} caricati`)
        for (const i in accepted) {
            const file = accepted[i]
            // @ts-ignore
            promises.push(uploadFile(file, directory).then(() => {
                uploaded ++
                setProgress(true, `${uploaded}/${counter} caricati`)
            }))
        }

        await Promise.all(promises)
        await refreshDirectory()
        setProgress(false)
    }, [directory, uploadFile, refreshDirectory, setProgress])
    const {isDragActive, getInputProps, getRootProps, open} = useDropzone({onDrop})

    const online = useIsOnline()

    return (can?.write && canCreateFile && online ? <Wrapper {...getRootProps({ onClick: (e) => withText ? e.stopPropagation() : null})}>
        <input {...getInputProps()}/>
        <Borders show={isDragActive}>
            <div onClick={e => e.stopPropagation()}>
                {children}
            </div>
            {withText && <div>
                <SDivider />
                <span>Trascina un file</span><br/>
                <span>o</span><br/>
                <Button onClick={open}>Clicca per caricare</Button>
            </div>}
        </Borders>
    </Wrapper> : <div>
        {children}
    </div>)
}

export default connector(FileUpload)
