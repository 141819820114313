import React, {ErrorInfo} from "react";

export default class ErrorBoundaryHandler extends React.Component<{onError: (a: Error, b: ErrorInfo) => any}> {

    constructor(props: {onError: () => any}) {
        super(props);
        this.state = { hasError: false };
    }

    // @ts-ignore
    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        this.props.onError(error, errorInfo)
    }

    render() {
        return this.props.children;
    }
}
