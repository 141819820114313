import React, {useCallback, useState} from "react";
import {Field, Form, Formik} from "formik";
import {Role} from "../../types/role";
import {useParams, useHistory} from 'react-router'
import {useRole} from "../../hooks/useRole";
import {TextField} from "formik-material-ui";
import {Button} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import PermissionsForm from "../PermissionsForm";
import {isSavedRoleWithRel} from "../../helpers/typeGuards";
import * as yup from 'yup'
import {useDispatch} from "react-redux";
import {createRole, updateRole} from "../../store/actions";
import {MessageType, setMessage} from "../../../ui/store/actions";
import {roleLink} from "../../../links";
import {useCurrentUserCan} from "../../hooks/useCurrentUserCan";

const emptyRole: Role = {description: "", name: "", type: ""}

const useStyles = makeStyles({
    form: {
        padding: '10px',
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridColumnGap: '10px',
        gridRowGap: '10px'
    },
    permissions: {
        gridColumn: '1 / 3'
    }
})

const RoleForm: React.FC = () => {
    const { id } = useParams<{id: string}>()
    const isCreating = id === 'create'
    const currentRole = useRole({ id })
    const classes = useStyles()
    const history = useHistory()
    const dispatch = useDispatch()
    const canUpdate = useCurrentUserCan('users-permissions', 'userspermissions', 'updaterole')
    const canSave = useCurrentUserCan('users-permissions', 'userspermissions', 'createrole')

    const initialValues: Role = isCreating ? emptyRole : currentRole ? currentRole : emptyRole

    const [schema] = useState(yup.object({
        name: yup.string().required('Prego inserire un nome').min(5, 'Il nome deve avere almento 5 caratteri di lunghezza'),
        description: yup.string().notRequired().min(5, 'La descrizione deve avere almento 5 caratteri di lunghezza'),
    }))

    const onSubmit = useCallback(async (values: typeof initialValues) => {
        try {
            if(isCreating) {
                await dispatch(createRole(values))
                history.push(roleLink(''))
            } else {
                if(isSavedRoleWithRel(values)) {
                    await dispatch(updateRole(values))
                } else {
                    dispatch(setMessage({ message: 'Dati inseriti errati', type: MessageType.error}))
                }
            }
            dispatch(setMessage({ message: 'Ruolo Salvato', type: MessageType.success}))
        } catch  {
            dispatch(setMessage({ message: 'C\'è stato un errore durante il salvataggio del ruolo', type: MessageType.error}))
        }
    }, [dispatch, history, initialValues, isCreating])


    return <Formik validationSchema={schema} initialValues={initialValues} enableReinitialize onSubmit={onSubmit} children={
        ({
             isSubmitting,
             isValid,
             dirty,
             submitForm,
            handleChange,
            values
        }) => <Form className={classes.form}>
            <Field
                component={TextField}
                name='name'
                label='Nome'
            />
            <Field
                component={TextField}
                name='description'
                label='Descrizione'
            />
            {isSavedRoleWithRel(values) &&
                <PermissionsForm
                    permissions={values.permissions}
                    onChange={handleChange}
                    className={classes.permissions}
                />
            }
            {((isCreating && canSave) || (!isCreating && canUpdate)) && <Button size='small' onClick={submitForm} disabled={!dirty || isSubmitting || !isValid} variant='contained'>
                Salva
            </Button>}
        </Form>
    }/>
}

export default RoleForm
