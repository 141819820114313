import React, {useState} from "react";
import {Button, Dialog, DialogContent, DialogTitle, TextField} from "@material-ui/core";
import {Add} from "@material-ui/icons";
import styled from 'styled-components'
import { ThunkDispatch } from 'redux-thunk'
import {connect, ConnectedProps} from "react-redux";
import {Message, MessageType, setMessage} from "../../../ui/store/actions";
import {createGroup} from "../../store/actions";

const DialogWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
`

const ButtonWrapper = styled.div`
display: flex;
width: 100%;
justify-content: center;
`

const mapActions = (dispatch: ThunkDispatch<any,any,any>) => {
    return {
        createGroup: (name: string) => dispatch(createGroup({name, users: []})),
        setMessage: (m: Message) => dispatch(setMessage(m))
    }
}

const connector = connect(null, mapActions)
type Props = ConnectedProps<typeof connector>

const CreateGroup = ({createGroup, setMessage}: Props) => {
    const [creating, setCreating] = useState(false)

    const [newGroupName, setNewGroupName] = useState('')

    const onClick = () => {
        setCreating(true)
    }

    const onDialogClose = () => {
        setNewGroupName('')
        setCreating(false)
    }

    const onCreateClick = async () => {
        try {
            await createGroup(newGroupName)
        } catch (e) {
            setMessage({
                message: e.toString(),
                type: MessageType.error
            })

        } finally {
            onDialogClose()
        }
    }

    return (
        <>
            <ButtonWrapper>
                <Button color='primary' variant='outlined'onClick={onClick}>
                    <Add />
                    <span>NUOVO GRUPPO</span>
                </Button>
            </ButtonWrapper>
            <Dialog onClose={onDialogClose} open={creating}>
                <DialogTitle>Inserisci il nome per il nuovo gruppo</DialogTitle>
                <DialogContent>
                    <DialogWrapper>
                        <TextField value={newGroupName} autoFocus onChange={e => setNewGroupName(e.target.value)} label={'Nome'} />
                        <div>
                            <Button onClick={onDialogClose}>Cancella</Button>
                            <Button disabled={newGroupName.length <= 0} onClick={onCreateClick}>Crea</Button>
                        </div>
                    </DialogWrapper>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default connector(CreateGroup)
