import {User} from "auth/types/user";
import {useDispatch, useSelector} from "react-redux";
import {useCallback, useState} from "react";
import {fetchUserEvents as fetchUserFileEvents} from "../../content-manager/store/events/file/actions";
import {fetchUserEvents as fetchUserDirectoryEvents} from "../../content-manager/store/events/directory/actions";
import { Event } from 'content-manager/types/event'
import {makeAllEventsByUserSelector} from "../../content-manager/store/events/selectors";

export const useUserEvents = (u: User, sort: string | null = null): [() => Promise<void>, Event[], boolean] => {
    const dispatch = useDispatch()
    const [fetching, setFetching] = useState<boolean>(false)
    const fetch = useCallback(async () => {
        setFetching(true)
        await Promise.all([
            dispatch(fetchUserFileEvents(u, sort)),
            dispatch(fetchUserDirectoryEvents(u, sort))
        ])
        setFetching(false)
    }, [dispatch, u, sort])

    const userEvents = useSelector(makeAllEventsByUserSelector(u)).toJS()

    return [fetch, userEvents, fetching]
}
