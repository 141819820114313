import { Record, List } from 'immutable'
import {IFile} from "../../../types/file";
import {FileEvent} from "../../../types/fileEvent";

interface Store {
    events: List<FileEvent>,
    currentFile: IFile | null
}

class DirectoryEventsStore extends Record<Store>({
    events: List([]),
    currentFile: null
}) {}

const fileEventsStore = new DirectoryEventsStore()

export default fileEventsStore
