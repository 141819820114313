export type Action = SetOnline
type SetOnline = {
  type: 'network/setOnline',
  payload: boolean
}

export const setOnline = (payload: boolean): SetOnline => ({
  type: 'network/setOnline',
  payload
})
