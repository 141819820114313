import { IFile } from "../../types/file";
import { InsertDriveFile } from "@material-ui/icons";
import styled from "styled-components";
import React, {forwardRef, KeyboardEvent, memo, useEffect, useState} from "react";
import axios from "axios";
import {Checkbox, ClickAwayListener, TextField, Theme, Tooltip} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {tag} from "../../types/tag";
import {useSelector} from "react-redux";
import {getTags} from "../../store/tags/selectors";
import {isTagsArray} from "../../helpers/typeGuards";
import {useIsOnline} from '../../../network/utils'

const isImage = (f: IFile): boolean => {
    if(!f.file) {
        return false
    }
    return [".jpg", ".jpeg", ".png"].includes(f.file.ext);
};
type FileProps = { file: IFile };
type Props = FileProps & {
    onChange: () => any,
    selected: boolean,
    onSubmit: (name: string) => any | Promise<any>,
    canEdit: boolean,
    cutted: boolean,
    tags: tag[],
    isEditing: boolean
    setIsEditing: (v: boolean) => void
    highlighted: boolean
};

const useStyles = makeStyles<Theme, { cutted: boolean }, any>({
    fileName: {
        display: 'block',
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    wrapper: props =>({
        opacity: props.cutted ? '.55' : '1'
    }),
    tagItem: {
        height: '10px',
        width: '10px',
        margin: '2px',
        borderRadius: '50%'
    },
    tagContainer: {
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: 1,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap-reverse',
        height: '100%'
    }
})

const FilePreview = styled.div<FileProps>`
    transition: background .8s;
    &.highlighted {
      background: #c4c4c4;
    }
    position: relative;
    border-radius: 5px;
    padding-top: 63.8%;
    background: ${(props) =>
        isImage(props.file)
            ? props.theme.colors.dark
            : props.theme.colors.light};
    .check {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        ${(props) =>
            isImage(props.file)
                ? `svg {
      fill: ${props.theme.colors.light}
    }`
                : ""}
    }

    .preview {
        position: relative;
    }

    .preview-img {
        margin: 0 auto;
        max-height: 100%;
        max-width: 100%;
    }

    .preview svg {
        font-size: 50px;
        fill: ${(props) => {
            switch (props.file?.file?.ext) {
                case ".pdf":
                    return "#EB1C26";
                case ".xls":
                case ".xlsx":
                case ".csv":
                    return "#1F7244";
                case ".doc":
                case ".docx":
                    return "#3980FB";
                case ".ppt":
                case ".pptx":
                    return "#D04727";
                default:
                    return props.theme.colors.dark;
            }
        }};
    }

    .preview span {
        font-size: 12px;
        color: white;
        position: absolute;
        left: 9px;
        bottom: 7px;
    }

    .preview-container {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

// const Link = styled.a`
//     display: flex;
//     flex-direction: column;
//     text-decoration: none;
//     color: inherit;
//     text-align: left;
// `;

const View = forwardRef<HTMLDivElement, Props>((props, ref) => {
    const { file, onChange, selected, onSubmit, canEdit, cutted, isEditing, setIsEditing, highlighted } = props
    const classes = useStyles({ cutted })

    const selectorTags = useSelector(getTags);
    const fetchCurrentTags = () => {
        let allTags = selectorTags
        let oTags: tag[] = []
        if(!!file && !!file.tags) {
            if(file.tags.length === 0) return oTags
            if(isTagsArray(file.tags)) {
                let tagsId = file.tags.flatMap(ft => ft._id)
                oTags = allTags.filter(t => tagsId.includes(t._id))
            } else {
                // @ts-ignore
                oTags = allTags.filter(t => file.tags.includes(t._id))
            }
        }
        return oTags
    }
    let tags: tag[] = fetchCurrentTags()
    const [name, setName] = useState(file.name)

    useEffect(() => {
        setName(file.name)
    }, [file.name, setName])

    const onNameKeyDown = async (e: KeyboardEvent<HTMLInputElement>) => {
        if(e.key === 'Enter' && !e.shiftKey) {
            await onSubmit(name)
            setIsEditing(false)
        }

        if(e.key === 'Escape') {
            setIsEditing(false)
            setName(file.name)
        }
    }
    const online = useIsOnline()

    return <div className={classes.wrapper} ref={ref} onClick={(e) => e.stopPropagation()} id={`file-item-${file._id}`}>
        <FilePreview file={file} className={highlighted ? 'highlighted' : ''}>
            { online && <Checkbox
                onClick={e => e.stopPropagation()}
                checked={selected}
                onChange={onChange}
                size="small"
                className="check"
                color={"default"}
            />}
            <div className="preview-container">
                {/*TODO: METTERE I PUNTI NEI PUNTI GIUSTI 8px di right ogni punto in piú (16 per due file)*/}
                <div  className={classes.tagContainer}>
                    {
                        !!tags && !!tags.length ?
                            tags.map(tag =>
                                <Tooltip key={tag._id} title={tag.title} placement="right">
                                    <div className={classes.tagItem} key={tag._id} style={{backgroundColor: tag.color, zIndex: 1}} />
                                </Tooltip>
                            ) : null
                    }
                </div>
                {isImage(file) ? (
                    <img
                        className="preview-img"
                        src={axios.defaults.baseURL + file.file.url}
                        alt={file.file.alternativeText || ""}
                    />
                ) : (
                    <div className="preview">
                        <InsertDriveFile />
                            <span>{file?.file?.ext || 'FILE MANCANTE!'}</span>
                    </div>
                )}
            </div>
        </FilePreview>

        {isEditing && online ?
                <TextField
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    onKeyDown={onNameKeyDown}
                    onBlur={() => setIsEditing(false)}
                /> :
                <span title={`${name}${file?.file?.ext || 'FILE MANCANTE!'}`} className={classes.fileName} onDoubleClick={() => canEdit ? setIsEditing(!isEditing) : null}>{`${name}${file?.file?.ext || 'FILE MANCANTE!'}`}</span>
         }
    </div>
});

export default memo(View);
