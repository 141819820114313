import React, {useState} from 'react'
import {ContextMenu, ContextMenuTrigger} from "react-contextmenu";
import {List, ListItem, ListItemIcon, Paper} from "@material-ui/core";
import {CreateNewFolder, Share, Delete, Edit, Group, GetApp, AccessTime} from "@material-ui/icons";
import {v4} from "uuid";
import ConfirmationDialog from "../../../ui/components/ConfirmationDialog";
import PasteIcon from "../../../ui/components/PasteIcon";
import {useIsOnline} from '../../../network/utils'

type Props = {
    onCreateClick: () => any,
    onShareClick: () => any,
    onEditClick: () => any,
    onDeleteClick: () => any,
    onDownloadClick: () => any,
    onPermissionClick: () => any,
    canPaste: boolean
    onPaste: () => any,
    onHistoryClick: () => any,
    canManage: boolean,
    canDelete: boolean,
    canWrite: boolean,
    canViewHistory: boolean,
    numberOfItemsToPaste: number
}

const View: React.FunctionComponent<Props> =
    ({
        children,
        onCreateClick,
        onDeleteClick,
        onDownloadClick,
        onEditClick,
        onPermissionClick,
        onShareClick,
        onPaste,
        canPaste,
        onHistoryClick,
        canDelete,
        canManage,
        canWrite,
        canViewHistory,
        numberOfItemsToPaste
}) => {

    const [showDelete, setShowDelete] = useState(false)

    const id = v4()

    const online = useIsOnline()
    return (
        <div>
            <ContextMenuTrigger id={id}>
                {children}
            </ContextMenuTrigger>
          {online && <ContextMenu id={id}>
                <Paper>
                    <List>
                        {canWrite && <ListItem button onClick={onCreateClick}>
                            <ListItemIcon>
                                <CreateNewFolder />
                            </ListItemIcon>
                            Crea cartella
                        </ListItem> }
                        {canWrite && <ListItem button onClick={onShareClick}>
                            <ListItemIcon>
                                <Share />
                            </ListItemIcon>
                            Condividi
                        </ListItem> }
                        {canWrite && canPaste && <ListItem button onClick={onPaste}>
                            <ListItemIcon>
                                <PasteIcon />
                            </ListItemIcon>
                            {numberOfItemsToPaste > 1 ? `Incolla ${numberOfItemsToPaste} file` : `Incolla`}
                        </ListItem> }
                        {canWrite && <ListItem button onClick={onEditClick}>
                            <ListItemIcon>
                                <Edit />
                            </ListItemIcon>
                            Rinomina
                        </ListItem>}
                        {canDelete && <ListItem button onClick={() => setShowDelete(true)}>
                            <ListItemIcon>
                                <Delete/>
                            </ListItemIcon>
                            Elimina
                        </ListItem>}
                        <ListItem button onClick={onDownloadClick}>
                            <ListItemIcon>
                                <GetApp />
                            </ListItemIcon>
                            Scarica
                        </ListItem>
                        {canManage && <ListItem button onClick={onPermissionClick}>
                            <ListItemIcon>
                                <Group />
                            </ListItemIcon>
                            Permessi
                        </ListItem>}
                        {canViewHistory && <ListItem button onClick={onHistoryClick}>
                            <ListItemIcon>
                                <AccessTime />
                            </ListItemIcon>
                            Storico Azioni
                        </ListItem>}
                    </List>
                </Paper>
            </ContextMenu> }
            <ConfirmationDialog
                open={showDelete}
                message={`Sei sicuro di voler eliminare la cartella?`}
                onConfirm={() => {onDeleteClick(); setShowDelete(false)}}
                onCancel={() => {setShowDelete(false)}}
                onClose={() => {setShowDelete(false)}}
            />
        </div>
    )
}

export default View
