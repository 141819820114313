import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {setOnline} from '../../store/actions'
import {showOfflineMessage, showOnlineMessage} from '../../utils'

const Detector: React.FC = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    window.addEventListener('online', () => {
      dispatch(setOnline(true))
      dispatch(showOnlineMessage())
    })

    window.addEventListener('offline', () => {
      dispatch(setOnline(false))
      dispatch(showOfflineMessage())
    })
  }, [])


  return null
}

export default Detector
