import React from "react";
import Wrapper from './wrapper'
import FileTree from "../FileTree/index";
import CreateDirectory from "../CreateDirectory";
import {useCurrentUserCan} from "../../../roles-and-permissions/hooks/useCurrentUserCan";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    sidebar: {
        display: 'flex',
        flexDirection: 'column',
        padding: '10px 5px',
        textAlign: 'left',
        height: 'calc(100vh - 80px)',
        '& > *:first-child':{
            flex: '0 1 auto',
        },
        '& > *:last-child':{
            flex: '1 1 auto',
        }
    }
})

const SideBar = () => {
    const canCreate = useCurrentUserCan('application', 'directory', 'create')
    const { sidebar } = useStyles()

    return <div className={sidebar}>
        {canCreate && <CreateDirectory />}
        <FileTree />
    </div>
}

export default SideBar
