import {User} from "../../auth/types/user";
import {Record} from 'immutable';

export interface State {
    users: User[]
}

class InitialState extends Record<State>({
    users: []
}) {}

const initialState = new InitialState()
export default initialState
