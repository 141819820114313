import {searchResults} from "../types/searchResults";
import {AppThunk} from "../../App";
import axios from "axios";
import {addDirectories, fetchDirectoryById, setCurrentDirectory} from "../../content-manager/store/actions";
import {Directory} from "../../content-manager/types/directory";
import {IFile} from "../../content-manager/types/file";
import {SearchResult} from "./state";

export type SetSearchText  = {
    type: 'search/setSearchText',
    payload: string
}

export type SetSearchResults = {
    type: 'search/setSearchResults',
    payload: SearchResult[]
}

export type SetIsFetching = {
    type: 'search/setIsFetching',
    payload: boolean
}

export type SetHasFetched = {
    type: 'search/setHasFetched',
    payload: boolean
}

type ResetStore = {
    type: 'search/resetStore',
    payload: boolean
}

export const resetSearchStore = (payload: boolean): ResetStore => ({
    type: "search/resetStore",
    payload
})

export const setSearchText = (s: string): SetSearchText => {
    return {
        type: 'search/setSearchText',
        payload: s
    }
}

export const setSearchResults = (r: SearchResult[]): SetSearchResults => {
    return {
        type: 'search/setSearchResults',
        payload: r
    }
}

export const setHasFetched = (b: boolean): SetHasFetched => {
    return {
        type: 'search/setHasFetched',
        payload: b
    }
}

export const setIsFetching = (b: boolean): SetIsFetching => {
    return {
        type: 'search/setIsFetching',
        payload: b
    }
}

export const fetchSearchResults = (): AppThunk => async (dispatch, getState) => {
    await dispatch(setIsFetching(true))
    const searchText = getState().search.searchText

    if(searchText === '') {
        await dispatch(setIsFetching(false))
        await dispatch(setHasFetched(false))
        await dispatch(setSearchResults([]))
        return
    }

    const files = await axios.post(`/file-items/search/${searchText}`)

    await dispatch(setSearchResults(files.data || []))
    await dispatch(setIsFetching(false))
    await dispatch(setHasFetched(true))
}

export const setSelectedDirectory = (s: string | Directory): AppThunk<Promise<Directory>> => async (dispatch) => {
    let dir
    if(typeof s === 'string') {
        const { data } = await axios.get(`/directories/${s}`)  // directory
        dir = data
    } else {
        const { data } = await axios.get(`/directories/${s._id}`)  // directory
        dir = data
    }

    await dispatch(setCurrentDirectory(dir))
    dispatch(setSearchResults([]))
    return dir
}

export type Actions = SetSearchResults | SetSearchText | SetHasFetched | SetIsFetching | ResetStore
