import { createSelector } from 'reselect'
import {getCurrentDirectoryEvents, getEvents as getDirectoryEvents} from "./directory/selectors";
import {getCurrentFileEvents, getEvents as getFileEvents} from "./file/selectors";
import { Event } from '../../types/event'
import { List } from 'immutable'
import {User} from "../../../auth/types/user";

export const getAllEvents = createSelector(
    [getDirectoryEvents, getFileEvents],
    (dirEvents, fileEvents): List<Event> => dirEvents.merge(fileEvents)
)

export const makeAllEventsByUserSelector = (u: User) => createSelector(
    getAllEvents,
    (events) => events.filter(event => event.user?._id === u._id)
)

export const getCurrentDirectoryAndFileEvents = createSelector(
    [getCurrentDirectoryEvents, getCurrentFileEvents],
    (a, b) => a.merge(b)
)

