import { useSelector} from "react-redux";
import {getCurrentGroup} from "../../store/selectors";
import { List} from "@material-ui/core";
import React, {forwardRef} from "react";
import {AclRule} from "../../../acl.old/types/aclRule";
import RulesSelectorItem from "../RulesSelectorItem";

type Params = {
    currentRule: AclRule | null
    onSelect: (rule: AclRule) => any
    className?: string
}


const RulesSelector = forwardRef<HTMLUListElement, Params>(({ currentRule, className, onSelect }, ref) => {
    const group = useSelector(getCurrentGroup)
    return <List className={className}>
            {group && group.rules
              .map(rule => <RulesSelectorItem key={rule.id} rule={rule} selected={rule.id === currentRule?.id} onSelect={() => onSelect(rule)}/>)}
        </List>
})

export default RulesSelector
