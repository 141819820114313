import React from 'react'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from "@date-io/moment";
import {
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableRow,
    TableHead,
    TableCell,
    Container
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Filters from "../../components/Filters";
import moment from 'moment'
import useEvents from "../../hooks/useEvents";
import {Link} from "react-router-dom";
import {userLink} from "../../../links";
import EventDescription from "../../components/EventDescription";
import {useCanShowItem} from '../../../acl/hooks/useCanShowItem'

const useStyle = makeStyles({
    main: {
        marginTop: '120px'
    }
})

const EventsManager: React.FC = () => {
    const classes = useStyle()

    // const count = useSelector(getCount)
    // const page = useSelector(getPage)
    // const perPage = useSelector(getPerPage)
    const [events] = useEvents()
    // const dispatch = useDispatch()
    // const onChangePage = useCallback((v, newPage) => {
    //     dispatch(setPage(newPage + 1))
    // }, [dispatch])
    // const onChangePerPage = useCallback((v) => {
    //     dispatch(setPerPage(v.target.value))
    // }, [dispatch])



    const canShow = useCanShowItem()

    return <MuiPickersUtilsProvider utils={MomentUtils} locale={'it'}>
        <Container className={classes.main}>
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={3}>
                            <Filters />
                        </TableCell>
                        {/*<TableCell>*/}
                            {/*<TablePagination*/}
                            {/*    rowsPerPageOptions={[5, 10, 20, 50, 100]}*/}
                            {/*    count={count}*/}
                            {/*    page={page - 1}*/}
                            {/*    rowsPerPage={perPage}*/}
                            {/*    onChangePage={onChangePage}*/}
                            {/*    onChangeRowsPerPage={onChangePerPage}*/}
                            {/*/>*/}
                        {/*</TableCell>*/}
                    </TableRow>
                </TableHead>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            Utente
                        </TableCell>
                        <TableCell>
                            Data
                        </TableCell>
                        <TableCell>
                            Azione
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {events.map(event => !canShow(event.user) ? null : <TableRow key={event._id}>
                        <TableCell>
                            {typeof event.user?._id === 'string' && <Link to={userLink(event.user._id)}>
                                {event.user.username}
                            </Link>}
                        </TableCell>
                        <TableCell>
                            {moment(event.createdAt).format('LLLL')}
                        </TableCell>
                        <TableCell>
                            <EventDescription event={event} />
                        </TableCell>
                    </TableRow>)}
                </TableBody>
            </Table>
        </TableContainer>
        </Container>
    </MuiPickersUtilsProvider>
}

export default EventsManager
