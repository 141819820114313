import React from 'react'
import View from "./view";
import {RootState} from "../../../App";
import {directoryToCreate, isCreating} from "../../store/selectors";
import { ThunkDispatch } from 'redux-thunk'
import {createDirectory, DirectoryRequest, setDirectoryToCreate, setIsCreatingDirectory} from "../../store/actions";
import {connect, ConnectedProps} from "react-redux";
import {useIsOnline} from '../../../network/utils'

const mapState = (state: RootState) => {
    return {
        directory: directoryToCreate(state),
        open: isCreating(state)
    }
}

const mapAction = (dispatch: ThunkDispatch<any,any,any>) => {
    return {
        setDirectory: (d: DirectoryRequest) => dispatch(setDirectoryToCreate(d)),
        createDirectory: (d: DirectoryRequest) => dispatch(createDirectory(d)),
        setOpen: (v: boolean) => dispatch(setIsCreatingDirectory(v))
    }
}

const connector = connect(mapState, mapAction)
type Props = ConnectedProps<typeof connector>

const DirectoryCreateDialog = ({directory, createDirectory, open, setOpen, setDirectory}: Props) => {
    const onConfirm = async () => {
        await createDirectory(directory)
        setOpen(false)
    }

    const onCancel = () => {
        setOpen(false)
    }

    const onDialogClose = () => {
        setOpen(false)
    }

    const canSubmit = directory.name.length > 0

    const onInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        await setDirectory({...directory, name: e.target.value})
    }

    const online = useIsOnline()

    return !online ? null : (<View directory={directory} open={open} onConfirm={onConfirm} onCancel={onCancel} onDialogClose={onDialogClose} canSubmit={canSubmit} onInputChange={onInputChange} />)
}

export default connector(DirectoryCreateDialog)
