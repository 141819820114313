import React from "react";
import {currentUser as getCurrentUser} from "../../../auth/store/selectors";
import {useSelector} from "react-redux";
import styled from "styled-components";
import PermissionsContent from '../../components/PermissionsContent'
import PermissionsSidebar from "../../components/PermissionsSidebar";

const PermissionsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 4fr;
    padding-top: 40px;
`

const ContentManager = () => {
    const currentUser = useSelector(getCurrentUser)
    return <div>
        {currentUser && (
            <PermissionsWrapper>
                <PermissionsSidebar />
                <PermissionsContent />
            </PermissionsWrapper>
        )}
    </div>
}

export default ContentManager
