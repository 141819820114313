import React from 'react'

export type Message = {
    type: MessageType,
    message: React.ReactNode,
    timeout?: number | null
}

export enum MessageType {
    'success',
    'info',
    'warning',
    'error'
}

export type MessageAction = {
    type: 'ui/message',
    payload: Message | undefined
}

export type ProgressAction = {
    type: 'ui/progress',
    payload: {
        show: boolean,
        message: string
    }
}

export type SetShowSidebar = {
    type: 'ui/setShowSidebar',
    payload: boolean
}

export type SetConfirmation = {
    type: 'ui/setConfirmation',
    payload: {message: string, onConfirm: () => any} | null
}

type ResetStore = {
    type: 'ui/resetStore',
    payload: boolean
}

export const resetUiStore = (payload: boolean): ResetStore => ({
    type: "ui/resetStore",
    payload
})

export const setMessage = (m: Message | undefined): MessageAction => {
    return {
        type: 'ui/message',
        payload: m
    }
}

export const setProgress = (show: boolean, message: string = ''): ProgressAction => {
    return {
        type: 'ui/progress',
        payload: {
            show,
            message
        }
    }
}

export const setShowSidebar = (payload: boolean): SetShowSidebar => ({
    type: "ui/setShowSidebar",
    payload
})

export const setConfirmation = (payload: {message: string, onConfirm: () => any} | null): SetConfirmation => ({
    type: "ui/setConfirmation",
    payload
})

export type Actions = MessageAction | ProgressAction | SetShowSidebar | SetConfirmation | ResetStore
