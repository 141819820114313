import {Role, SavedRole, SavedRoleWithoutRel, SavedRoleWithRel} from "../types/role";

export const isRole = (i: any): i is Role => {
    return i && i.hasOwnProperty('name') && i.hasOwnProperty('description') && i.hasOwnProperty('type')
}

export const isSavedRole = (i: any): i is SavedRole => {
    return i && i.hasOwnProperty('id') && (i.hasOwnProperty('nb_users') || i.hasOwnProperty('users'))
}

export const isSavedRoleWithRel = (i: any): i is SavedRoleWithRel => {
    return isSavedRole(i) && !!i.permissions && Array.isArray(i.users)
}

export const isSavedRolwWithoutRel = (i: any): i is SavedRoleWithoutRel => {
    return isSavedRole(i) && !i.permissions && !i.users
}
