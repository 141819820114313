import { Record } from "immutable";
import { Directory } from "../types/directory";
import { DirectoryRequest } from "./actions";
import { List } from "immutable";
import { IFile } from "../types/file";

export type State = {
    currentDirectory: Directory | null,
    directories: Directory[],
    directoryToCreate: DirectoryRequest,
    isCreating: boolean,
    selectedFiles: List<IFile>,
    files: IFile[],
    directoryToUpdate: Directory | null,
    isUpdatingDirectory: boolean,
    copiedFile: List<IFile>,
    cuttedFile: List<IFile>,
    sharedIds: string[],
    showBlocked: boolean,
    filter: string,
    highlightedFile: string[] | undefined,
    scrollListTo: number | undefined
};

class InitialState extends Record<State>({
    currentDirectory: null,
    directories: [],
    files: [],
    selectedFiles: List([]),
    directoryToCreate: {
        files: [],
        name: "",
        user: undefined,
        parent: undefined,
    },
    isCreating: false,
    directoryToUpdate: null,
    isUpdatingDirectory: false,
    copiedFile: List(),
    cuttedFile: List(),
    sharedIds: [],
    showBlocked: false,
    filter: '',
    highlightedFile: undefined,
    scrollListTo: undefined
}) {}

const initialState = new InitialState();

export default initialState;
