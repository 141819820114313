import FileUpload from "../FileUpload";
import DirectoryContextMenu from "../DirectoryContextMenu";
import {Folder} from "@material-ui/icons";
import React from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";
import {Directory} from "../../types/directory";
import {useDirectoryDrop} from "../../hooks/useDirectoryDrop";

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
  padding: 5px;
  flex-direction: row;
  border-radius: 3px;
  border: 1px solid gray;
  
  & .folder-icon {
    margin-right: 10px;
  }
  
  &.active {
    border: 1px solid ${props => props.theme.colors.primary}
  }
`

type Props = { directory: Directory }

const DirectoryItem: React.FC<Props> = ({ directory }) => {

    const [{isOver}, drop] = useDirectoryDrop(directory, (monitor) => ({ isOver: monitor.isOver()}))

    return <FileUpload key={directory._id} directory={directory}>
        <DirectoryContextMenu directory={directory}>
            <StyledLink className={isOver ? 'active' : ''} ref={drop} key={directory._id} to={`/${directory._id}`}>
                <Folder className='folder-icon' />
                <span>{directory.name}</span>
            </StyledLink>
        </DirectoryContextMenu>
    </FileUpload>
}

export default DirectoryItem
