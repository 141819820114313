import {useRoles} from "../../hooks/useRoles";
import {IconButton, List, ListItem, ListItemIcon} from "@material-ui/core";
import React, {useCallback, useState} from "react";
import HatIcon from "../../../ui/components/HatIcon";
import {Link} from "react-router-dom";
import {roleLink} from "../../../links";
import {useParams} from 'react-router'
import {Delete} from "@material-ui/icons";
import {SavedRole} from "../../types/role";
import {useDispatch} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import ConfirmationDialog from "../../../ui/components/ConfirmationDialog";
import {isSavedRole} from "../../helpers/typeGuards";
import {MessageType, setMessage} from "../../../ui/store/actions";
import {deleteRole} from "../../store/actions";
import Button from "@material-ui/core/Button";
import {useCurrentUserCan} from "../../hooks/useCurrentUserCan";
import CopyIcon from "../../../ui/components/Icons/CopyIcon";
import {useCurrentUserIsSuperadmin} from '../../../acl/hooks/useCurrentUserIsSuperadmin'

const useStyles = makeStyles({
    deleteIcon: {
        marginLeft: 'auto'
    },
    createButton: {
        margin: '20px auto 0',
        width: 'calc(100% - 20px)',
    },
})

const RolesList = () => {
    const roles = useRoles()
    const [showConfirm, setShowConfirm] = useState(false)
    const [roleToDelete, setRoleToDelete] = useState<SavedRole | null>(null)
    const { id } = useParams<{id?: string}>()
    const dispatch = useDispatch()
    const classes = useStyles()
    const canCreateRole = useCurrentUserCan('users-permissions', 'userspermissions', 'createrole')
    const canDeleteRole = useCurrentUserCan('users-permissions', 'userspermissions', 'deleterole')

    const onDeleteClick = useCallback((role: SavedRole) => {
        setRoleToDelete(role)
        setShowConfirm(true)
    }, [])

    const hideDialog = useCallback(() => {
        setShowConfirm(false)
    }, [])

    const onDeleteConfirm = useCallback(async () => {
        if(isSavedRole(roleToDelete)) {
            await dispatch(deleteRole(roleToDelete))
            dispatch(setMessage({message: 'Ruolo eliminato', type: MessageType.success}))
            setRoleToDelete(null)
            hideDialog()
        }
    }, [dispatch, hideDialog, roleToDelete])

    const copyRole = useCallback(() => {

    }, [])

    const isSA = useCurrentUserIsSuperadmin()

    return <>
        <div>
            {id !== 'create' && canCreateRole && <Link to={roleLink('create')}><Button className={classes.createButton} variant='contained'>Crea Ruolo</Button></Link>}
            <List>
                {roles.map(role => !isSA && role.name === 'superadmin' ? null : <Link key={role.id} to={roleLink(role.id)}><ListItem  button selected={id === role.id}>
                    <ListItemIcon><HatIcon/></ListItemIcon>
                    {role.name}
                    { canCreateRole && <IconButton className={classes.deleteIcon} onClick={copyRole}><CopyIcon/></IconButton>}
                    { canDeleteRole && <IconButton className={!canCreateRole ? classes.deleteIcon : undefined} onClick={() => onDeleteClick(role)}>
                        <Delete />
                    </IconButton> }
                </ListItem></Link>)}
            </List>
        </div>
        <ConfirmationDialog onCancel={hideDialog} onClose={hideDialog} onConfirm={onDeleteConfirm} open={showConfirm} />
        </>
}

export default RolesList
