import initialState from "./store";
import {Actions} from "./actions";

const reducer = (state: typeof initialState = initialState, action: Actions): typeof initialState =>  {
    switch (action.type) {
        case "content-manager/share/SetCurrentShare":
            return state.set('currentShare', action.payload)
        case "content-manager/share/SetShowSidebar":
            return state.set('showSidebar', action.payload)
        case "content-manager/shares/SetShares":
            return state.set('shares', action.payload)
        default:
            return state
    }
}

export default reducer
