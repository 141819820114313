import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import {Dialog, DialogTitle, Divider} from "@material-ui/core";
import CreateNewTag from "./CreateNewTag";
import TagsList from "./TagsList";
import ModifyTag from "./ModifyTag";
import {
    getIsChoosingTags,
    getIsCreatingTag,
    getIsModifyingTag,
    getIsSaving,
    getSelectedItem
} from "../../store/tags/selectors";
import {closeTagDialog} from "../../store/tags/actions";


const TagsDialog: React.FC = () => {
    const dispatch = useDispatch()
    const isChoosingTags = useSelector(getIsChoosingTags);
    const isModifyingTag = useSelector(getIsModifyingTag);
    const isCreatingTag = useSelector(getIsCreatingTag);
    const selectedItem = useSelector(getSelectedItem)
    const isSaving = useSelector(getIsSaving);

    return (
        <Dialog open={!!isChoosingTags || !!isModifyingTag || !!isCreatingTag} onClose={isSaving ? () => false : () => dispatch(closeTagDialog())}>
            <DialogTitle>Gestione Tag</DialogTitle>
            {
                isCreatingTag || isModifyingTag ?
                null : isChoosingTags ?
                <span style={{padding: '0 24px'}}>{
                    selectedItem === null ? '' : 'File: ' + selectedItem.name
                }</span> : null
            }
            <Divider style={{marginTop: '10px'}} />
            {isChoosingTags ? <TagsList/> :
                isCreatingTag ? <CreateNewTag /> :
                    isModifyingTag ? <ModifyTag /> : null
            }
        </Dialog>
    );
}

export default TagsDialog
