import React, {useCallback, useState} from 'react'
import {Button, CircularProgress, createStyles, fade, InputBase, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Search} from "@material-ui/icons";
import {useDispatch, useSelector} from "react-redux";
import {fetchSearchResults, setHasFetched, setIsFetching, setSearchText} from "../../store/actions";
import {useHistory} from 'react-router'
import {getIsFetching} from "../../store/selectors";
import {useCurrentUserCan} from "../../../roles-and-permissions/hooks/useCurrentUserCan";
import {useIsOnline} from '../../../network/utils'
import {isOnline} from '../../../network/store/selectors'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
                 search: {
                     position: 'relative',
                     borderRadius: theme.shape.borderRadius,
                     backgroundColor: fade(theme.palette.common.white, 0.15),
                     '&:hover': {
                         backgroundColor: fade(theme.palette.common.white, 0.25),
                     },
                         marginLeft: 'auto',
                         marginRight: 0,
                         width: '100%',
                     [theme.breakpoints.up('sm')]: {
                         marginLeft: theme.spacing(1),
                         width: 'auto',
                     },
                 },
                 searchIcon: {
                     padding: theme.spacing(0, 2),
                     height: '100%',
                     position: 'absolute',
                     pointerEvents: 'none',
                     display: 'flex',
                     alignItems: 'center',
                     justifyContent: 'center',
                 },
                 inputRoot: {
                     color: 'inherit',
                 },
                 inputInput: {
                     padding: theme.spacing(1, 1, 1, 0),
                     // vertical padding + font size from searchIcon
                     paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
                     transition: theme.transitions.create('width'),
                     width: '100%',
                     [theme.breakpoints.up('sm')]: {
                         width: '20ch',
                         '&:focus': {
                             width: '28ch',
                         },
                     },
                 },
                 progressContainer: {
                     position: 'absolute',
                     right: 0,
                     top: 0,
                     height: '100%',
                     padding: '0px 16px',
                     display: 'flex',
                     alignItems: 'center'
                 }
             })
);

const SearchBar: React.FC = () => {
    const canSearch = useCurrentUserCan('application', 'file-item', 'search')
    const classes = useStyles();
    const h = useHistory()
    const [searchText, setSearch] = useState('')
    const online = useSelector(isOnline)

    const search = useCallback(async () => {
        if(searchText.length >= 3) {
            h.push('/search/'+searchText)
        } else if (searchText.length === 0) {
            h.push('/')
        }
    }, [h, searchText])

    return canSearch ?
            <form className={classes.search} onSubmit={e => {
                e.preventDefault()
                search()
            }}>
            <div className={classes.searchIcon}>
                <Search />
            </div>
                <InputBase
                    disabled={!online}
                    onChange={v => setSearch(v.target.value)}
                    placeholder="Cerca…"
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                />

            <Button onClick={search} disabled={searchText.length < 3} >Cerca</Button>
            </form> : null
}

export default SearchBar;
