import {Share} from "../../types/share";
import {AppThunk} from "../../../App";
import axios from 'axios'
import {Directory} from "../../types/directory";
import {User} from "../../../auth/types/user";
import {AclGroup} from "../../../acl.old/types/aclGroup";
import {fetchDirectoryById} from "../actions";


type SetCurrentShare = {
    type: 'content-manager/share/SetCurrentShare',
    payload: Share | null
}

type SetShowSidebar = {
    type: 'content-manager/share/SetShowSidebar',
    payload: boolean
}

type SetShares = {
    type: 'content-manager/shares/SetShares',
    payload: Directory[]
}

export const setCurrentShare = (payload: Share | null): SetCurrentShare => ({
    type: "content-manager/share/SetCurrentShare",
    payload
})

export const setShowSidebar = (payload: boolean): SetShowSidebar => ({
    type: "content-manager/share/SetShowSidebar",
    payload
})

export const setShares = (payload: Directory[]): SetShares => ({
    type: "content-manager/shares/SetShares",
    payload
})

export const createEmptyShare = (directory: Directory): AppThunk => async dispatch => {
    const { data } = await axios.post('/shares', { directory: directory._id })
    await dispatch(fetchDirectoryById(directory._id))
    return dispatch(setCurrentShare(data))
}

export const fetchShareById = (id: string): AppThunk => async dispatch => {
    const { data } = await axios.get(`/shares/${id}`)
    return dispatch(setCurrentShare(data))
}

export const fetchCurrentUserShares = (): AppThunk => async dispatch => {
    const { data } = await axios.get<Directory[]>('/me/shares')
    dispatch(setShares(data))
}

export const addUserToShare = (share: Share, user: User): AppThunk => async dispatch => {
    await axios.put(`/shares/${share._id}`, {
        users: [
            ...share.users.map(u => u._id),
            user._id
        ]
    })
}

export const addGroupToShare = (share: Share, group: AclGroup): AppThunk => async dispatch => {
    await axios.put(`/shares/${share._id}`, {
        groups: [
            ...share.groups.map(i => i._id),
            group._id
        ]
    })
}

export const addUserToCurrentShare = (user: User, refresh: boolean = true): AppThunk => async (dispatch, getState) => {
    const state = getState()
    const share = state["content-manager/share"].currentShare
    if(share) {
        await dispatch(addUserToShare(share, user))
        if (refresh) {
            await dispatch(fetchShareById(share._id))
        }
    }
}

export const addGroupToCurrentShare = (group: AclGroup, refresh: boolean = true): AppThunk => async (dispatch, getState) => {
    const state = getState()
    const share = state["content-manager/share"].currentShare
    if(share) {
        await dispatch(addGroupToShare(share, group))
        if (refresh) {
            await dispatch(fetchShareById(share._id))
        }
    }
}

export const updateShare = (share: Share): AppThunk => async () => {
    await axios.put(`/shares/${share._id}`, {
        directory: share.directory._id,
        groups: share.groups.map(i => i._id),
        users: share.users.map(i => i._id),
    })
}

export const removeGroupFromShare = (share: Share, group: AclGroup): AppThunk => async dispatch => {
    return dispatch(updateShare({...share, groups: share.groups.filter(i => i._id !== group._id)}))
}

export const removeUserFromShare = (share: Share, user: User): AppThunk => async dispatch => {
    return dispatch(updateShare({...share, users: share.users.filter(i => i._id !== user._id)}))
}

export const removeGroupFromCurrentShare = (group: AclGroup, refresh = true): AppThunk => async (dispatch, getState) => {
    const state = getState()
    const share = state["content-manager/share"].currentShare
    if(share) {
        await dispatch(removeGroupFromShare(share, group))
        if(refresh) {
            await dispatch(fetchShareById(share._id))
        }
    }
}

export const removeUserFromCurrentShare = (user: User, refresh = true): AppThunk => async (dispatch, getState) => {
    const state = getState()
    const share = state["content-manager/share"].currentShare
    if(share) {
        await dispatch(removeUserFromShare(share, user))
        if(refresh) {
            await dispatch(fetchShareById(share._id))
        }
    }
}

export type Actions = SetCurrentShare
    | SetShowSidebar
    | SetShares
