import initialState from "./store";
import { Actions } from "./actions";

const reducer = (
    state = initialState,
    action: Actions,
): typeof initialState => {
    switch (action.type) {
        case "content-manager/tags/setSelectedItem":
            return state.set("selectedItem", action.payload)
        case "content-manager/tags/addTag":
            return state.set("tags", [...state.tags.filter(t => {return action.payload._id !== t._id}), action.payload])
        case "content-manager/tags/setIsChoosingTags":
            return state.set("isChoosingTags", action.payload)
        case "content-manager/tags/setIsCreatingTag":
            return state.set("isCreatingTag", action.payload)
        case "content-manager/tags/setIsModifyingTag":
            return state.set("isModifyingTag", action.payload)
        case "content-manager/tags/setSelectedTags":
            return state.set("selectedTags", action.payload)
        case "content-manager/tags/setTags":
            return state.set("tags", action.payload)
        case "content-manager/tags/setTagToModify":
            return state.set("tagToModify", action.payload)
        case "content-manager/tags/addSelectedTag":
            return state.set("selectedTags", [...state.selectedTags, action.payload])
        case "content-manager/tags/removeSelectedTag":
            return state.set("selectedTags", state.selectedTags.filter(t => {return t._id !== action.payload._id}))
        case "content-manager/tags/setIsSaving":
            return state.set("isSaving", action.payload)
        default:
            return state;
    }
};

export default reducer;
