import {Role, SavedRole, SavedRoleWithoutRel, SavedRoleWithRel} from "../types/role";
import {Permissions} from "../types/permission";
import {AppThunk} from "../../App";
import axios from 'axios'

type AddRole = {
    type: 'getRoles-and-permissions/addRole',
    payload: SavedRole
}

type RemoveRole = {
    type: 'getRoles-and-permissions/removeRole',
    payload: {id: string}
}

type ResetStore = {
    type: 'getRoles-and-permissions/resetStore',
    payload: boolean
}

export const resetGetRolesAndPermissionsStore = (payload: boolean): ResetStore => ({
    type: "getRoles-and-permissions/resetStore",
    payload
})

export const addRole = (payload: SavedRole): AddRole => ({
    type: "getRoles-and-permissions/addRole",
    payload
})

export const removeRole = (payload: {id: string}): RemoveRole => ({
    type: "getRoles-and-permissions/removeRole",
    payload
})

export const fetchRoleById = ({ id}: { id: string}): AppThunk<Promise<SavedRoleWithRel>> => async dispatch => {
    const { data: { role } } = await axios.get<{role: SavedRoleWithRel}>(`/users-permissions/roles/${id}`)
    dispatch(addRole(role))
    return role
}

export const fetchRoles = (): AppThunk<Promise<SavedRoleWithoutRel[]>> => async (dispatch, getStore) => {
    const { data : { roles } } = await axios.get<{ roles: SavedRoleWithoutRel[] }>(`/users-permissions/roles`)
    const storeRoles = getStore()["roles-and-permission"].roles
    roles.forEach(role => {
        if(storeRoles.findIndex(r => r.id === role.id) === -1) {
            dispatch(addRole(role))
        }
    })
    return roles
}

export const updateRole = (role: SavedRoleWithRel): AppThunk<Promise<{ok: boolean}>> => async dispatch => {
    const { data: { ok }} = await axios.put<{ok: boolean}>(`/users-permissions/roles/${role.id}`, role)
    dispatch(addRole(role))
    return { ok }
}

export const createRole = (role: Role): AppThunk<Promise<{ok: boolean}>> => async dispatch => {
    const { data: { permissions }} = await axios.get<{permissions: Permissions}>(`/users-permissions/permissions`)
    const { data: { ok }} = await axios.post<{ ok: boolean }>(`/users-permissions/roles`, {...role, permissions })
    await dispatch(fetchRoles())
    return { ok }
}

export const deleteRole = ({id} : {id: string}): AppThunk<Promise<void>> => async dispatch => {
    await axios.delete(`/users-permissions/roles/${id}`)
    dispatch(removeRole({ id }))
}

export type Actions = AddRole | RemoveRole | ResetStore
