import React, {useState} from 'react'
import {RootState} from "../../../App";
import {currentFiles, getSelectedFiles} from "../../store/selectors";
import {connect, ConnectedProps} from "react-redux";
import {Button, Checkbox, Grow, Tooltip} from "@material-ui/core";
import {deleteSelectedFiles, setSelectedFiles} from "../../store/actions";
import {IFile} from "../../types/file";
import {ThunkDispatch} from 'redux-thunk'
import styled from "styled-components";
import ConfirmationDialog from "../../../ui/components/ConfirmationDialog";
import {useIsOnline} from '../../../network/utils'

const mapState = (state: RootState) => ({
    selectedFiles: getSelectedFiles(state),
    currentFiles: currentFiles(state)
})

const mapActions = (dispatch: ThunkDispatch<any,any,any>) => ({
    setSelectedFiles: (f: IFile[]) => dispatch(setSelectedFiles(f)),
    deleteSelectedFiles: () => dispatch(deleteSelectedFiles())
})

const connector = connect(mapState, mapActions)

type Props = ConnectedProps<typeof connector>

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  
  & > * {
    margin-left: 5px;
  }
`



const FileViewerActions: React.FC<Props> = ({selectedFiles, currentFiles, setSelectedFiles, deleteSelectedFiles}) => {
    const [open, setOpen] = useState(false)

    const allFilesAreSelected = selectedFiles.size === currentFiles?.length

    const onClick = () => {
        if(allFilesAreSelected) {
            setSelectedFiles([])
        } else if(currentFiles) {
            setSelectedFiles(currentFiles)
        }
    }

    const onDeleteClick = () => { setOpen(true) }

    const onConfirm = () => {
        deleteSelectedFiles()
        setOpen(false)
    }

    const online = useIsOnline()

    return online ? <Wrapper>
            <Grow in={selectedFiles.count() > 0} >
                <Button color='secondary' onClick={onDeleteClick} >Elimina {selectedFiles.count()} file</Button>
            </Grow>
        <ConfirmationDialog open={open} onClose={() => setOpen(false)} onConfirm={onConfirm} onCancel={() => setOpen(false)} />
            {currentFiles && currentFiles.length > 0 && <Tooltip title={'Seleziona tutti'}>
                <Checkbox onClick={onClick} indeterminate={!allFilesAreSelected && selectedFiles.size > 0} checked={allFilesAreSelected} />
            </Tooltip>}
    </Wrapper> : null
}

export default connector(FileViewerActions)
