import React, {ErrorInfo, useCallback} from "react";
import ErrorBoundaryHandler from "./handler";

const ErrorBundary: React.FC = ({children}) => {
    const onError = useCallback((e: Error, e2: ErrorInfo) => {
        console.log(e, e2)
        return false
    }, [])

    return <ErrorBoundaryHandler onError={onError} >{children}</ErrorBoundaryHandler>
}

export default ErrorBundary
