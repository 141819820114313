import {DirectoryEvent} from "../../../types/directoryEvent";
import { Record, List } from 'immutable'
import {Directory} from "../../../types/directory";

interface Store {
    events: List<DirectoryEvent>,
    currentDirectory: Directory | null
}

class DirectoryEventsStore extends Record<Store>({
    events: List([]),
    currentDirectory: null
}) {}

const directoryEventsStore = new DirectoryEventsStore()

export default directoryEventsStore
