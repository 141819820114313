import React, {useCallback, useEffect, useState} from "react";
// import {User} from "../../../auth/types/user";
import {useHistory, useParams} from 'react-router-dom'
import {useDispatch, useSelector} from "react-redux";
import {makeGetUserById} from "../../userStore/selectors";
import {makeStyles} from "@material-ui/core/styles";
import {Field, Form, Formik} from "formik";
import {TextField} from "formik-material-ui";
import {Button, FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import Switch from "../Switch";
import * as yup from 'yup'
import {getMe} from "../../../auth/store/selectors";
import {pick} from 'lodash'
import {createUser, deleteUser, updateUser} from "../../userStore/actions";
import {MessageType, setMessage} from "../../../ui/store/actions";
import {AppDispatch} from "../../../App";
import ConfirmButton from "../../../ui/components/ConfirmButton";
import UserEvents from "../UserEvents";
import {useRoles} from "../../../roles-and-permissions/hooks/useRoles";
import {useCurrentUserIsSuperadmin} from '../../../acl/hooks/useCurrentUserIsSuperadmin'

const useStyle = makeStyles({
    form: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridColumnGap: '10px',
        gridRowGap: '10px',
        paddingRight: '10px'
    }
})

// const emptyUser: User = {
//     password: "",
//     blocked: false,
//     confirmed: false,
//     createdAt: "",
//     email: "",
//     username: ""
// }

const UserForm = () => {
    const dispatch: AppDispatch = useDispatch()
    const { form } = useStyle()
    const history = useHistory()
    const {id} = useParams<{id: string}>()
    const currentUser = useSelector(makeGetUserById(id))
    const me = useSelector(getMe)
    const isCreating = id === 'create'
    const isEditingMe = (!!me && currentUser?._id === me?._id)
    const roles = useRoles()

    const [schema, setSchema] = useState<ReturnType<typeof yup.object>>()
    useEffect(() => {
        if(isCreating) {
            setSchema(yup.object({
                username: yup
                    .string()
                    .required('Inserire un nome utente')
                    .min(5, 'Il nome utente deve avere un minimo di 5 caratteri'),
                email: yup
                    .string()
                    .email('Inserire una mail valida')
                    .required('Inserire una mail'),
                password: yup
                    .string()
                    .required('Inserire una password')
                    .min(6, 'La password deve avere un minimo di 6 caratteri')
                    .oneOf([yup.ref('passwordConfirm'), ''], 'Le password non corrispondono'),
                role: yup.string().required('Selezionare un ruolo'),
                passwordConfirm: yup
                    .string()
                    .oneOf([yup.ref('password'), ''], 'Le password non corrispondono')
            }))
        } else {
            setSchema(yup.object({
                username: yup
                    .string()
                    .required('Inserire un nome utente')
                    .min(5, 'Il nome utente deve avere un minimo di 5 caratteri'),
                email: yup
                    .string()
                    .email('Inserire una mail valida')
                    .required('Inserire una mail'),
                password: yup
                    .string()
                    .notRequired()
                    .min(6, 'La password deve avere un minimo di 6 caratteri')
                    .oneOf([yup.ref('passwordConfirm'), ''], 'Le password non corrispondono'),
                role: yup.string().required('Selezionare un ruolo'),
                passwordConfirm: yup
                    .string()
                    .notRequired()
                    .oneOf([yup.ref('password'), ''], 'Le password non corrispondono')
            }))
        }
    }, [isCreating, setSchema])

    const initialValues = {
        username: currentUser?.username || '',
        email: currentUser?.email || '',
        blocked: currentUser?.blocked || false,
        confirmed: currentUser?.confirmed || true,
        password: '',
        passwordConfirm: '',
        role: currentUser && currentUser.role ? (typeof currentUser.role === 'string' ? currentUser.role : currentUser.role.id) : ''
    }

    const onSubmit = useCallback((values: typeof initialValues) => {
        const exec = async () => {
            if(currentUser) {
                const updated = {
                    ...currentUser,
                    ...pick(values, ['username', 'email', 'blocked', 'confirmed'])
                }
                if(values.password.length) {
                    updated.password = values.password
                }
                await dispatch(updateUser(updated))
                dispatch(setMessage({ message: 'Utente aggiornato', type: MessageType.success}))
            } else {
                const createdUser = await dispatch(createUser(values))
                dispatch(setMessage({ message: 'Utente creato', type: MessageType.success}))
                if(createdUser._id) {
                    history.push(`/users/${createdUser._id}`)
                }
            }
            return
        }

        exec()
    }, [currentUser, dispatch, initialValues, history])

    const onDeleteClick = useCallback(() => {
        const exec = async () => {
            if(currentUser) {
                await dispatch(deleteUser(currentUser))
                dispatch(setMessage({message: 'Utente eliminato', type: MessageType.success}))
                history.push('/users')
            }
        }
        exec()
    }, [currentUser, history, dispatch])

  const isSA = useCurrentUserIsSuperadmin()

    return <div>
        <Formik
            enableReinitialize
            validationSchema={schema}
            initialValues={initialValues}
            onSubmit={onSubmit}
        >
            {({submitForm, isSubmitting, isValid, dirty, values, handleChange}) => (<Form className={form}>
                <Field
                    component={TextField}
                    name='username'
                    label='Nome Utente'
                />
                <Field
                    component={TextField}
                    name='email'
                    type='email'
                    label='Email'
                />
                <Field
                    component={TextField}
                    name='password'
                    type='password'
                    label='Password'
                />
                <Field
                    component={TextField}
                    name='passwordConfirm'
                    type='password'
                    label='Conferma Password'
                />
                <FormControl>
                    <InputLabel id='user-role'>Ruolo</InputLabel>
                    <Select value={values.role} onChange={handleChange} name='role' labelId='user-role'>
                        {roles.map(role => !isSA && role.name === 'superadmin' ? null : <MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>)}
                    </Select>
                </FormControl>
                {!isEditingMe && <>
                    <Field
                        component={Switch}
                        name='confirmed'
                        label='Confermato'
                    />
                    <Field
                        component={Switch}
                        name='blocked'
                        label='Disabilitato'
                    />
                </>}
                <Button onClick={submitForm} disabled={isSubmitting || !dirty || !isValid}>Salva</Button>
                { !isCreating && !isEditingMe && <ConfirmButton color='secondary' onConfirm={onDeleteClick}>Elimina</ConfirmButton> }
                {currentUser && <UserEvents user={currentUser}/> }
            </Form>)}
        </Formik>
        </div>
}

export default UserForm
