import {RootState} from "../../../App";
import { createSelector } from "reselect";

const state = (state: RootState) => state["content-manager/share"]

export const showSidebar = createSelector(
    state,
    state => state.showSidebar
)

export const getCurrentShare = createSelector(
    state,
    state => state.currentShare
)

export const getShares = createSelector(
    state,
    state => state.shares
)

