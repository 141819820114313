import {RootState} from "../../../App";
import {message} from "../../store/selectors";
import {connect, ConnectedProps} from "react-redux";
import {Dispatch} from 'redux'
import {Snackbar} from "@material-ui/core";
import {setMessage} from "../../store/actions";
import {Message as IMessage} from "../../store/actions";
import React from "react";

const mapState = (state: RootState) => {
    return {
        message: message(state)
    }
}

const mapActions = (dispatch: Dispatch) => {
    return {
        setMessage : (p: IMessage | undefined) => dispatch(setMessage(p))
    }
}


const connector = connect(mapState, mapActions)
type Props = ConnectedProps<typeof connector>

const Message = ({message, setMessage}: Props) => {
    return (
        <Snackbar
            open={!!message?.message}
            onClose={() => setMessage(undefined)}
            message={message?.message || ''}
            autoHideDuration={message?.timeout || 5000}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
        />
    )
}

export default connector(Message)
