import React from 'react'
import SearchBar from '../../components/SearchBar'
import {createStyles, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import SearchList from '../../components/SearchList'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            position: 'relative'
        }
    }),
);

const Search = () => {
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <SearchBar />
            <SearchList />
        </div>
    )
}

export default Search
