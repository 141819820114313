import React from 'react';
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import './App.css';
import Header from "./ui/components/Header";
import { Action, combineReducers, compose, createStore, applyMiddleware } from 'redux'
import {DefaultRootState, Provider} from "react-redux";
import Login from "./auth/containers/Login";
import styled from "styled-components";
import thunk from 'redux-thunk'
import Message from "./ui/components/Message";
import theme from './theme'
import {ThemeProvider} from "styled-components";
import AclStore from './acl/store'
import AuthStore from '../src/auth/store'
import UiStore from '../src/ui/store'
import ContentManagerStore from '../src/content-manager/store'
import ShareStore from '../src/content-manager/store/share'
import DirectoryEventsStore from '../src/content-manager/store/events/directory'
import FileEventsStore from '../src/content-manager/store/events/file'
import AclGroupStore from './acl.old/store'
import UserStore from './users/userStore'
import SearchStore from './search/store'
import RolesAndPermissionsStore from 'roles-and-permissions/store'
import EventsStore from './events/store/index'
import TagsStore from './content-manager/store/tags'
import Network from './network/store/reducer'
import ProgressIndicator from "./ui/components/ProgressIndicator";
import {BrowserRouter} from "react-router-dom";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import Confirmation from "./ui/components/Confirmation";
import AxiosErrorHandler from "./ui/components/AxiosErrorHandler";
import ErrorBoundary from "./ui/components/ErrorBoundary";
import UserIsAuthenticated from "./auth/components/UserIsAuthenticated";
import Routes from "./Routes";
import Detector from './network/components/Detector'

const reducer = combineReducers({
    auth: AuthStore,
    ui: UiStore,
    'content-manager': ContentManagerStore,
    'content-manager/share': ShareStore,
    'content-manager/tags': TagsStore,
    'acl-group': AclGroupStore,
    'directory-events': DirectoryEventsStore,
    'file-events': FileEventsStore,
    user: UserStore,
    // rules: RulesStore,
    'roles-and-permission': RolesAndPermissionsStore,
    acl: AclStore,
    search: SearchStore,
    events: EventsStore,
    network: Network
})

const middleware = [thunk]
// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const enhancer = composeEnhancers(applyMiddleware(...middleware))
const store = createStore(reducer, {}, enhancer)

export type RootState = ReturnType<typeof reducer>

declare module 'react-redux' {
    interface DefaultRootState {
        auth: ReturnType<typeof AuthStore>,
        ui: ReturnType<typeof UiStore>,
        'content-manager': ReturnType<typeof ContentManagerStore>,
        'content-manager/share': ReturnType<typeof ShareStore>,
        'content-manager/tags': ReturnType<typeof TagsStore>
        'directory-events': ReturnType<typeof DirectoryEventsStore>
        'file-events': ReturnType<typeof FileEventsStore>
        'acl-group': ReturnType<typeof AclGroupStore>,
        user: ReturnType<typeof UserStore>,
        'roles-and-permissions': ReturnType<typeof RolesAndPermissionsStore>,
        acl: ReturnType<typeof AclStore>,
        search: ReturnType<typeof SearchStore>,
        events: ReturnType<typeof EventsStore>,
        network: ReturnType<typeof Network>
    }
}

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>

export type AppDispatch = ThunkDispatch<RootState, any, Action<string>>

const Wrapper = styled.div`
  margin-top: 60px;
`

function App() {
  return (
      <DndProvider backend={HTML5Backend}>
      <BrowserRouter>
          <ThemeProvider theme={theme}>
              <Provider store={store}>
                  <ErrorBoundary>
                      <ProgressIndicator/>
                      <Message />
                      <Wrapper className="App">
                          <Login/>
                          <Header/>
                          <UserIsAuthenticated>
                              <Routes />
                          </UserIsAuthenticated>
                          <AxiosErrorHandler/>
                      </Wrapper>
                      <Confirmation/>
                      <Detector />
                  </ErrorBoundary>
              </Provider>
          </ThemeProvider>
      </BrowserRouter>
      </DndProvider>
  );
}

export default App;
