import React, {useCallback, useEffect, useState} from "react";
import {AclGroup} from "../../../acl.old/types/aclGroup";
import {User} from "../../../auth/types/user";
import {Directory} from "../../types/directory";
import {IconButton, TableCell, TableRow, Tooltip, Switch as MiSwitch} from "@material-ui/core";
import {isUser} from "../../../auth/helpers/isUser";
import {Delete, Save} from "@material-ui/icons";
import ConfirmationDialog from "../../../ui/components/ConfirmationDialog";
import {removeGroupFromCurrentShare, removeUserFromCurrentShare} from "../../store/share/actions";
import {MessageType, setMessage} from "../../../ui/store/actions";
import {useDispatch, useSelector} from "react-redux";
import {fetchGroupById, updateAclRule} from "../../../acl/store/actions";
import {makeGetGroupById} from "../../../acl/store/selectors";
import {Field, Formik} from "formik";
import Switch from "../../../users/components/Switch";
import {AclRule} from "../../../acl.old/types/aclRule";
import {useCanShowItem} from '../../../acl/hooks/useCanShowItem'

const ShareSideBarItem: React.FC<{entity: AclGroup | User, directory: Directory }> = ({ entity, directory}) => {

    const dispatch = useDispatch()
    const [showDialog, setShowDialog] = useState(false)
    const closeDialog = useCallback(() => setShowDialog(false), [])
    const onDeleteClick = useCallback(() => setShowDialog(true), [])
    const onDeleteConfirm = useCallback(async () => {
        isUser(entity) ? await dispatch(removeUserFromCurrentShare(entity)) : dispatch(removeGroupFromCurrentShare(entity))
        dispatch(setMessage({ message: (isUser(entity) ? 'Utente ' : 'Gruppo ') + 'Rimosso', type: MessageType.info}))
        setShowDialog(false)
    }, [dispatch, entity])


    useEffect(() => {
        if (directory.acl_rule_group) {
            const id = typeof directory.acl_rule_group === 'string' ? directory.acl_rule_group : directory.acl_rule_group.id
            dispatch(fetchGroupById({id}))
        }
    }, [directory.acl_rule_group, dispatch])

    const group = useSelector(makeGetGroupById(typeof directory.acl_rule_group === 'string' ? directory.acl_rule_group : typeof directory.acl_rule_group === 'object' ? directory.acl_rule_group?.id : ''))

    const rule = group ? group.rules.find(r => (isUser(entity) ? r.user : r.acl_group) === entity._id ) : false
    const initialValues = rule


    const onRuleSubmit = useCallback(async (val: AclRule) => {
        await dispatch(updateAclRule({...rule, ...val }))
        dispatch(setMessage({ message: 'Permesso aggiornato', type: MessageType.success}))
    }, [dispatch, rule])

    const canShowRow = useCanShowItem()

    return !canShowRow(entity) ? null : <>
        <TableRow >
        {isUser(entity) ? <>
            <TableCell>
                Utente
            </TableCell>
            <TableCell>
                {entity.username}
            </TableCell>
        </> : <>
            <TableCell>
                Gruppo
            </TableCell>
            <TableCell>
                {entity.name}
            </TableCell>
        </>}
        {group && initialValues ? <Formik initialValues={initialValues} onSubmit={onRuleSubmit} enableReinitialize>
            {({ dirty, isSubmitting, submitForm }) => <>
                <TableCell>
                    <Field component={Switch} name='read' />
                </TableCell>
                <TableCell>
                    <Field component={Switch} name='write' />
                </TableCell>
                <TableCell>
                    <Field component={Switch} name='delete' />
                </TableCell>
                <TableCell>
                    <Tooltip title={'Salva'}>
                        <span>
                            <IconButton disabled={!dirty || isSubmitting} onClick={submitForm}><Save /></IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip title={'Rimuovi'}>
                        <IconButton onClick={onDeleteClick} aria-label='delete'>
                            <Delete />
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </>}
        </Formik> : <>
            <TableCell><MiSwitch disabled /></TableCell>
            <TableCell><MiSwitch disabled /></TableCell>
            <TableCell><MiSwitch disabled /></TableCell>
            <TableCell>
                <Tooltip title={'Salva'}>
                    <span>
                        <IconButton disabled><Save /></IconButton>
                    </span>
                </Tooltip>
                <Tooltip title={'Rimuovi'}>
                    <span>
                        <IconButton onClick={onDeleteClick} aria-label='delete'><Delete /></IconButton>
                    </span>
                </Tooltip>
            </TableCell>
        </>}
        </TableRow>
        <ConfirmationDialog open={showDialog} onConfirm={onDeleteConfirm} onClose={closeDialog} onCancel={closeDialog} />
    </>
}

export default ShareSideBarItem
