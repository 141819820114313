import {Directory} from "./directory";
import {BasicModel, Saved} from "../../types/Saved";
import {AclRuleGroup} from "../../acl/types/aclRuleGroup";
import {tag} from "./tag";

export const FILE = 'file'

export type IFile = BasicModel & {
    name: string
    metadata: {[key: string]: string}
    directory: string,
    file: {
        _id: string
        alternativeText: string | null
        caption: string | null
        createdAt: string
        ext: string
        hash: string
        height: null | string
        id: string
        mime: string
        name: string
        provider: string
        related: string[]
        size: number
        updatedAt: string
        url: string
        width: string | null
    },
    acl_rule_group: AclRuleGroup | string
    tags?: tag[] | string[]
}

export type IFileWithRel = Saved<IFile> & {
    directory: Directory
}

export type IFileWithoutRel = IFile & {
    directory: string
}
