import React from 'react'
import {Directory} from "../../types/directory";
import View from "./view";
import {
    deleteDirectory,
    downloadDirectory,
    pasteFiles,
    setDirectoryToCreate,
    setDirectoryToUpdate,
    setIsCreatingDirectory,
    setIsUpdatingDirectory
} from "../../store/actions";
import {useDispatch, useSelector} from "react-redux";
import {MessageType, setMessage, setProgress} from "../../../ui/store/actions";
import {createEmptyShare, fetchShareById, setShowSidebar} from "../../store/share/actions";
import {setCurrentDirectory} from "../../store/events/directory/actions";
import {useFetchDirectoryEvents} from "../../hooks/useFetchDirectoryEvents";
import {createGroup, fetchGroupById, setCurrentAclRuleGroupId} from "../../../acl/store/actions";
import {AppDispatch} from "../../../App";
import {useCan} from "../../../acl/hooks/useCan";
import {useCurrentUserCan} from "../../../roles-and-permissions/hooks/useCurrentUserCan";
import {getFilesToPaste} from "../../store/selectors";
import {useIsOnline} from '../../../network/utils'

type ComponentProps = { directory: Directory, showOpen ?: boolean }

type Props = ComponentProps

const DirectoryContextMenu: React.FunctionComponent<Props> = ({
    children,
    directory,
}) => {
    const can = useCan(directory)
    const canDeleteDirectory = useCurrentUserCan('application', 'directory', 'delete')
    // const canCreateDirectory = useCurrentUserCan('application', 'directory', 'create')
    const canUpdateDirectory = useCurrentUserCan('application', 'directory', 'update')
    const canViewHistory = useCurrentUserCan('application', 'directory-event', 'find')
    const canSeeRules = useCurrentUserCan('application', 'acl-rule-group', 'find')
    const canUpdateRules = useCurrentUserCan('application', 'acl-rule-group', 'update')

    const dispatch: AppDispatch = useDispatch()

    const [fetchEvents] = useFetchDirectoryEvents(directory)

    const onCreateClick = () => {
        dispatch(setDirectoryToCreate({parent: directory._id, name: ''}))
        dispatch(setIsCreatingDirectory(true))
    }
    const onDeleteClick = async () => {
        await dispatch(deleteDirectory(directory))
        dispatch(setMessage({ message: 'Cartella eliminata', type: MessageType.info}))
    }
    const onDownloadClick = async () => {
        await dispatch(downloadDirectory(directory, (e) => {
            dispatch(setProgress(true, `Download in corso (${Math.round((e.loaded * 100) / e.total)}%)` ))
        }))
        dispatch(setProgress(false))
    }
    const onEditClick = () => {
        dispatch(setDirectoryToUpdate(directory))
        dispatch(setIsUpdatingDirectory(true))
    }
    const onShareClick = async () => {
        if(!directory.share) {
            await dispatch(createEmptyShare(directory))
        } else {
            await dispatch(fetchShareById(directory.share._id))
        }
        dispatch(setShowSidebar(true))
    }
    const onPermissionClick = async () => {
        dispatch(setProgress(true, 'Ottengo i permessi della cartella'))
        try {
            if(directory.acl_rule_group) {
                const id = typeof directory.acl_rule_group === 'string' ? directory.acl_rule_group : directory.acl_rule_group.id
                await dispatch(fetchGroupById({id}))
                dispatch(setCurrentAclRuleGroupId(id))
            } else {
                const group = await dispatch(createGroup(directory))
                dispatch(setCurrentAclRuleGroupId(group.id))
            }
        } finally {
            dispatch(setProgress(false))
        }
    }

    const toPaste = useSelector(getFilesToPaste)

    const onPaste = async () => {
        if(toPaste) {
            dispatch(setProgress(true, `Incollando ${toPaste.size} file`))
            await dispatch(pasteFiles(directory))
            dispatch(setProgress(false))
        }
    }

    const onHistoryClick = async () => {
        dispatch(setProgress(true, 'Ottengo lo storico della cartella'))
        await fetchEvents()
        dispatch(setProgress(false))
        dispatch(setCurrentDirectory(directory))
    }


    return (
        <View
            canDelete={(can?.delete && canDeleteDirectory) || false}
            canManage={(can?.manage && canSeeRules && canUpdateRules) || false}
            canWrite={(can?.write && canUpdateDirectory) || false}
            onCreateClick={onCreateClick}
            onDeleteClick={onDeleteClick}
            onDownloadClick={onDownloadClick}
            onEditClick={onEditClick}
            onShareClick={onShareClick}
            onPermissionClick={onPermissionClick}
            canPaste={toPaste.size > 0 && canUpdateDirectory}
            onPaste={onPaste}
            onHistoryClick={onHistoryClick}
            canViewHistory={canViewHistory}
            numberOfItemsToPaste={toPaste.size}
        >
            {children}
        </View>
    )
}

export default DirectoryContextMenu
