import {tag} from "../../types/tag";
import { Record } from "immutable";
import {IFile} from "../../types/file";

export type State = {
    selectedItem: IFile | null,
    tags: tag[],
    isChoosingTags: boolean,
    isModifyingTag: boolean,
    isCreatingTag: boolean,
    selectedTags: tag[],
    tagToModify: tag | null,
    defaultColors: string[],
    isSaving: boolean

};

class InitialState extends Record<State>({
    selectedItem: null,
    tags: [],
    isChoosingTags: false,
    isModifyingTag: false,
    isCreatingTag: false,
    selectedTags: [],
    tagToModify: null,
    defaultColors: ["#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#795548", "#607d8b"],
    isSaving: false
}) {}

const initialState = new InitialState();

export default initialState;
