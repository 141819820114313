import React, {memo} from "react";
import {Card, CardContent, Divider, Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {AccountCircle, Add, Delete, Edit, GetApp, PersonOutline} from "@material-ui/icons";
import { userLink} from "../../../links";
import { Event } from 'content-manager/types/event'
import {isFileEvent} from "../../helpers/typeGuards";
import {
    TimelineConnector,
    TimelineContent,
    TimelineDot, TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator
} from "@material-ui/lab";
import moment from "moment";

const useStyle = makeStyles({
    user: {
        display: 'flex',
        flexDirection: 'row',
        '& > svg': { marginRight: '15px'}
    }
})

type Props = { event: Event, isLast: boolean }

const EventsTimelineItem: React.FC<Props> = ({ event, isLast }) => {
    const classes = useStyle()

    const avatar = event.user ? <Link className={classes.user} to={userLink(event.user.id || '')}>
        <AccountCircle />
        <Typography>{event.user.username} ({event.user.email})</Typography>
    </Link> : <span>
        <PersonOutline/>
        <Typography>Utente sconosciuto</Typography>
    </span>

    let content, icon

    if(isFileEvent(event)) {
        switch (event.action) {
            case "download":
                content = <Typography>Scaricato il file: "{event.value.name}"</Typography>
                icon = <GetApp />
                break
            case "edit":
                content = <Typography>Modificato il nome del file da"{event.value.before}" a
                    "{event.value.after}"</Typography>
                icon = <Edit />
                break
            default:
                content = <Typography>???</Typography>
                break;
        }
    } else {
        switch (event.action) {
            case "create_child":
                content = <Typography>Creata la cartella: {event.value.name}</Typography>
                icon = <Add />
                break
            case "create_file":
                content = <Typography>Creato il file: {event.value.name}</Typography>
                icon = <Add />
                break
            case "delete_child":
                content = <Typography>Eliminata la cartella: {event.value.name}</Typography>
                icon = <Delete />
                break
            case "delete_file":
                content = <Typography>Eliminato il file: {event.value.name}</Typography>
                icon = <Delete />
                break
            case "download":
                icon = <GetApp />
                content = <Typography>Scaricata la cartella: {event.value.name}</Typography>
                break
            case "edit":
                content = <Typography>Modificato il nome da della cartella "{event.value.before}" a "{event.value.after}"</Typography>
                icon = <Edit />
                break
            default:
                content = <Typography>???</Typography>
                break;
        }
    }


    return <>
         <TimelineItem>
            <TimelineOppositeContent>
                <Typography color="textSecondary">{moment(event.createdAt).format('LLLL')}</Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
                <TimelineDot variant='outlined' >
                    {icon}
                </TimelineDot>
                {!isLast && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>
                <Card>
                    <CardContent>
                        {avatar}
                        <Divider/>
                        {content}
                    </CardContent>
                </Card>
            </TimelineContent>
         </TimelineItem>
    </>
}

export default memo(EventsTimelineItem)

