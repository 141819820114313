import {AclRuleGroup} from "../types/aclRuleGroup";
import { Record, List } from 'immutable'
import {aclAction} from "../../acl.old/types/aclRule";


class AclState extends Record<{
    groups: List<AclRuleGroup>
    currentGroupId: string | null
    permissions: {[dirId: string]: {[key in aclAction]: boolean}}
    fetchedPermissions: boolean
}>({
    groups: List(),
    currentGroupId: null,
    permissions: {},
    fetchedPermissions: false
}) {}

const aclState = new AclState()
export default aclState
