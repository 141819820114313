import React from "react";
import Wrapper from "./wrapper";
import SideBar from "../../components/Sidebar";
import FileViewer from "../../components/FileViewer";
import {RootState} from "../../../App";
import {currentUser} from "../../../auth/store/selectors";
import {connect, ConnectedProps} from "react-redux";
import DirectoryCreateDialog from "../../components/DirectoryCreateDialog";
import {RouteComponentProps} from 'react-router-dom'
import DirectoryUpdateDialog from "../../components/DirectoryUpdateDialog";
import ShareSideBar from "../../components/ShareSideBar";
import EventsSidebar from "../../components/EventsSidebar";
import DirectoryAclControls from "../../../acl/components/DirectoryAclControls";
import {useCurrentUserCan} from "../../../roles-and-permissions/hooks/useCurrentUserCan";
import TagsDialog from '../../../content-manager/components/TagsDialog';
import SearchPage from "../../../search/components/SearchPage";

const mapState = (state: RootState) => {
    return {
        currentUser: currentUser(state)
    }
}

const connector = connect(mapState)
type Props = RouteComponentProps<{directoryId: string | undefined,  text: string | undefined} > & ConnectedProps<typeof connector>

const ContentManager = ({currentUser, match}: Props) => {
    const canSeeGroups = useCurrentUserCan('application', 'acl-group', 'find')
    const canSeeUsers = useCurrentUserCan('users-permissions', 'user', 'find')
    const canUpdateDirectory = useCurrentUserCan('application', 'directory', 'update')
    const canCreateDirectory = useCurrentUserCan('application', 'directory', 'create')
    const canSeeDirectoryEvents = useCurrentUserCan('application', 'directory-event', 'findone')
    const canSeeDirectories = useCurrentUserCan('application', 'directory', 'find')
    const canSeeFiles = useCurrentUserCan('application', 'file-item', 'find')
    const canSeeRuleGroups = useCurrentUserCan('application', 'acl-rule-group', 'findone')
    const canSeeTags = useCurrentUserCan('application', 'tags', 'findone')
    const canSearch = useCurrentUserCan('application', 'file-item', 'search')

    return <div>
        {currentUser && (<Wrapper>
            {canSeeDirectories && <SideBar /> }
            {match.params.text && canSearch && canSeeFiles && <SearchPage/>}
            { match.params.directoryId && match.params.directoryId !== 'search' && canSeeFiles && <FileViewer directoryId={match.params.directoryId} /> }
            {canCreateDirectory && <DirectoryCreateDialog/>}
            {canUpdateDirectory && <DirectoryUpdateDialog/>}
            {canSeeGroups && canSeeUsers && <ShareSideBar/>}
            {canSeeRuleGroups && <DirectoryAclControls />}
            {canSeeDirectoryEvents && <EventsSidebar/>}
            {canSeeTags && <TagsDialog/>}
        </Wrapper>)}
    </div>
}

export default connector(ContentManager)
