import { RootState } from "../../App";
import { createSelector } from "reselect";
import { Directory } from "../types/directory";
import { IFile } from "../types/file";

export const state = (state: RootState) => state["content-manager"];

export const currentDirectory = createSelector(
    state,
    (state) => state.currentDirectory,
);

export const currentFiles = createSelector(
    currentDirectory,
    currentDirectory => currentDirectory?.files
)

export const directories = createSelector(state, (state) => state.directories);

export const getSharedIds = createSelector(state, state => state.sharedIds)

export const showBlocked = createSelector(state, state => state.showBlocked)

export const folderFilter = createSelector(state, state => state.filter)

export const highlightedFile = createSelector(state, state => state.highlightedFile)
export const scrollListTo = createSelector(state, state => state.scrollListTo)

export const getSharedDirectories = createSelector(
    [directories, getSharedIds, showBlocked, folderFilter],
    (directories, sharedIds, blocked, filter) => directories
        .filter(i => sharedIds.includes(i._id))
        .filter(i => {
            if(i.user?.blocked === true) {
                return blocked
            } else {
                return true
            }
        })
        .filter(i => filter.trim().length > 0 ? i.name.toLowerCase().includes(filter.trim().toLowerCase()) : true)
)

export const getUserDirectories = createSelector(
    [directories, getSharedIds],
    (directories, sharedIds) => directories
        .filter(i => !sharedIds.includes(i._id))
)

export const files = createSelector(state, (state) => state.files)

export const directoryToCreate = createSelector(
    state,
    (state) => state.directoryToCreate,
);

export const isCreating = createSelector(state, (state) => state.isCreating);

export const directoryToUpdate = createSelector(state, state => state.directoryToUpdate)

export const isUpdatingDirectory = createSelector(state, state => state.isUpdatingDirectory)

export const copiedFile = createSelector(state, state => state.copiedFile)

export const cuttedFile = createSelector(state, state => state.cuttedFile)

export const parentDirectories = createSelector(directories, items => items.filter(i => !i.parent))
export const makeDirectoryByIdSelector = (id: string) => {
    return createSelector(state, (state) =>
        state.directories.find((d) => d._id === id),
    );
};

export const makeChildrenDirectoriesSelector = (d: Directory) => {
    return createSelector(directories, (directories) =>
        directories.filter((directory) => directory.parent?._id === d._id),
    );
};

export const makeDirectoryFilesSelector = (d: Directory) => {
    return createSelector(files, files => files.filter(file => file.directory === d._id))
}

export const getSelectedFiles = createSelector(
    state,
    (state) => state.selectedFiles,
);

export const getFilesToPaste = createSelector(
    state,
    (state) => state.copiedFile.size > 0 ? state.copiedFile : state.cuttedFile
)

export const makeIsSelected = (f: IFile) => {
    return createSelector(getSelectedFiles, (selectedFiles) =>
        selectedFiles.some((file) => file._id === f._id),
    );
};
