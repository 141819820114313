import View from "./view";
import React, {useCallback} from 'react'
import {IFile} from "../../types/file";
import {deleteFile, downloadFile, setCopiedFile, setCuttedFile, setSelectedFiles} from "../../store/actions";
import { useDispatch, useSelector} from "react-redux";
import {copiedFile, cuttedFile, getSelectedFiles} from "../../store/selectors";
import {MessageType, setMessage, setProgress} from "../../../ui/store/actions";
import {setCurrentFile} from "../../store/events/file/actions";
import {useFetchFileEvents} from "../../hooks/useFetchFileEvents";
import {useCan} from "../../../acl/hooks/useCan";
import {useCurrentUserCan} from "../../../roles-and-permissions/hooks/useCurrentUserCan";
import axios from 'axios'
import {fetchGroupById, setCurrentAclRuleGroupId} from "../../../acl/store/actions";
import {fetchTags, setIsChoosingTags, setSelectedItem, setSelectedTags} from "../../store/tags/actions";
import {tag} from "../../types/tag";
import {isTag} from "../../helpers/typeGuards";
import {useIsOnline} from '../../../network/utils'

type ComponentProps = { file: IFile , renderTag?: React.ReactType }

type Props = ComponentProps

const FileContextMenu: React.FC<Props> = ({ file, children, renderTag }) => {
    const dispatch = useDispatch()
    const copied = useSelector(copiedFile)
    const cutted = useSelector(cuttedFile)
    const can = useCan(file)
    const canViewHistory = useCurrentUserCan('application', 'file-event', 'find')
    const canDownload = useCurrentUserCan('application', 'file-item', 'download')
    const canPreview = useCurrentUserCan('application', 'file-item', 'preview')
    const canSeeRules = useCurrentUserCan('application', 'acl-rule-group', 'find')
    const canUpdateRules = useCurrentUserCan('application', 'acl-rule-group', 'update')
    const canCopyFiles = useCurrentUserCan('application', 'file-item', 'copy')
    const canCutFiles = useCurrentUserCan('application', 'file-item', 'cut')
    const canDeleteFiles = useCurrentUserCan('application', 'file-item', 'delete')
    const selectedFiles = useSelector(getSelectedFiles)

    const [fetchEvents] = useFetchFileEvents(file)

    const onDelete = async () => {
        await dispatch(deleteFile(file))
        dispatch(setMessage({message: 'File eliminato', type: MessageType.info}))
    }


    const onPermissionClick = useCallback(async () => {
        dispatch(setProgress(true, 'Ottengo i permessi della cartella'))
        try {
            if(file.acl_rule_group) {
                const id = typeof file.acl_rule_group === 'string' ? file.acl_rule_group : file.acl_rule_group.id
                await dispatch(fetchGroupById({id}))
                dispatch(setCurrentAclRuleGroupId(id))
            }
        } finally {
            dispatch(setProgress(false))
        }
    }, [dispatch, file.acl_rule_group])

    const onPreviewClick = useCallback(async () => {
        const {data} = await axios.get(`/file-items/${file.id}/preview`)
        window.open(axios.defaults.baseURL + data)
    }, [file.id])


    const onDownloadClick = async () => {
        await dispatch(downloadFile(file, (e) => {
            dispatch(setProgress(true, `Download in corso (${Math.round((e.loaded * 100) / e.total)}%)` ))
        }))
        dispatch(setProgress(false))
    }

    const onHistoryClick = useCallback(async () => {
        dispatch(setProgress(true, 'Ottengo lo storico del file'))
        await fetchEvents()
        dispatch(setProgress(false))
        dispatch(setCurrentFile(file))
    }, [dispatch, fetchEvents, file])

    const onCopy = useCallback(async () => {
        if(selectedFiles.size > 0) {
            dispatch(setCopiedFile(selectedFiles.toJS()))
            dispatch(setSelectedFiles([]))
        } else {
            dispatch(setCopiedFile([file]))
        }
    }, [dispatch, file, selectedFiles])

    const onCut = useCallback(async () => {
        if(selectedFiles.size > 0) {
            dispatch(setCuttedFile(selectedFiles.toJS()))
            dispatch(setSelectedFiles([]))
        } else {
            dispatch(setCuttedFile([file]))
        }
    }, [dispatch, file, selectedFiles])

    const isCopied = copied.findIndex(v => v.id === file.id) !== -1
    const isCutted = cutted.findIndex(v => v.id === file.id) !== -1


    const canManageTag = useCurrentUserCan('application', 'tags', 'update') && ((can?.manage && canSeeRules) || false)
    const onTagClick = useCallback(async () => {
        if(canManageTag) {
            dispatch(setProgress(true, 'Ottengo le tag disponibili'))
            try {
                dispatch(setSelectedItem(file))
                const res: any = await dispatch(fetchTags())
                if (!!file.tags && file.tags.length !== 0) {
                    let selectedTagOfFile: tag[] = res.filter((t: tag) => {
                            // @ts-ignore
                            if (isTag(file.tags[0])) {
                                // @ts-ignore
                                return file.tags.find(ft => ft._id === t._id)
                            } else {
                                // @ts-ignore
                                return file.tags.includes(t._id)
                            }
                        }
                    )
                    await dispatch(setSelectedTags(selectedTagOfFile))
                }
                dispatch(setIsChoosingTags(true))
            } finally {
                dispatch(setProgress(false))
            }
        }
    }, [canManageTag, dispatch, file])

    return <View
        onDelete={onDelete}
        onCopy={onCopy}
        onCut={onCut}
        canCopy={canCopyFiles}
        isCopied={isCopied}
        canCut={canCutFiles}
        isCutted={isCutted}
        onPermissionClick={onPermissionClick}
        onDownloadClick={onDownloadClick}
        onHistoryClick={onHistoryClick}
        onTagClick={onTagClick}
        canManage={(can?.manage && canSeeRules && canUpdateRules) || false}
        canWrite={can?.write || false}
        canDelete={(can?.delete && canDeleteFiles) || false}
        canViewHistory={canViewHistory}
        canDownload={canDownload}
        canPreview={canPreview}
        onPreviewClick={onPreviewClick}
        canManageTag={canManageTag}
        renderTag={renderTag}
    >
        {children}
    </View>

}

export default FileContextMenu
