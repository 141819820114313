import initialState from "./state";
import {Actions} from "./actions";

const reducer = (state = initialState, action: Actions ) => {
    switch (action.type) {
        case "search/setSearchResults":
            return state.set('searchResults', action.payload)
        case "search/setHasFetched":
            return state.set('hasFetched', action.payload)
        case "search/setIsFetching":
            return state.set('isFetching', action.payload)
        case "search/setSearchText":
            return state.set('searchText', action.payload)
        case "search/resetStore":
            return state.set('searchText', '')
                .set('searchResults', [])
                .set('hasFetched', false)
                .set('isFetching', false)
        default:
            return state
    }
}

export default reducer
