import {Directory} from "../../../types/directory";
import {RootState} from "../../../../App";
import { createSelector } from 'reselect'
import { List } from 'immutable'

const state = (state: RootState) => state["directory-events"]

export const getEvents = createSelector(
    state,
    state => state.events
)

export const getCurrentDirectory = createSelector(
    state,
    state => state.currentDirectory
)

export const getCurrentDirectoryEvents = createSelector(
    [getEvents, getCurrentDirectory],
    (events, currentDir) => currentDir ? events
        .filter(event => (typeof event.directory === 'string' ? event.directory : event.directory._id) === currentDir._id) : List([])
)

export const makeDirectoryEventsSelector = (directory: Directory) => createSelector(
    getEvents,
    events => events.filter(i => (typeof i.directory === 'string' ? i.directory : i.directory._id) === directory._id)
)

export const makeDirectoryEventByIdSelector = (id: string) => createSelector(
    getEvents,
    events => events.find(i => i._id === id)
)
