import initialState from "./state";
import {Actions} from "./actions";

const reducer = (state = initialState, action: Actions ) => {
    switch (action.type) {
        case "ui/message":
            return state.set('message', action.payload)
        case "ui/progress":
            return state.set('progress', action.payload)
        case "ui/setShowSidebar":
            return state.set('showSidebar', action.payload)
        case "ui/setConfirmation":
            return state.set('confirmation', action.payload)
        case "ui/resetStore":
            return state.set('message', undefined)
                .set('progress',{show: false, message: ''})
                .set('showSidebar', false)
                .set('confirmation', null)
        default:
            return state
    }
}

export default reducer
