import React, {useCallback} from 'react'
import {makeStyles} from "@material-ui/core/styles";
import {DateTimePicker} from "@material-ui/pickers";
import {Moment} from "moment";
import {useDispatch, useSelector} from "react-redux";
import {getFrom, getTo} from "../../store/selectors";
import {setFrom, setTo} from "../../store/actions";
import AwesomeDebouncePromise from "awesome-debounce-promise";

const useStyle = makeStyles({
    main: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, calc((100% - 15px) / 2))',
        gridColumnGap: '15px'
    }
})

const Filters: React.FC = () => {
    const dispatch = useDispatch()
    const classes = useStyle()
    const fromDate = useSelector(getFrom)
    const toDate = useSelector(getTo)
    const setFromDate = useCallback((v: null | Moment) => {
        dispatch(setFrom(v))
    },[dispatch])
    const setToDate = useCallback((v: null | Moment) => {
       dispatch(setTo(v))
    },[dispatch])

    const debouncedFrom = AwesomeDebouncePromise(setFromDate, 500)
    const debouncedTo = AwesomeDebouncePromise(setToDate, 500)

    return <div className={classes.main}>
        {/*{canViewUsers && <Users />}*/}
        <DateTimePicker ampm={false} label='Da' variant='inline' format={'LLLL'} value={fromDate} onChange={v => debouncedFrom(v)} />
        <DateTimePicker ampm={false} label='A' variant='inline' format={'LLLL'} value={toDate} onChange={v => debouncedTo(v)} />
    </div>
}

export default Filters
