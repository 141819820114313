import React, {useState} from 'react'
import {Button, DialogActions, DialogContent, Divider, TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import {getDefaultColors, getIsSaving} from "../../../store/tags/selectors";
import {
    closeTagDialog, createTag,
    setIsChoosingTags,
    setIsCreatingTag,
    setIsModifyingTag, setIsSaving
} from "../../../store/tags/actions";
import {CirclePicker} from "react-color";

const useStyles = makeStyles({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        height: '300px',
        overflow: 'auto'
    },
    selectedColor: {
        height: '50px',
        width: '50px',
        position: 'relative',
        outline: 'none',
        borderRadius: '4px',
    }
})

const CreateNewTag: React.FC = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const defaultColors = useSelector(getDefaultColors)
    const isSaving = useSelector(getIsSaving)
    const [tmpTag, setTmpTag] = useState({title: '', color: ''})

    let isFormValid = tmpTag !== null && tmpTag.title !== '' && tmpTag.color !== ''
    const checkValidation = () => {
        isFormValid = tmpTag !== null && tmpTag.title !== '' && tmpTag.color !== ''
    }

    const onclick = async () => {
        await dispatch(setIsSaving(true))
        if(tmpTag !== null) await dispatch(createTag(tmpTag))
        await activeChoosingTags()
        await dispatch(setIsSaving(false))
    }

    const onkeypress = async (e: any) => {
        if(e.keyCode === 13){
            await onclick()
        }
    }

    const activeChoosingTags = async () => {
        dispatch(setIsChoosingTags(true))
        dispatch(setIsCreatingTag(false))
        dispatch(setIsModifyingTag(false))
    }

    return (
        <>
            <DialogContent style={{display: 'flex', flexDirection: 'column', height: '300px', overflow: 'auto', width: '400px'}}>
                <div className={classes.wrapper}>
                    {tmpTag !== null ?
                        <div>
                            <TextField
                                required id="tag-name" label="Nome tag"
                                onChange={(event: any) => {
                                    checkValidation()
                                    setTmpTag({title: event.target.value, color: tmpTag.color})
                                }}
                                onKeyDown={onkeypress}
                                defaultValue={tmpTag.title} style={{width: '100%', marginBottom: '14px'}}
                            />
                            <div style={{margin: '7px', width: '100%', display: 'flex', flexDirection: 'row'}}>
                                <CirclePicker
                                    color={tmpTag.color} colors={defaultColors}
                                    onChange={(color: any) => {
                                        checkValidation()
                                        setTmpTag({title: tmpTag.title, color: color.hex})
                                    }}
                                />
                                <div style={{width: '40%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <div className={classes.selectedColor} style={{background: tmpTag.color}} />
                                </div>
                            </div>
                        </div> : null
                    }
                </div>
            </DialogContent>
            <Divider style={{marginBottom: '10px'}} />
            <DialogActions>
                <Button disabled={isSaving} onClick={() => dispatch(closeTagDialog())}>Chiudi</Button>
                <Button disabled={isSaving} onClick={activeChoosingTags}>Torna indietro</Button>
                <Button disabled={!isFormValid || isSaving} onClick={onclick}>Crea</Button>
            </DialogActions>
        </>
    )
}

export default CreateNewTag;
