import {RootState} from "../../App";
import {createSelector} from 'reselect'

export const state = (s: RootState) => s["acl-group"]

export const aclGroups = createSelector(
    state,
    (state) => state.get('aclGroups')
)

export const currentGroup = createSelector(
    state,
    (state) => state.get('currentGroup')
)
