import axios from 'axios'
import {AclRuleGroup} from "../types/aclRuleGroup";
import {aclAction, AclRule, UnsavedAclRule, WithRel} from "../../acl.old/types/aclRule";
import {AppThunk} from "../../App";
import {Directory} from "../../content-manager/types/directory";

type AddAclRuleGroup = {
    type: 'acl/addAclRuleGroup',
    payload: AclRuleGroup
}

type SetAclRuleGroups = {
    type: 'acl/setAclRuleGroups',
    payload: AclRuleGroup[]
}

type SetCurrentAclRuleGroupId = {
    type: 'acl/setCurrentAclRuleGroupId',
    payload: string | null
}

type AddPermission = {
    type: 'acl/addPermission',
    payload: [string, {[key in aclAction]: boolean}]
}

type ResetStore = {
    type: 'acl/resetStore',
    payload: boolean
}

type SetFetchedPermissions = {
    type: 'acl/setFetchedPermissions',
    payload: boolean
}

export const resetAclStore = (payload: boolean): ResetStore => ({
    type: "acl/resetStore",
    payload
})

export const addAclRuleGroup = (payload: AclRuleGroup): AddAclRuleGroup => ({
    type: "acl/addAclRuleGroup",
    payload
})

export const setAclRuleGroups = (payload: AclRuleGroup[]): SetAclRuleGroups => ({
    type: "acl/setAclRuleGroups",
    payload
})

export const setCurrentAclRuleGroupId = (payload: string | null): SetCurrentAclRuleGroupId => ({
    type: "acl/setCurrentAclRuleGroupId",
    payload
})

export const addPermission = (payload: AddPermission['payload']): AddPermission => ({
    type: "acl/addPermission",
    payload
})

export const setFetchedPermissions = (payload: boolean): SetFetchedPermissions => ({
    type: 'acl/setFetchedPermissions',
    payload
})

export const canUserDo = (directory: Directory, action: aclAction): AppThunk<Promise<boolean>> => async () => {
    const { data } = await axios.get<{can: boolean}>(`/directory/${directory._id}/can/${action}`)
    return data.can
}

export const whatCanUserDo = (directory: Directory): AppThunk<Promise<{[key in aclAction]: boolean}>> => async () => {
    const { data } = await axios.get<{[key in aclAction]: boolean}>(`/directory/${directory._id}/can`)
    return data
}

export const fetchGroupById = ({id}: {id: string}): AppThunk<Promise<AclRuleGroup>> => async dispatch => {
    const res = await axios.get<AclRuleGroup>(`/acl-rule-groups/${id}`)
    dispatch(addAclRuleGroup(res.data))
    return res.data
}

export const createGroup = (directory: Directory): AppThunk<Promise<AclRuleGroup>> => async dispatch => {
    const { data } = await axios.post<AclRuleGroup>(`acl-rule-groups`, { directory: directory._id })
    dispatch(addAclRuleGroup(data))
    return data
}

export const addRuleToGroup = (rule: UnsavedAclRule, group: AclRuleGroup, refresh: boolean = true): AppThunk<Promise<AclRule>> => async dispatch => {
    const res = await dispatch(createRule({
        ...rule,
        acl_rule_group: group.id
    }))
    if(refresh) {
        await dispatch(fetchGroupById(group))
    }
    return res
}

export const deleteRule = (rule: AclRule): AppThunk<Promise<void>> => async dispatch => {
    await axios.delete(`/acl-rules/${rule.id}`)

    if(rule.acl_rule_group) {
        await dispatch(fetchGroupById({id: typeof rule.acl_rule_group === 'string' ? rule.acl_rule_group : rule.acl_rule_group.id}))
    }
}

export const createRule = (rule: UnsavedAclRule): AppThunk<Promise<AclRule>> => async () => {
    return axios.post(`/acl-rules`, rule)
}

export const updateAclRule = (rule: AclRule): AppThunk<Promise<AclRule>> => async dispatch => {
    const { data } = await axios.put<WithRel<AclRule>>(`/acl-rules/${rule.id}`, rule)
    if(data.acl_rule_group) {
        await dispatch(fetchGroupById({id: data.acl_rule_group.id}))
    }
    return data
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const canCache: {
    [dirId: string]: {
        [key in aclAction]: boolean
    }
} = {}

export const fetchPermission = (directory: Directory | string): AppThunk<Promise<void>> => async (dispatch, getState) => {
    const id = typeof directory === 'string' ? directory : directory._id
    const permissions = getState().acl.permissions
    const shouldFetch = !getState().acl.fetchedPermissions
    if(!permissions.hasOwnProperty(id) && shouldFetch) {
        dispatch(setFetchedPermissions(true))
        const res = await axios.get<{[key in aclAction]: boolean}>(`/directories/${id}/can`)
        dispatch(addPermission([id, res.data]))
    }
}

export type Actions = AddAclRuleGroup | SetAclRuleGroups | SetCurrentAclRuleGroupId | AddPermission | ResetStore | SetFetchedPermissions
