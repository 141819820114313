import {useEffect} from "react";
import {fetchAclGroups} from "../../acl.old/store/actions";
import {useDispatch, useSelector} from "react-redux";
import {aclGroups as getAclGroups} from "../../acl.old/store/selectors";
import {AclGroup} from "../../acl.old/types/aclGroup";

export const useAvailableAclGroups = (): AclGroup[] => {
    const dispatch = useDispatch()
    const availableGroups = useSelector(getAclGroups).toJS() as AclGroup[]

    useEffect(() => {
        if(availableGroups.length === 0) {
            dispatch(fetchAclGroups())
        }
    }, [availableGroups, dispatch])

    return availableGroups
}
