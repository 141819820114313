import {Drawer, List, ListItem, ListItemIcon} from "@material-ui/core";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {getShowSidebar} from "../../store/selectors";
import {setShowSidebar} from "../../store/actions";
import {Link, useLocation} from 'react-router-dom'
import {AccessTime, InsertDriveFile, People, Person, Security} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import {useCurrentUserCan} from "../../../roles-and-permissions/hooks/useCurrentUserCan";

const useStyle = makeStyles({
    wrapper: {
        width: '30vh'
    }
})

const Sidebar: React.FC = () => {
    const {wrapper} = useStyle()
    const dispatch = useDispatch()
    const close = () => dispatch(setShowSidebar(false))
    const show = useSelector(getShowSidebar)
    const location = useLocation()
    const canViewFiles = useCurrentUserCan('application', 'file-item', 'find')
    const canViewDirectory = useCurrentUserCan('application', 'directory', 'findone')
    const canViewDirectories = useCurrentUserCan('application', 'directory', 'find')
    const canViewUsers = useCurrentUserCan('users-permissions', 'user', 'find')
    const canViewUser = useCurrentUserCan('users-permissions', 'user', 'findone')
    const canViewRoles = useCurrentUserCan('users-permissions', 'userspermissions', 'getroles')
    const canViewGroups = useCurrentUserCan('application', 'acl-group', 'find')
    const canViewFileEvents = useCurrentUserCan('application','file-event', 'find')
    const canViewDirectoryEvents = useCurrentUserCan('application','file-event', 'find')
    const routes = []

    if(canViewDirectories && canViewFiles && canViewDirectory) routes.push({
        path: '/',
        icon: InsertDriveFile,
        text: 'Files'
    })

    if(canViewGroups) routes.push({
        path: '/groups',
        icon: People,
        text: 'Gruppi'
    })

    if(canViewRoles) routes.push({
        path: '/roles',
        icon: Security,
        text: 'Ruoli e Permessi'
    })

    if(canViewUsers && canViewUser && canViewRoles) routes.push({
        path: '/users',
            icon: Person,
            text: 'Utenti'
    })

    if(canViewDirectoryEvents && canViewFileEvents) routes.push({
        path: '/events',
        icon: AccessTime,
        text: 'Storico Eventi'
    })

    return <Drawer open={show} onClose={close} anchor="left">
        <List className={wrapper}>
            {routes.map(route => {
                const Icon = route.icon
                return <Link key={route.path} to={route.path} onClick={close}>
                    <ListItem selected={route.path === location.pathname}>
                        <ListItemIcon>
                            <Icon />
                        </ListItemIcon>
                        {route.text}
                    </ListItem>
                </Link>
            })}
        </List>
    </Drawer>
}

export default Sidebar
