import {SavedRole} from "../types/role";
import { List, Record } from 'immutable'

interface Store {
    roles: List<SavedRole>
}

class RolesAndPermissionsStore extends Record<Store>({
    roles: List([])
}) {}

const rolesAndPermissionsStore = new RolesAndPermissionsStore()

export default rolesAndPermissionsStore
