import {useCallback, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchRoleById} from "../store/actions";
import {makeGetRoleById} from "../store/selectors";
import {Role} from "../types/role";

let fetchList: string[] = []

export const useFetchRoleById = ({ id }: { id: string }): [Role | undefined, () => Promise<void>, boolean] => {
    const dispatch = useDispatch()
    const role = useSelector(makeGetRoleById({ id }))
    const [fetching, setFetching] = useState<boolean>(fetchList.includes(id))
    const fetch = useCallback(async () => {
        if(!fetching) {
            fetchList.push(id)
            setFetching(true)
            await dispatch(fetchRoleById({id}))
            setFetching(false)
        }
    }, [fetching, dispatch, id])

    return [role, fetch, fetching]
}
