import {RootState} from "../../App";
import {createSelector} from 'reselect'

const state = (state: RootState) => state.search

export const getSearchText = createSelector(
    state,
    state => state.searchText
)

export const getSearchResults = createSelector(
    state,
    state => state.searchResults
)

export const getHasFetched = createSelector(
    state,
    state => state.hasFetched
)

export const getIsFetching = createSelector(
    state,
    state => state.isFetching
)

