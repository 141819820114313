import React, {useEffect} from 'react'
import {RootState} from "../../../App";
import {
    makeDirectoryByIdSelector,
    makeChildrenDirectoriesSelector,
    makeDirectoryFilesSelector
} from "../../store/selectors";
import {connect, ConnectedProps, useDispatch} from "react-redux";
import {withRouter, RouteComponentProps} from 'react-router-dom'
import {fetchChildrenDirectories, fetchDirectoryById, setCurrentDirectory} from "../../store/actions";
import View from "./view";
import DirectoryContextMenu from "../DirectoryContextMenu";
import {useHistory} from "react-router";

type ComponentProps = {directoryId: string}

const mapState = (state: RootState, props: ComponentProps) => {
    return {
        directory: makeDirectoryByIdSelector(props.directoryId)(state),
        childrenDirectories: makeChildrenDirectoriesSelector({_id: props.directoryId, files: [], name: ""})(state),
        files: makeDirectoryFilesSelector({_id: props.directoryId, files: [], name: ""})(state)
    }
}

const connector = connect(mapState)
type Props = ConnectedProps<typeof connector>

export const FileViewer: React.FC<Props> = ({directory, childrenDirectories, files}) => {
    const history = useHistory()
    const dispatch = useDispatch()

    useEffect(() => {
        if(directory) {
            dispatch(setCurrentDirectory(directory))
            dispatch(fetchChildrenDirectories(directory))
        } else {
            dispatch(fetchDirectoryById(history.location.pathname.replace('/', '')))
        }
    }, [dispatch, history, directory])

    if(directory) {
        return <DirectoryContextMenu directory={directory} showOpen={false}>
            <View directory={directory} childrenDirectories={childrenDirectories} files={files} />
        </DirectoryContextMenu>
    } else {
        return <span>Loading</span>
    }
}

export default connector(FileViewer)
