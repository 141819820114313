import React, {useCallback, useEffect} from "react";
import View from './view'
import {User} from "../../../auth/types/user";
import {AclGroup} from "../../types/aclGroup";
import {MessageType, setMessage} from "../../../ui/store/actions";
import {useDispatch, useSelector} from "react-redux";
import {updateGroup} from "../../store/actions";
import {users as getUsers} from '../../../users/userStore/selectors'
import {useCurrentUserIsSuperadmin} from '../../../acl/hooks/useCurrentUserIsSuperadmin'
import {useCanShowItem} from '../../../acl/hooks/useCanShowItem'


type Props = {
    currentGroup: AclGroup
}

function not(a: User[], b: User[]) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: User[], b: User[]) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a: User[], b: User[]) {
    return [...a, ...not(b, a)];
}

const TransferList = ({currentGroup}: Props) => {
    const users = useSelector(getUsers)

    const dispatch = useDispatch()

    const getNotMembers = useCallback(() => {
        let o: User[]
        if (currentGroup.users.length) {
            o = users.filter((u: User) => !currentGroup.users.some(memberUser => memberUser._id === u._id))
        } else {
            o = users
        }
        return o
    }, [currentGroup, users])

    const getMembers = useCallback(() => {
        return currentGroup.users.length ? currentGroup.users : []
    }, [currentGroup])

    const [initialSelected, setInitialSelected] = React.useState<User[]>(getMembers());
    const [initialNotSelected, setInitialNotSelected] = React.useState<User[]>(getNotMembers())
    const [checked, setChecked] = React.useState<User[]>([]);
    const [left, setLeft] = React.useState<User[]>(initialNotSelected);     //  NOT SELECTED USERS
    const [right, setRight] = React.useState<User[]>(initialSelected);   //  SELECTED USERS
    useEffect(() => {
        setInitialSelected(getMembers())
        setInitialNotSelected(getNotMembers())
    }, [getMembers, getNotMembers, currentGroup])

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const onClick = async () => {
        try {
            let newGroup = currentGroup
            newGroup.users = right
            await dispatch(updateGroup(newGroup))
        } catch (e) {
            dispatch(setMessage({
                message: e.toString(),
                type: MessageType.error
            }))

        } finally {
            dispatch(setMessage({message: 'Il gruppo é stato salvato!', type: MessageType.success, timeout: 5000}))
        }
    }
    const handleToggle = (value: User) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };
    const numberOfChecked = (items: User[]) => intersection(checked, items).length;
    const handleToggleAll = (items: User[]) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };
    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };
    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };
    const canShow = useCanShowItem()
    return (
            <View
                currentGroup={currentGroup}
                users={users.filter(canShow)}
                checked={checked}
                left={left.filter(canShow)}
                right={right.filter(canShow)}
                leftChecked={leftChecked}
                rightChecked={rightChecked}
                handleToggle={handleToggle}
                handleToggleAll={handleToggleAll}
                handleCheckedRight={handleCheckedRight}
                handleCheckedLeft={handleCheckedLeft}
                numberOfChecked={numberOfChecked}
                onclick={onClick}
            />
        )
}

export default TransferList
