import {AppThunk} from "../../../../App";
import axios from 'axios'
import {FileEvent} from "../../../types/fileEvent";
import {IFile} from "../../../types/file";
import {User} from "../../../../auth/types/user";

type AddEvent = {
    type: 'fileEvent/addEvent',
    payload: FileEvent
}

type AddEvents = {
    type: 'fileEvent/addEvents',
    payload: FileEvent[]
}

type SetEvents = {
    type: 'fileEvent/setEvents',
    payload: FileEvent[]
}

type RemoveEvent = {
    type: 'fileEvent/removeEvent',
    payload: FileEvent
}

type SetCurrentFile = {
    type: 'fileEvent/setCurrentFile',
    payload: IFile | null
}

export const addEvent = (payload: FileEvent): AddEvent => ({
    type: "fileEvent/addEvent",
    payload
})

export const addEvents = (payload: FileEvent[]): AddEvents => ({
    type: "fileEvent/addEvents",
    payload
})

export const setEvents = (payload: FileEvent[]): SetEvents => ({
    type: "fileEvent/setEvents",
    payload
})

export const removeEvent = (payload: FileEvent): RemoveEvent => ({
    type: "fileEvent/removeEvent",
    payload
})

export const setCurrentFile = (payload: IFile | null): SetCurrentFile => ({
    type: "fileEvent/setCurrentFile",
    payload
})


export const fetchFileEvents = (file: IFile): AppThunk<Promise<FileEvent[]>> => async dispatch => {
    const { data } = await axios.get<FileEvent[]>(`/file-events?file_eq=${file._id}`)
    dispatch(addEvents(data))
    return data
}

export const fetchUserEvents = (user: User, sort: string | null = null): AppThunk<Promise<FileEvent[]>> => async dispatch => {
    const { data } = await axios.get<FileEvent[]>(`/file-events?user_eq=${user.id}${sort ? `&_sort=${sort}` : ''}`)
    dispatch(addEvents(data))
    return data
}

export type Actions = AddEvent | SetEvents | RemoveEvent | AddEvents | SetCurrentFile
