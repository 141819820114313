import React, {memo} from 'react'
import {Button, Dialog, DialogActions, DialogContent} from "@material-ui/core";

type Props = {
    onConfirm?: () => any
    onCancel?: () => any
    onClose?: () => any
    message?: string
    open: boolean
}

const ConfirmationDialog = ({open, onCancel, onClose, onConfirm, message}: Props) => {

    return (
        <Dialog onClose={onClose} open={open}>
            <DialogContent>
                {message || 'Sei sicuro di voler continuare?'}
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>
                    Cancella
                </Button>
                <Button onClick={onConfirm}>
                    Continua
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default memo(ConfirmationDialog)

