import {RootState} from "../../../App";
import {directoryToUpdate, isUpdatingDirectory} from "../../store/selectors";
import {ThunkDispatch} from 'redux-thunk'
import {setDirectoryToUpdate, setIsUpdatingDirectory, updateDirectory} from "../../store/actions";
import {Directory} from "../../types/directory";
import {connect, ConnectedProps} from "react-redux";
import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@material-ui/core";
import {Message, MessageType, setMessage} from "../../../ui/store/actions";
import {useIsOnline} from '../../../network/utils'

const mapState = (state: RootState) => ({
    open: isUpdatingDirectory(state),
    directory: directoryToUpdate(state)
})

const mapActions = (dispatch: ThunkDispatch<any,any,any>) => ({
    setOpen: (v: boolean) => dispatch(setIsUpdatingDirectory(v)),
    setDirectory: (d: Directory | null) => dispatch(setDirectoryToUpdate(d)),
    updateDirectory: (d: Directory) => dispatch(updateDirectory(d)),
    setMessage: (m: Message) => dispatch(setMessage(m))
})

const connector = connect(mapState, mapActions)

type Props = ConnectedProps<typeof connector>

const DirectoryUpdateDialog: React.FC<Props> = ({open, setOpen, directory, setDirectory, updateDirectory, setMessage}) => {
    const onClose = () => {
        setOpen(false)
        setDirectory(null)
    }

    const onCancel = () => { onClose() }

    const onConfirm = async () => {
        if(directory) {
            try {
                await updateDirectory(directory)
                setMessage({ message: 'Cartella Aggiornata', type: MessageType.success})
            } catch (e) {
                setMessage({ message: e, type: MessageType.error})
            } finally {
                onClose()
            }
        }
    }

    const canConfirm = directory && directory.name.length > 0

    const online = useIsOnline()

    return <Dialog open={open && online} onClose={onClose}>
        <DialogTitle>Modifica cartella</DialogTitle>
        <DialogContent>
            { directory && <TextField value={directory.name} onChange={(e) => setDirectory({...directory, name: e.target.value})} /> }
        </DialogContent>
        <DialogActions>
            <Button onClick={onCancel}>Cancella</Button>
            <Button onClick={onConfirm} disabled={!canConfirm}>Conferma</Button>
        </DialogActions>
    </Dialog>
}

export default connector(DirectoryUpdateDialog)
