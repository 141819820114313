import {useDispatch, useSelector} from "react-redux";
import {getRoles} from "../store/selectors";
import {useEffect} from "react";
import {fetchRoles} from "../store/actions";

export const useRoles = () => {
    const dispatch = useDispatch()
    const roles = useSelector(getRoles)
    useEffect(() => {
        if(roles.size <= 2) {
            dispatch(fetchRoles())
        }
    }, [dispatch, roles.size])
    return roles
}
