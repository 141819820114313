import {makeGetRoleById} from "../store/selectors";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {fetchRoleById} from "../store/actions";

export const useRole = ({ id }: { id: string }) => {
    const role = useSelector(makeGetRoleById({ id }))
    const dispatch = useDispatch()

    useEffect(() => {
        if((!role || !role.hasOwnProperty('permissions')) && id && id !== 'create') {
            dispatch(fetchRoleById({ id }))
        }
    }, [dispatch, role, id])

    return role
}
