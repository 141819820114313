import {AclGroup} from "../../acl.old/types/aclGroup";
import {User} from "../../auth/types/user";
import {IFile} from "../types/file";
import {Directory} from "../types/directory";
import {AclRule, UnsavedAclRule} from "../../acl.old/types/aclRule";
import {Event} from '../types/event'
import {FileEvent} from "../types/fileEvent";
import {tag} from "../types/tag";

export const isAclGroup = (i: any): i is AclGroup => {
    return i.hasOwnProperty('name') && i.hasOwnProperty('users')
}

export const isUser = (i: any): i is User => {
    return i.hasOwnProperty('username')
}

export const isFile = (i: any): i is IFile => {
    return i.hasOwnProperty('file')
}

export const isDirectory = (i: any): i is Directory => {
    return i.hasOwnProperty('files')
}

export const isUnsavedAclRule = (i: any): i is UnsavedAclRule => {
    return i.hasOwnProperty('acl_rule_group') && i.hasOwnProperty('write')
}

export const isAclRule = (i: any): i is AclRule => {
    return i.hasOwnProperty('id') && isUnsavedAclRule(i)
}

export const isFileEvent = (i: Event): i is FileEvent => {
    return i.hasOwnProperty('file')
}

export const isTag = (i: any): i is tag => {
    return i.hasOwnProperty('title') && i.hasOwnProperty('color')
}
export const isTagsArray = (i: any): i is tag[] => {
    return i.length !== 0 && i[0].hasOwnProperty('title') && i[0].hasOwnProperty('color')
}
