import { Record } from "immutable";

interface Store {
  online: boolean
}

class NetworkStore extends Record<Store>({
  online: true
}) {}

const networkStore = new NetworkStore()

export default networkStore
