import {Directory} from "../../types/directory";
import axios from 'axios'
import React, {useEffect, useState} from "react";
import {Breadcrumbs as MBreadcrumbs} from "@material-ui/core";
import {Link} from 'react-router-dom'
import styled from "styled-components";
import {useIsOnline} from '../../../network/utils'

type Props = {
    directory: Directory
    className?: string
}

type SLinkProps = {
    isCurrent: boolean
}

const SLink = styled(({ isCurrent, ...rest }) => <Link {...rest} />)<SLinkProps>`
  color: ${props => props.isCurrent ? props.theme.colors.dark : 'inherit'}
`

const Breadcrumbs: React.FC<Props> = ({directory, className}) => {
    const [directories, setDirectories] = useState<Directory[]>([])

    useEffect(() => {
        async function fetchData() {
            const { data } = await axios.get(`/directories/${directory._id}/parents`)
            setDirectories(data)
        }

        fetchData()
    }, [directory])

    const online = useIsOnline()

    return <MBreadcrumbs aria-label='breadcrumb' className={className}>
        {directories.map(dir => online ? <SLink isCurrent={directory._id === dir._id} key={dir._id} to={`/${dir._id}`}>{dir.name}</SLink> : <span>{dir.name}</span>)}
    </MBreadcrumbs>
}

export default Breadcrumbs
