import initialState from "./state";
import {Actions, JWT_TOKEN_KEY} from "./actions";
import axios from 'axios'

const setAxiosAuth = (state: typeof initialState) => {
    if(state.token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${state.token}`
    } else if (axios.defaults.headers.common.hasOwnProperty('Authorization') && !state.token) {
        delete axios.defaults.headers.common['Authorization']
    }
}

const setToken = (state: typeof initialState) => {
    if(state.remember && state.token) {
        window.localStorage.setItem(JWT_TOKEN_KEY, state.token)
    } else {
        window.localStorage.removeItem(JWT_TOKEN_KEY)
    }
}

export default function reducer(state = initialState, action: Actions): typeof initialState{
    let o: typeof initialState | null = null
    switch (action.type) {
        case "auth/setMe":
            o = state.set('me', action.payload)
            break;
        case "auth/setToken":
            o = state.set('token', action.payload)
            break;
        case "auth/setRemember":
            o = state.set('remember', action.payload)
            break;
        default:
            o = state
            break;
    }
    setAxiosAuth(o)
    setToken(o)
    return o
}
