import {Record} from 'immutable'
import {searchResults} from "../types/searchResults";
import {IFile} from "../../content-manager/types/file";
import {Directory} from "../../content-manager/types/directory";

export type SearchResult = IFile & { parents: Directory[]}

type SearchStore = {
    searchText: string,
    searchResults: SearchResult[],
    hasFetched: boolean,
    isFetching: boolean
}

class InitialState extends Record<SearchStore>({
    searchText: '',
    searchResults: [],
    hasFetched: false,
    isFetching: false
}) {}

const initialState = new InitialState()

export default initialState
