import React from "react";
import {User} from "../../../auth/types/user";
import {
    Button,
    Card,
    CardHeader,
    Checkbox,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText
} from "@material-ui/core";
import {AclGroup} from "../../types/aclGroup";
import styled from "styled-components";
import {useCurrentUserIsSuperadmin} from '../../../acl/hooks/useCurrentUserIsSuperadmin'
import {useCanShowItem} from '../../../acl/hooks/useCanShowItem'

type Props = {
    users: User[],
    handleToggle: (user: User) => () => void,
    handleToggleAll: (users: User[]) => () => void,
    handleCheckedRight: () => void,
    handleCheckedLeft: () => void,
    numberOfChecked: (users: User[]) => number,
    checked: User[],
    left: User[],
    right: User[],
    leftChecked: User[],
    rightChecked: User[],
    onclick: () => void,
    currentGroup: AclGroup
}

const Wrapper = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    
    & .grid-container {
        flex-wrap: nowrap;
        align-items: stretch;
    }
    
    & .grid-button {
        margin: 4px;
    }
    
    & .grid-save-button {
        padding: 5px; 
    }
    & .grid-buttons-container{
        width: auto !important;
    }
    
    & .grid-list {
        width: 225px;
        height: 100%;
    }
`

const StyledCard = styled(Card)`
  height: 100%;
  
  & .card-header {
    padding: 8px 16px;
  }
  
  & .card-list {
    min-width: 200px;
    max-width: 250px;
    height: 230px;
    overflow: auto;
  }
`


const View: React.FunctionComponent<Props> = ({
                                                  handleCheckedLeft,
                                                  handleCheckedRight,
                                                  handleToggle,
                                                  handleToggleAll,
                                                  numberOfChecked,
                                                  rightChecked,
                                                  leftChecked,
                                                  checked,
                                                  left,
                                                  right,
                                                  onclick,
                                                  currentGroup
}) => {
    const customList = (title: React.ReactNode, users: User[]) => (
        <StyledCard>
            <CardHeader
                className="card-header"
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(users)}
                        checked={numberOfChecked(users) === users.length && users.length !== 0}
                        indeterminate={numberOfChecked(users) !== users.length && numberOfChecked(users) !== 0}
                        disabled={users.length === 0}
                        inputProps={{ 'aria-label': 'all items selected' }}
                    />
                }
                title={title}
                subheader={`${numberOfChecked(users)}/${users.length} selezionati`}
            />
            <Divider />
            <List className="card-list" dense component="div" role="list">
                {users.map((user: User, index) => {
                    const labelId = `transfer-list-all-item-${user.username}-label`;
                    return (
                        <ListItem key={index} role="listitem" button onClick={handleToggle(user)}>
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(user) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={user.username} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </StyledCard>
    );
    return (
        <Wrapper>
            <Grid container spacing={2} justify="center" alignItems="center" className="grid-container">
                <Grid className="grid-list" item>{customList('Utenti', left)}</Grid>
                <Grid className="grid-buttons-container" container direction="column" justify="center" alignItems="center">
                    <Button
                        variant="outlined"
                        size="small"
                        className="grid-button"
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0}
                        aria-label="move selected right"
                    >
                        &gt;
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        className="grid-button"
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                        aria-label="move selected left"
                    >
                        &lt;
                    </Button>
                </Grid>
                <Grid className="grid-list" item>{customList('Membri', right)}</Grid>
            </Grid>
            <Button color='primary' className="grid-save-button" onClick={onclick}>
                SALVA
            </Button>
        </Wrapper>
    )
}

export default View
