import {Record} from 'immutable'
import {Message} from "./actions";

type UiState = {
    showSidebar: boolean,
    message: Message | undefined,
    progress: {
        show: boolean,
        message: string
    },
    confirmation: {
        message: string,
        onConfirm: () => any
    } | null
}

class InitialState extends Record<UiState>({
    message: undefined,
    progress: {
        show: false,
        message: ''
    },
    showSidebar: false,
    confirmation: null
}) {}

const initialState = new InitialState()

export default initialState
