import {Collapse, IconButton, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import React, {forwardRef} from "react";
import {Directory} from "../../types/directory";
import {ExpandLess, ExpandMore, Folder, FolderShared} from "@material-ui/icons";
import DirectoryContextMenu from "../DirectoryContextMenu";
import FileTreeDirectoryItem from "./index";
import {useSelector} from "react-redux";
import {currentDirectory} from "../../store/selectors";
import {makeStyles} from "@material-ui/core/styles";

type Props = {
    directory: Directory,
    open: boolean,
    childrenDirectories: Directory[],
    selected: boolean,
    onClick: () => any,
    onCollapseToggle: () => any
}

const useStyle = makeStyles({
    dir: {
        '&.blocked': {
            opacity: '.6'
        },
        '&.selected': {
            backgroundColor: 'rgba(0,0,0,.1)'
        }
    }
})

const View = forwardRef<HTMLDivElement, Props>(({ directory, selected, open, childrenDirectories, onClick, onCollapseToggle }, ref) => {
    const hasChildren = childrenDirectories.length > 0
    const current = useSelector(currentDirectory)
    const { dir } = useStyle()

    //  TODO: if ricerca e sviluppo, change icon color to #3f51b5
    const checkIsResearchAndDevelopement = () => {
        let o = directory.name.toLowerCase()
        while(o.includes(" ")) {
            o = o.replace(/\s/g, "");
        }
        return o === 'ricercaesviluppo'
    }
    const isResearchAndDevelopement = checkIsResearchAndDevelopement()

    const classes = [dir,(directory.user?.blocked === true ? 'blocked' : ''),(current?._id === directory._id ? 'selected' : '')].join(' ')

    return (
            <DirectoryContextMenu directory={directory} >
            <ListItem className={classes} selected={selected} ref={ref} button onClick={onClick} >
                <ListItemIcon style={isResearchAndDevelopement ? {color: '#3f51b5'} : {}}>
                    {directory.share ? <FolderShared /> : <Folder />}
                </ListItemIcon>
                <ListItemText primary={directory.name} />
                <IconButton size={'small'} onClick={onCollapseToggle}>
                    {hasChildren ? open ? <ExpandLess /> : <ExpandMore /> : null}
                </IconButton>
            </ListItem>
                {(hasChildren) && (
                        <Collapse style={{paddingLeft: '5px'}} in={open} timeout='auto' unmountOnExit>
                            {childrenDirectories
                                .sort((a,b) => (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0)
                                .map(d => <FileTreeDirectoryItem key={d._id} directory={d} />)}
                        </Collapse>
                )}
            </DirectoryContextMenu>
    )
})

export default View
