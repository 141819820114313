import {RootState} from "../../App";
import { createSelector } from 'reselect'
import {getMe} from "../../auth/store/selectors";
import {isSavedRoleWithRel} from "../helpers/typeGuards";

const state = (state: RootState) => state["roles-and-permission"]

export const getRoles = createSelector(
    state,
    state => state.roles
)

export const makeGetRoleById = ({id}: {id: string}) => createSelector(
    getRoles,
    roles => roles.find(role => role.id === id)
)

export const getCurrentUserRole = createSelector(
    [getRoles, getMe],
    (roles, me) => roles.find(i => isSavedRoleWithRel(i) ? i.users.some(u => u.id === me?._id) : false)
)

