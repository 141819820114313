import React from "react";
import {useSelector} from "react-redux";
import {getMe} from "../../store/selectors";

const UserIsAuthenticated: React.FC = ({ children }) => {
    const me = useSelector(getMe)

    return <>{me ? children : null}</>
}

export default UserIsAuthenticated
