import {useSelector} from 'react-redux'
import {getMe} from '../../auth/store/selectors'
import {isUser} from '../../auth/helpers/isUser'
import {useCallback} from 'react'
import {useAvailableUsers} from '../../content-manager/hook/useAvailableUsers'

export const useCanShowItem = (): (v: any) => boolean => {
  const me = useSelector(getMe)
  const users = useAvailableUsers()
  return useCallback((opt: any): boolean => {
    const actualUser = !isUser(opt) ? undefined : (opt.role && typeof opt?.role !== 'string') ? opt : users.find(v => v._id === opt._id)
    if(!actualUser) return true
    else if (me?.role?.type === 'superadmin') return true
    else if (typeof actualUser?.role !== 'string' && actualUser?.role?.type !== 'superadmin') return true
    else return false
  }, [me, users])
}
