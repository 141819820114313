import {UserWithRel} from "../types/user";
import {Record} from 'immutable'
import {JWT_TOKEN_KEY} from "./actions";

export interface State {
    me: UserWithRel | undefined,
    token: string | null,
    remember: boolean
}

const token = window.localStorage.getItem(JWT_TOKEN_KEY)

class InitialState extends Record<State>({
    me: undefined,
    token,
    remember: !!token
}) {}

const initialState = new InitialState()
export default initialState
