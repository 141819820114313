import {RootState} from "../../App";
import {createSelector} from 'reselect'

const state = (state: RootState) => state.ui

export const message = createSelector(
    state,
    state => state.message
)

export const progress = createSelector(
    state,
    state => state.progress
)

export const getConfirmation = createSelector(
    state,
    state => state.confirmation
)

export const getShowSidebar = createSelector(state, state => state.showSidebar)
