import {Directory} from "../types/directory";
import {useDispatch} from "react-redux";
import {useCallback, useState} from "react";
import {fetchDirectoryEvents} from "../store/events/directory/actions";

export const useFetchDirectoryEvents = (directory: Directory): [() => Promise<void>, boolean] => {
    const [fetching, setFetching] = useState<boolean>(false)
    const dispatch = useDispatch()
    const refresh = useCallback(async () => {
        setFetching(true)
        await dispatch(fetchDirectoryEvents(directory))
        setFetching(false)
    }, [directory, dispatch, setFetching])

    return [refresh, fetching]
}
