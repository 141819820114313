import {aclAction, AclRule} from "../../../acl.old/types/aclRule";
import React, {useCallback} from "react";
import {cloneDeep} from 'lodash'
import {Field, Form, Formik} from "formik";
import Checkbox from "ui/components/FormElements/Checkbox";
import {makeStyles} from "@material-ui/core/styles";
import {Button} from "@material-ui/core";
import {isAclRule} from "../../../content-manager/helpers/typeGuards";
import {updateAclRule} from "../../store/actions";
import {useDispatch} from "react-redux";
import {MessageType, setMessage} from "../../../ui/store/actions";

const useStyles = makeStyles({
    form: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridColumnGap: '5px',
        gridRowGap: '5px'
    },
    button: {
        gridColumn: '0, 2'
    }
})

const RuleEditor: React.FC<{rule: AclRule | null}> = ({ rule }) => {
    const classes = useStyles()
    const initialValues = rule ? cloneDeep(rule) : {write: false, delete: false, read: false, manage: false}
    const dispatch = useDispatch()
    const onSubmit = useCallback(async (values: typeof initialValues) => {
        if(isAclRule(values)) {
            await dispatch(updateAclRule(values))
            dispatch(setMessage({message: 'Permessi Aggiornati', type: MessageType.success}))
        }
    }, [dispatch, initialValues])

    const options: { label: string, key: aclAction }[] = [
         { label: 'Modificare', key: "write"},
         { label: 'Eliminare', key: "delete"},
         { label: 'Leggere', key: "read" },
         { label: 'Gestire', key: "manage" }
    ]

    return <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
        {({ dirty, submitForm, isSubmitting }) => <Form className={classes.form}>
            {options.map(o => <Field
                component={Checkbox}
                name={o.key}
                key={o.key}
                label={o.label}
                disabled={!initialValues.hasOwnProperty('id') || isSubmitting}
            />)}
            <Button className={classes.button} variant='contained' disabled={!initialValues.hasOwnProperty('id') || !dirty || isSubmitting} onClick={submitForm}>Salva</Button>
        </Form>}
    </Formik>
}

export default RuleEditor
