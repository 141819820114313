import {Directory} from "../types/directory";
import {DropTargetMonitor, useDrop} from "react-dnd";
import {useDispatch} from "react-redux";
import {FILE} from "../types/file";
import {FileDragObject} from "../components/FileItem";
import {moveFile} from "../store/actions";
import {MessageType, setMessage} from "../../ui/store/actions";

export const useDirectoryDrop = (directory: Directory, collect: (m: DropTargetMonitor) => any ) => {
    const dispatch = useDispatch()
    return useDrop({
        accept: FILE,
        drop: async (item: FileDragObject) =>  {
            await dispatch(moveFile(item.file, directory))
            dispatch(setMessage({ message: 'File spostato', type: MessageType.info}))
        },
        collect
    })
}
