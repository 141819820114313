import {IconButton, ListItem, ListItemIcon, Typography} from "@material-ui/core";
import {Delete, People, Person} from "@material-ui/icons";
import React, {useCallback, useState} from "react";
import {AclRule} from "../../../acl.old/types/aclRule";
import {makeStyles} from "@material-ui/core/styles";
import {useAvailableUsers} from "../../../content-manager/hook/useAvailableUsers";
import {useAclGroups} from "../../../acl.old/hooks/useAclGroups";
import ConfirmationDialog from "../../../ui/components/ConfirmationDialog";
import {MessageType, setMessage} from "../../../ui/store/actions";
import {deleteRule} from "../../store/actions";
import {useDispatch, useSelector} from "react-redux";
import {currentUser, getMe} from '../../../auth/store/selectors'

const useStyles = makeStyles( theme => ({
    delete: {
        color: theme.palette.error.main,
        marginLeft: 'auto'
    }
}))

const RulesSelectorItem: React.FC<{rule: AclRule, selected: boolean, onSelect: () => any}> = ({ rule, selected, onSelect}) => {

    const dispatch = useDispatch()
    const classes = useStyles()
    const users = useAvailableUsers()
    const me = useSelector(getMe)
    const groups = useAclGroups()
    const onDeleteClick = useCallback(() => {
        setShowDelete(true)
    }, [])

    const [showDelete, setShowDelete] = useState(false)
    const closeDialog = useCallback(() => {
        setShowDelete(false)
    }, [])

    const onDeleteConfirm = useCallback(async () => {
        await dispatch(deleteRule(rule))
        dispatch(setMessage({ message: 'Regola Eliminata', type: MessageType.success}))
    }, [dispatch, rule])

  const ruleUser = users.find(u => u.id === rule.user)

    const show = me?.role?.type === 'superadmin' ? true :
      (typeof ruleUser?.role !== 'string' && ruleUser?.role?.type !== 'superadmin') ? true : false

    return show ? <>
        <ListItem selected={selected} onClick={onSelect} button>
        <ListItemIcon>
            {!!rule.user ? <Person /> : <People />}
        </ListItemIcon>
        <Typography>{rule.user ? ruleUser?.username : rule.acl_group ? groups.find(g => rule.acl_group === g.id)?.name : '???'}</Typography>
        <IconButton onClick={onDeleteClick} className={classes.delete} size={'small'}>
            <Delete />
        </IconButton>
        </ListItem>
        <ConfirmationDialog open={showDelete} onCancel={closeDialog} onClose={closeDialog} onConfirm={onDeleteConfirm} />
        </> : null
}

export default RulesSelectorItem
