import aclState from "./store";
import {Actions} from "./actions";
import {AclRuleGroup} from "../types/aclRuleGroup"
import { List } from 'immutable'
import {aclAction} from "../../acl.old/types/aclRule";

const addAclRuleGroup = (groups: List<AclRuleGroup>, group: AclRuleGroup): List<AclRuleGroup> => {
    const index = groups.findIndex(g => g.id === group.id)
    if(index >= 0) {
        return groups.set(index, group)
    }
    return groups.push(group)
}

export default function(state = aclState, action: Actions): typeof aclState {
    switch (action.type) {
        case "acl/addAclRuleGroup":
            return state.set('groups', addAclRuleGroup(state.groups, action.payload))
        case "acl/setAclRuleGroups":
            return state.set('groups', List(action.payload))
        case "acl/setCurrentAclRuleGroupId":
            return state.set('currentGroupId', action.payload)
        case "acl/addPermission":
            return state.set('permissions', {...state.permissions, [action.payload[0]]: action.payload[1] as {[key in aclAction]: boolean}})
        case "acl/resetStore":
            return state.set('groups', List()).set('currentGroupId', null)
        case 'acl/setFetchedPermissions':
            return state.set('fetchedPermissions', false)
        default:
            return state
    }
}
