import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import {deleteGroup, setCurrentGroup} from "../../store/actions";
import {AclGroup} from "../../types/aclGroup";
import View from './view'
import {currentGroup as getCurrentGroup} from "../../store/selectors";

type Props = { group: AclGroup }

const SidebarAclGroupItem: React.FunctionComponent<Props> = ({ group}) => {
    const dispatch = useDispatch()
    const currentGroup = useSelector(getCurrentGroup)

    const [open, setOpen] = React.useState(false);
    const toggleOpen = () => {setOpen(!open)}
    const onDeleteConfirm = () => {
        setOpen(!open)
        if(currentGroup) {
            if(currentGroup._id === group._id) {
                dispatch(setCurrentGroup(null))
            }
        }
        dispatch(deleteGroup(group))
    }
    const onClick = () => {
        dispatch(setCurrentGroup(group))
    }
    return (
        <View key={group._id} open={open} group={group} onClick={onClick} onDeleteClick={toggleOpen} onDeleteClose={toggleOpen} onDeleteCancel={toggleOpen} onDeleteConfirm={onDeleteConfirm} />
    )
}

export default SidebarAclGroupItem
