import {aclAction} from "../../acl.old/types/aclRule";
import {Directory} from "../../content-manager/types/directory";
import {useDispatch, useSelector} from "react-redux";
import {fetchPermission} from "../store/actions";
import {useEffect} from "react";
import {AppDispatch} from "../../App";
import {IFile} from "../../content-manager/types/file";
import {isDirectory} from "../../content-manager/helpers/typeGuards";
import {makeGetPermissionByDirId} from "../store/selectors";
type Return  = {[key in aclAction]: boolean}

let fetching: (Directory | IFile)[] = []



export const useCan = (entity: Directory | IFile): Return => {
    const dispatch: AppDispatch = useDispatch()
    // @ts-ignore
    const permission = useSelector(makeGetPermissionByDirId({id : isDirectory(entity) ? entity._id : entity.directory?.id || entity.directory }))
    useEffect(() => {
        const exec = async () => {
            if(!fetching.includes(entity)) {
                fetching.push(entity)
                if(isDirectory(entity)) {
                    await dispatch(fetchPermission(entity))
                } else if (!isDirectory(entity)) {
                    await dispatch(fetchPermission(entity.directory))
                }
                fetching = fetching.filter(i => i !== entity)
            }
        }

        if(!permission) {
            exec()
        }

    }, [entity, dispatch, permission])

    return permission || {write: false, manage: false, delete: false, read: false}
}
