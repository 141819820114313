import {DirectoryEvent} from "../../../types/directoryEvent";
import {Directory} from "../../../types/directory";
import {AppThunk} from "../../../../App";
import axios from 'axios'
import {User} from "../../../../auth/types/user";

type AddEvent = {
    type: 'directoryEvent/addEvent',
    payload: DirectoryEvent
}

type AddEvents = {
    type: 'directoryEvent/addEvents',
    payload: DirectoryEvent[]
}

type SetEvents = {
    type: 'directoryEvent/setEvents',
    payload: DirectoryEvent[]
}

type RemoveEvent = {
    type: 'directoryEvent/removeEvent',
    payload: DirectoryEvent
}

type SetCurrentDirectory = {
    type: 'directoryEvent/setCurrentDirectory',
    payload: Directory | null
}

export const addEvent = (payload: DirectoryEvent): AddEvent => ({
    type: "directoryEvent/addEvent",
    payload
})

export const addEvents = (payload: DirectoryEvent[]): AddEvents => ({
    type: "directoryEvent/addEvents",
    payload
})

export const setEvents = (payload: DirectoryEvent[]): SetEvents => ({
    type: "directoryEvent/setEvents",
    payload
})

export const removeEvent = (payload: DirectoryEvent): RemoveEvent => ({
    type: "directoryEvent/removeEvent",
    payload
})

export const setCurrentDirectory = (payload: Directory | null): SetCurrentDirectory => ({
    type: "directoryEvent/setCurrentDirectory",
    payload
})


export const fetchDirectoryEvents = (directory: Directory): AppThunk<Promise<DirectoryEvent[]>> => async dispatch => {
    const { data } = await axios.get<DirectoryEvent[]>(`/directory-events?directory_eq=${directory._id}`)
    dispatch(addEvents(data))
    return data
}

export const fetchUserEvents = (user: User, sort: string | null = null): AppThunk<Promise<DirectoryEvent[]>> => async dispatch => {
    const { data } = await axios.get<DirectoryEvent[]>(`/directory-events?user_eq=${user.id}${sort ? `&_sort=${sort}` : ''}`)
    dispatch(addEvents(data))
    return data
}

export type Actions = AddEvent | SetEvents | RemoveEvent | AddEvents | SetCurrentDirectory
