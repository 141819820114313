import {DirectoryEvent} from "../../content-manager/types/directoryEvent";
import {FileEvent} from "../../content-manager/types/fileEvent";
import { List, Record } from 'immutable'
import moment, {Moment} from "moment";

type StateInterface = {
    events: List<DirectoryEvent | FileEvent>
    from: Moment | null
    to: Moment | null
    page: number
    perPage: number
    count: number
}

class Store extends Record<StateInterface>({
    events: List([]),
    from: moment().startOf('day'),
    to: moment().add(1, 'day').startOf('day'),
    page: 1,
    perPage: 20,
    count: 0
}) {}

const store = new Store()

export default store
