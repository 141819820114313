import {Moment} from "moment";
import {FileEvent} from "../../content-manager/types/fileEvent";
import {DirectoryEvent} from "../../content-manager/types/directoryEvent";
import {AppThunk} from "../../App";
import axios from 'axios'

export type Actions = SetFrom | SetTo | SetEvents | SetPage | SetPerPage | SetCount | ResetStore

type SetFrom = {
    type: 'events/setFrom',
    payload: Moment | null
}

type SetTo = {
    type: 'events/setTo',
    payload: Moment | null
}

type SetEvents = {
    type: 'events/setEvents',
    payload: (FileEvent | DirectoryEvent)[]
}

type SetPage = {
    type: 'events/setPage',
    payload: number
}

type SetPerPage = {
    type: 'events/setPerPage',
    payload: number
}

type SetCount = {
    type: 'events/setCount',
    payload: number
}

type ResetStore = {
    type: 'events/resetStore',
    payload: boolean
}

export const resetEventsStore = (payload: boolean): ResetStore => ({
    type: "events/resetStore",
    payload
})

export const setCount = (payload: number): SetCount => ({
    type: "events/setCount",
    payload
})

export const setFrom = (payload: Moment | null): SetFrom => ({
    type: "events/setFrom",
    payload
})

export const setTo = (payload: Moment | null): SetTo => ({
    type: "events/setTo",
    payload
})

export const setEvents = (payload: (FileEvent | DirectoryEvent)[]): SetEvents => ({
    type: "events/setEvents",
    payload
})

export const setPage = (payload: number): SetPage => ({
    type: "events/setPage",
    payload
})

export const setPerPage = (payload: number): SetPerPage => ({
    type: "events/setPerPage",
    payload
})

// let lastDirLimit: number | null  = null
// let lastPerPage = 0
// let dirOffset = 0

export const fetchEvents = (): AppThunk => async (dispatch, getState) => {
    const state = getState().events
    const params = {} as any
    // params._limit = state.perPage
    // params._start = state.perPage * (state.page - 1)
    if(state.from) {
        params.createdAt_gte = state.from.toDate().toISOString()
    }
    if(state.to) {
        params.createdAt_lte = state.to.toDate().toISOString()
    }

    const fileEventsCount = await axios.get<number>(`/file-events/count`, { params })
    const directoryEventsCount = await axios.get<number>(`/directory-events/count`, { params })
    await dispatch(setCount(fileEventsCount.data + directoryEventsCount.data))


    const fileEvents = await axios.get<FileEvent[]>(`/file-events`, { params })
    const directoryEvents = await axios.get<DirectoryEvent[]>(`/directory-events`, { params })

    // // Get the missing elements to complete the page
    // const missing = state.perPage - fileEvents.data.length
    // // Add the difference to the directory events offset
    // dirOffset += lastPerPage === state.perPage && lastDirLimit && lastDirLimit !== params._limit ? params._limit - lastDirLimit : 0
    // const start = params._start - dirOffset
    // let directoryEvents: any = {}
    // if(missing !== 0) {
    //     lastDirLimit = missing
    // } else {
    //     directoryEvents.data = []
    //     dirOffset += state.perPage
    // }

    dispatch(setEvents([...fileEvents.data, ...directoryEvents.data]))
    // lastPerPage = state.perPage
}

