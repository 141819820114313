import {v4} from "uuid";
import React, {MouseEvent, useState} from "react";
import {ContextMenuTrigger, ContextMenu} from "react-contextmenu";
import {List, ListItem, ListItemIcon, Paper} from "@material-ui/core";
import {AccessTime, Delete, FileCopy, GetApp, Group, LocalOffer, Pageview} from "@material-ui/icons";
import CutIcon from "../../../ui/components/CutIcon";
import ConfirmationDialog from "../../../ui/components/ConfirmationDialog";
import {useIsOnline} from '../../../network/utils'

type ComponentProps = {
    canCopy: boolean,
    isCopied: boolean,
    onCopy: (e: MouseEvent<HTMLElement>) => any,
    canCut: boolean,
    isCutted: boolean,
    onCut: (e: MouseEvent<HTMLElement>) => any,
    onDelete: () => any,
    onPermissionClick: () => any,
    onDownloadClick: () => any,
    onHistoryClick: () => any,
    onTagClick: () => any,
    canDelete: boolean,
    canWrite: boolean,
    canViewHistory: boolean,
    canDownload: boolean,
    canPreview: boolean,
    onPreviewClick: () => any,
    canManage: boolean,
    canManageTag: boolean,
    renderTag?: React.ReactType
}

type Props = ComponentProps

const View: React.FC<Props> = ({
    children,
    onHistoryClick,
    onCopy,
    onCut,
    onDelete,
    canCopy,
    canCut,
    onDownloadClick,
    onPermissionClick,
    canManage,
    canDelete,
    canWrite,
    onTagClick,
    canViewHistory,
    canDownload,
    canPreview,
    onPreviewClick,
    canManageTag,
    isCopied,
    isCutted,
    renderTag
}) => {
    const id = v4()
    const [deleteOpen, setDeleteOpen] = useState(false)
    const online = useIsOnline()

    return <>
        <ContextMenuTrigger renderTag={renderTag} id={id}>
            {children}
        </ContextMenuTrigger>
        {online && <ContextMenu id={id}>
            <Paper>
                <List>
                    {canCopy && <ListItem button onClick={onCopy} disabled={isCopied}>
                        <ListItemIcon>
                            <FileCopy />
                        </ListItemIcon>
                        Copia
                    </ListItem>}
                    {canWrite && canCut && <ListItem button onClick={onCut} disabled={isCutted}>
                        <ListItemIcon>
                            <CutIcon/>
                        </ListItemIcon>
                        Taglia
                    </ListItem>}
                    {canDelete && <ListItem button onClick={() => setDeleteOpen(true)}>
                        <ListItemIcon>
                            <Delete />
                        </ListItemIcon>
                        Elimina
                    </ListItem>}
                    {canDownload && <ListItem button onClick={onDownloadClick}>
                        <ListItemIcon>
                            <GetApp />
                        </ListItemIcon>
                        Scarica
                    </ListItem>}
                    {canManageTag && <ListItem button onClick={onTagClick}>
                        <ListItemIcon>
                            <LocalOffer />
                        </ListItemIcon>
                        Aggiungi Tag
                    </ListItem>}
                    {canManage && <ListItem button onClick={onPermissionClick}>
                        <ListItemIcon>
                            <Group />
                        </ListItemIcon>
                        Permessi
                    </ListItem>}
                    {canViewHistory && <ListItem button onClick={onHistoryClick}>
                        <ListItemIcon>
                            <AccessTime />
                        </ListItemIcon>
                        Storico Azioni
                    </ListItem>}
                    {canPreview && <ListItem button onClick={onPreviewClick}>
                        <ListItemIcon>
                            <Pageview />
                        </ListItemIcon>
                        Anteprima
                    </ListItem>}
                </List>
            </Paper>
        </ContextMenu>}
        <ConfirmationDialog open={deleteOpen} onClose={() => setDeleteOpen(false)} onCancel={() => setDeleteOpen(false)} onConfirm={onDelete} />
    </>
}

export default View
