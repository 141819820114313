import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {
    Button,
    Drawer,
    ListItemIcon,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@material-ui/core";
import {Autocomplete} from '@material-ui/lab'
import {getCurrentShare, showSidebar} from "../../store/share/selectors";
import {
    addGroupToCurrentShare,
    addUserToCurrentShare, removeGroupFromCurrentShare,
    removeUserFromCurrentShare,
    setShowSidebar
} from "../../store/share/actions";
import {makeStyles} from "@material-ui/core/styles";
import {fetchUsers} from "../../../users/userStore/actions";
import {fetchAclGroups} from "../../../acl.old/store/actions";
import {AclGroup} from "../../../acl.old/types/aclGroup";
import {User} from "../../../auth/types/user";
import {isUser} from "../../../auth/helpers/isUser";
import {People, Person} from "@material-ui/icons";
import {MessageType, setMessage} from "../../../ui/store/actions";
import ConfirmationDialog from "../../../ui/components/ConfirmationDialog";
import {useAvailableAclGroups} from "../../hook/useAvailableAclGroups";
import {useAvailableUsers} from "../../hook/useAvailableUsers";
import ShareSideBarItem from "../ShareSideBarItem";
import {fetchCurrentDirectory, fetchDirectoryById} from "../../store/actions";
import {useIsOnline} from '../../../network/utils'
import {useCanShowItem} from '../../../acl/hooks/useCanShowItem'

const useStyle = makeStyles({
    wrapper: {
        padding: '10px 15px',
        width: '60vw'
    }
})

const ShareSideBar = () => {
    const dispatch = useDispatch()
    const show = useSelector(showSidebar)
    const currentShare = useSelector(getCurrentShare)
    // const me = useSelector(currentUser)
    const availableUsers = useAvailableUsers()
    const availableGroups = useAvailableAclGroups()
    const users = currentShare?.users || []
    const groups = currentShare?.groups || []
    const mixed = [...users, ...groups]
    const mixedIds = mixed.map(i => i._id)
    const online = useIsOnline()

    const [pendingValue, setPendingValue] = useState<null | AclGroup | User>(null)
    const [showDelete, setShowDelete] = useState(false)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [entityToDelete, setEntityToDelete] = useState<null | AclGroup | User>(null)


    useEffect(() => {
        if(availableUsers.length === 0) {
            dispatch(fetchUsers())
        }
    }, [availableUsers, dispatch])

    useEffect(() => {
        if(availableGroups.length === 0) {
            dispatch(fetchAclGroups())
        }
    }, [availableGroups, dispatch])

    const onClose = () => dispatch(setShowSidebar(false))
    const classes = useStyle()

    const directoryName = currentShare?.directory.name || 'NOME SCONOSCIUTO'

    const onAdd = async () => {
        if(pendingValue) {
            isUser(pendingValue) ? await dispatch(addUserToCurrentShare(pendingValue)) : await dispatch(addGroupToCurrentShare(pendingValue))
            dispatch(setMessage({ message: (isUser(pendingValue) ? 'Utente ' : 'Gruppo ') + 'Aggiunto', type: MessageType.info}))
            dispatch(fetchCurrentDirectory())
            setPendingValue(null)
        }
    }

    const showOption = useCanShowItem()

    const onDeleteConfirm = async () => {
        if(entityToDelete) {
            isUser(entityToDelete) ? await dispatch(removeUserFromCurrentShare(entityToDelete)) : dispatch(removeGroupFromCurrentShare(entityToDelete))
            dispatch(setMessage({ message: (isUser(entityToDelete) ? 'Utente ' : 'Gruppo ') + 'Rimosso', type: MessageType.info}))
        }
        setShowDelete(false)
    }

    return online ? <Drawer open={show && !!currentShare} anchor='right' onClose={onClose}>
        <ConfirmationDialog
            open={showDelete}
            onClose={() => setShowDelete(false)}
            onCancel={() => setShowDelete(false)}
            onConfirm={onDeleteConfirm}
        />
        <div className={classes.wrapper}>
            <Typography variant='h5'>
                Condivisione "{directoryName}"
            </Typography>
            <TableContainer component={Paper}>
                <Table size={'small'}>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            Tipo
                        </TableCell>
                        <TableCell>
                            Nome
                        </TableCell>
                        <TableCell>
                            Leggere
                        </TableCell>
                        <TableCell>
                            Modificare
                        </TableCell>
                        <TableCell>
                            Eliminare
                        </TableCell>
                        <TableCell>
                            Rimuovi
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {currentShare && mixed.map(i => <ShareSideBarItem key={i._id} entity={i} directory={currentShare.directory}/>)}
                    <TableRow>
                        <TableCell colSpan={4}>
                            <Autocomplete
                                value={pendingValue}
                                onChange={(_, val) => setPendingValue(val)}
                                options={[...availableGroups, ...availableUsers].filter(i => !mixedIds.includes(i._id) && showOption(i))}
                                getOptionLabel={(i: User | AclGroup) => isUser(i) ? i.username : i.name}
                                renderInput={(params) => {
                                    return <TextField {...params} />
                                }}
                                renderOption={option => <>
                                     <ListItemIcon>
                                        {isUser(option) ? <Person /> : <People /> }
                                    </ListItemIcon>
                                    { isUser(option) ? option.username : option.name}
                                </>}
                            />
                        </TableCell>
                        <TableCell colSpan={2}>
                            <Button disabled={pendingValue === null} onClick={onAdd}>Aggiungi</Button>
                        </TableCell>
                    </TableRow>
                </TableBody>
                </Table>
            </TableContainer>
        </div>
        </Drawer> : null
}

export default ShareSideBar
