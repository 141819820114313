import {useDispatch} from "react-redux";
import {useCallback, useState} from "react";
import {fetchFileEvents} from "../store/events/file/actions";
import {IFile} from "../types/file";

export const useFetchFileEvents = (file: IFile): [() => Promise<void>, boolean] => {
    const [fetching, setFetching] = useState<boolean>(false)
    const dispatch = useDispatch()
    const refresh = useCallback(async () => {
        setFetching(true)
        await dispatch(fetchFileEvents(file))
        setFetching(false)
    }, [file, dispatch, setFetching])

    return [refresh, fetching]
}
