import {useSelector} from "react-redux";
import { get } from 'lodash'
import {useFetchRoleById} from "./useFetchRoleById";
import {getMe} from "../../auth/store/selectors";

export const useCurrentUserCan = (plugin: string, controller: string, action: string): boolean => {
    const me = useSelector(getMe)
    const [role, fetch, fetching] = useFetchRoleById({ id: me?.role?._id || '' })

    if(!role && !fetching) {
        fetch()
        return false
    }

    if(role){
        const key = `${plugin}.controllers.${controller}.${action}.enabled`
        const res = get(role.permissions, key, null)
        if(res === null) {
            console.warn(`Unable to find permission ${key}`)
            return false
        }
        return !!res
    }
    return false
}
