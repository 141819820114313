import {RootState} from "../../App";
import {createSelector} from 'reselect'

export const state = (s: RootState) => s.auth

export const currentUser = createSelector(
    state,
    (state) => state.get('me')
)

export const getMe = createSelector(
    state,
    state => state.me
)

export const remember = createSelector(
    state,
    state => state.get('remember')
)
