import React from "react";
import {Button} from "@material-ui/core";
import {logout} from "../../store/actions";
import {RootState} from "../../../App";
import {currentUser} from "../../store/selectors";
import {ThunkDispatch} from 'redux-thunk'
import {connect, ConnectedProps, useDispatch} from "react-redux";
import { useHistory } from "react-router-dom";
import {resetAclStore} from "../../../acl/store/actions";
import {resetContentManagerStore} from "../../../content-manager/store/actions";
import {resetEventsStore} from "../../../events/store/actions";
import {resetSearchStore} from "../../../search/store/actions";
import {resetUiStore} from "../../../ui/store/actions";

const mapState = (state: RootState) => {
    return {
        currentUser: currentUser(state)
    }
}

const mapActions = (dispatch: ThunkDispatch<any,any,any>) => {
    return {
        logout: () => dispatch(logout())
    }
}

const connector = connect(mapState, mapActions)
type Props = ConnectedProps<typeof connector>

const Logout = ({currentUser, logout}: Props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const onClick = () => {
        dispatch(resetContentManagerStore(true))
        dispatch(resetEventsStore(true))
        dispatch(resetSearchStore(true))
        dispatch(resetUiStore(true))
        dispatch(resetAclStore(true))
        logout()
        history.push('/')
    }

    if(currentUser) {
        return <Button color='inherit' onClick={onClick}>Logout</Button>
    }

    return <span />
}

export default connector(Logout)
