import React from 'react'
import {Checkbox, IconButton, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText} from "@material-ui/core";
import {tag} from '../../../../types/tag';
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import {addSelectedTag, removeSelectedTag, setIsChoosingTags, setIsModifyingTag, setTagToModify} from "../../../../store/tags/actions";
import {Create} from "@material-ui/icons";
import {getSelectedTagsIds} from "../../../../store/tags/selectors";

const useStyles = makeStyles((theme) => ({
    listItem: {
        width: '100%'
    },
    colorContainer: {
        width: '24px',
        height: '24px',
        borderRadius: '50%'
    }
}));

type Props = {
    tag: tag
}

const TagListItem: React.FC<Props> = ({tag}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const selectedTagsIds = useSelector(getSelectedTagsIds);

    const onclickModify = async () => {
        await dispatch(setTagToModify(tag))
        dispatch(setIsModifyingTag(true))
        dispatch(setIsChoosingTags(false))
    }

    const onchangeSelected = async () => {
        if(selectedTagsIds.includes(tag._id)) {
            dispatch(removeSelectedTag(tag))
        } else {
            dispatch(addSelectedTag(tag))
        }
    }

    return (
        <ListItem className={classes.listItem}>
            <ListItemAvatar>
                <div className={classes.colorContainer} style={{backgroundColor: tag.color}} />
            </ListItemAvatar>
            <ListItemText primary={tag.title}/>
            <ListItemSecondaryAction>
                <IconButton onClick={onclickModify} edge="end" aria-label="delete">
                    <Create />
                </IconButton>
                <Checkbox
                    edge="end"
                    onChange={onchangeSelected}
                    checked={selectedTagsIds.includes(tag._id)}
                />
            </ListItemSecondaryAction>
        </ListItem>
    )
};

export default TagListItem;
