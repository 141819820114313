import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import RolesList from "../../components/RolesList";
import RoleForm from "../../components/RoleForm";
import {useParams} from 'react-router'

const useStyle = makeStyles({
    wrapper: {
        display: 'grid',
        gridTemplateColumns: '1.3fr 5fr'
    }
})

const RolesAndPermissions = () => {
    const classes = useStyle()
    const { id } = useParams()

    return <div className={classes.wrapper}>
        <RolesList/>
        {id && <RoleForm />}
    </div>
}

export default RolesAndPermissions
