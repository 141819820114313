import React, {useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@material-ui/core";
import {Add} from "@material-ui/icons";
import Wrapper from "./wrapper";
// import styled from 'styled-components'
import { ThunkDispatch } from 'redux-thunk'
import {createUserDirectory} from "../../store/actions";
import {User} from "../../../auth/types/user";
import {connect, ConnectedProps} from "react-redux";
import {Message, MessageType, setMessage} from "../../../ui/store/actions";
import {useIsOnline} from '../../../network/utils'

// const DialogWrapper = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
// `

const mapActions = (dispatch: ThunkDispatch<any,any,any>) => {
    return {
        createDirectory: (name: string, user: User | null = null) => dispatch(createUserDirectory(name, user)),
        setMessage: (m: Message) => dispatch(setMessage(m))
    }
}

const connector = connect(null, mapActions)
type Props = ConnectedProps<typeof connector>

const CreateDirectory = ({createDirectory, setMessage}: Props) => {
    const [creating, setCreating] = useState(false)
    const [newDirName, setNewDirName] = useState('')

    const onClick = () => {
        setCreating(true)
    }

    const onDialogClose = () => {
        setCreating(false)
    }

    const onCreateClick = async () => {
        try {
            await createDirectory(newDirName)
        } catch (e) {
            setMessage({
                message: e.toString(),
                type: MessageType.error
            })

        } finally {
            onDialogClose()
            setNewDirName('')
        }
    }

    const online = useIsOnline()

    return (
        <Wrapper>
            <Button disabled={!online} color='primary' variant='outlined' onClick={onClick}>
                <Add />
                <span>Nuova Cartella</span>
            </Button>
            <Dialog onClose={onDialogClose} open={creating && online}>
                <form onSubmit={e => e.preventDefault()}>
                    <DialogTitle>Crea una cartella</DialogTitle>
                    <DialogContent>
                            <TextField autoFocus value={newDirName} onChange={e => setNewDirName(e.target.value)} label={'Nome'} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onDialogClose}>Cancella</Button>
                        <Button type='submit' disabled={newDirName.length <= 0} onClick={onCreateClick}>Crea</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </Wrapper>
    )
}

export default connector(CreateDirectory)
