import {Route, Switch} from "react-router-dom";
import AccessControl from "./acl.old/containers/AccessControl";
import UsersManager from "./users/containers/UsersManager";
import RolesAndPermissions from "./roles-and-permissions/container/RolesAndPermissions";
import ContentManager from "./content-manager/containers/ContentManager";
import React from "react";
import {useCurrentUserCan} from "./roles-and-permissions/hooks/useCurrentUserCan";
import EventsManager from "./events/containers/EventsManager";
import SearchPage from "./search/components/SearchPage";


const Routes = () => {
    const canViewFiles = useCurrentUserCan('application', 'file-item', 'find')
    const canViewDirectory = useCurrentUserCan('application', 'directory', 'findone')
    const canViewDirectories = useCurrentUserCan('application', 'directory', 'find')
    const canViewUsers = useCurrentUserCan('users-permissions', 'user', 'find')
    const canViewUser = useCurrentUserCan('users-permissions', 'user', 'findone')
    const canViewRoles = useCurrentUserCan('users-permissions', 'userspermissions', 'getroles')
    const canViewRole = useCurrentUserCan('users-permissions', 'userspermissions', 'getrole')
    const canViewGroups = useCurrentUserCan('application', 'acl-group', 'find')
    const canViewFileEvents = useCurrentUserCan('application','file-event', 'find')
    const canViewDirectoryEvents = useCurrentUserCan('application','file-event', 'find')

    const canDirectory = canViewDirectories && canViewDirectory && canViewFiles
    const canRoles = canViewRoles && canViewRole
    const canUsers = canViewRoles && canViewUser && canViewUser
    const canGroups = canViewGroups && canViewUsers
    const canEvents = canViewFileEvents && canViewDirectoryEvents


    return <Switch>
        {canEvents && <Route path='/events' component={EventsManager} exact />}
        {canGroups && <Route path='/groups' component={AccessControl} exact />}
        {canUsers && <Route path='/users' exact component={UsersManager} />}
        {canUsers && <Route path='/users/:id' exact component={UsersManager} />}
        {canRoles && <Route path='/roles' exact component={RolesAndPermissions} />}
        {canRoles && <Route path='/roles/:id' exact component={RolesAndPermissions} />}
        {canDirectory && <Route path='/' component={ContentManager} exact />}
        {canDirectory && <Route path='/search/:text' component={ContentManager} />}
        {canDirectory && <Route path='/:directoryId' component={ContentManager} />}

    </Switch>
}


export default Routes
