import initialState from "./store";
import {Actions} from './actions';
import {User} from "../../auth/types/user";

const addUsers = (state: typeof initialState, users: User[]): typeof initialState => {
    const idMap = users.map(i => i._id)
    return state.set('users', [
        ...state.users.filter(i => !idMap.includes(i._id)),
        ...users
    ])
}

export default function reducer(state = initialState, action: Actions): typeof initialState {
    switch (action.type) {
        case "user/setUsers":
            return state.set('users', action.payload)
        case "user/addUsers":
            return addUsers(state, action.payload)
        case "user/removeUser":
            return state.set('users', state.users.filter(i => i._id !== action.payload._id))
        case "user/resetStore":
            return state.set('users', []);
        default:
            return state
    }
}
