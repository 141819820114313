import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {useSelector} from 'react-redux'
import {isOnline} from '../../store/selectors'

const useStyles = makeStyles({
  banner: {
    background: 'red',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.4em',
    padding: '20px',
    transition: 'height .5s padding .5s',
    '&--hidden': {
      height: 0,
      padding: 0,
    }
  }
})

const NetworkBanner = () => {
  const { banner } = useStyles()
  const online = useSelector(isOnline)
  return <div className={`${banner} ${online ? banner + '--hidden' : ''}`}>
    {!online && <span>L'applicazione è in modalità offline. Alcune funzionalità sono limitate</span>}
  </div>
}

export default NetworkBanner
