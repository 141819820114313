import {AppThunk} from "../../../App";
import axios from "axios";
import {tag} from "../../types/tag";
import {IFile} from "../../types/file";
import {useSelector} from "react-redux";
import {getTags} from "./selectors";

export type SetSelectedItem = {
    type: "content-manager/tags/setSelectedItem",
    payload: IFile | null
}
export type SetTags = {
    type: "content-manager/tags/setTags",
    payload: tag[]
}
export type SetIsChoosingTags = {
    type: "content-manager/tags/setIsChoosingTags",
    payload: boolean
}
export type SetIsModifyingTag = {
    type: "content-manager/tags/setIsModifyingTag",
    payload: boolean
}
export type SetIsCreatingTag = {
    type: "content-manager/tags/setIsCreatingTag",
    payload: boolean
}
export type SetSelectedTags = {
    type: "content-manager/tags/setSelectedTags",
    payload: tag[]
}
export type SetTagToModify = {
    type: "content-manager/tags/setTagToModify",
    payload: tag | null
}
export type AddTag = {
    type: "content-manager/tags/addTag",
    payload: tag
}
export type AddSelectedTag = {
    type: "content-manager/tags/addSelectedTag",
    payload: tag
}
export type RemoveSelectedTag = {
    type: "content-manager/tags/removeSelectedTag",
    payload: tag
}
export type UpdateTag = {
    type: "content-manager/tags/updateTag",
    payload: tag
}
export type SetIsSaving = {
    type: "content-manager/tags/setIsSaving",
    payload: boolean
}

export const fetchTags = (): AppThunk => async dispatch => {
    const {data} = await axios.get('/tags')
    dispatch({
        type: "content-manager/tags/setTags",
        payload: data,
    });
    return data
}
export const setTagToModify = (t: tag | null): SetTagToModify => {
    return {
        type: "content-manager/tags/setTagToModify",
        payload: t,
    }
}
export const setSelectedTags = (t: tag[]): SetSelectedTags => {
    return {
        type: "content-manager/tags/setSelectedTags",
        payload: t
    }
}
export const setIsCreatingTag = (b: boolean): SetIsCreatingTag => {
    return {
        type: "content-manager/tags/setIsCreatingTag",
        payload: b
    }
}
export const setIsModifyingTag = (b: boolean): SetIsModifyingTag => {
    return {
        type: "content-manager/tags/setIsModifyingTag",
        payload: b
    }
}
export const setIsChoosingTags = (b: boolean): SetIsChoosingTags => {
    return {
        type: "content-manager/tags/setIsChoosingTags",
        payload: b
    }
}
export const fetchTagsFromIds = (s: string[]): AppThunk => async dispatch => {
    const tags = useSelector(getTags)
    const o = []
    for (const ti of s) {
        let tmp = tags.find(t => {return t._id === ti})
        if(tmp) o.push(tmp)
        else o.push(await dispatch(fetchTagById(ti)))
    }
    return o
}

export const setTags = (t: tag[]): SetTags => {
    return {
        type: "content-manager/tags/setTags",
        payload: t
    }
}
export const addSelectedTag = (t: tag): AddSelectedTag => {
    return {
        type: "content-manager/tags/addSelectedTag",
        payload: t
    }
}
export const removeSelectedTag = (t: tag): RemoveSelectedTag => {
    return {
        type: "content-manager/tags/removeSelectedTag",
        payload: t
    }
}
export const closeTagDialog = (): AppThunk => async dispatch => {
    dispatch(setIsChoosingTags(false))
    dispatch(setIsCreatingTag(false))
    dispatch(setIsModifyingTag(false))
}
export const setSelectedItem = (i: IFile | null): SetSelectedItem => {
    return {
        type: "content-manager/tags/setSelectedItem",
        payload: i
    }
}
export const addTag = (t: tag): AddTag => {
    return {
        type: "content-manager/tags/addTag",
        payload: t
    }
}

export const fetchTagById = (i: string): AppThunk => async (dispatch) => {
    const { data } = await axios.get(`/tags/${i}`)
    dispatch(addTag(data))
    return data
}

export const updateTag = (t: tag): AppThunk => async dispatch => {
     const {data} = await axios.put(`/tags/${t._id}`, t)
     await dispatch(fetchTagById(data._id))
}

export const createTag = (t: {title: string, color: string}): AppThunk => async dispatch => {
    const {data} = await axios.post("/tags", t);
    await dispatch(addTag(data))
}

export const setIsSaving = (b: boolean): SetIsSaving => {
    return {
        type: "content-manager/tags/setIsSaving",
        payload: b
    }
}

export type Actions =
    SetSelectedItem
    | SetTags
    | SetIsChoosingTags
    | SetIsModifyingTag
    | SetIsCreatingTag
    | SetSelectedTags
    | SetTagToModify
    | AddTag
    | AddSelectedTag
    | RemoveSelectedTag
    | SetIsSaving;
