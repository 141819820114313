import fileEventsStore from "./store";
import {Actions} from "./actions";
import { List } from 'immutable'
import {FileEvent} from "../../../types/fileEvent";

const addEvent = (events: List<FileEvent>, payload: FileEvent): List<FileEvent> => {
    const index = events.findIndex(i => i._id === payload._id)
    if(index === -1) {
        return events.push(payload)
    }
    return events.remove(index).push(payload)
}

const addEvents = (events: List<FileEvent>, payload: FileEvent[]): List<FileEvent> => {
    payload.forEach(event => {
        const index = events.findIndex(i => i._id === event._id)
        if(index === -1) {
            events = events.push(event)
        }
        events = events.remove(index).push(event)
    })

    return events
}

export default function (store = fileEventsStore, action: Actions): typeof fileEventsStore {
    switch (action.type) {
        case "fileEvent/addEvent":
            return store.set('events', addEvent(store.events, action.payload))
        case "fileEvent/setEvents":
            return store.set('events', List(action.payload))
        case "fileEvent/removeEvent":
            return store.set('events', store.events.remove(store.events.indexOf(action.payload)))
        case "fileEvent/addEvents":
            return store.set('events', addEvents(store.events, action.payload))
        case "fileEvent/setCurrentFile":
            return store.set('currentFile', action.payload)
        default:
            return store
    }
}
