import {useUserEvents} from "../../hooks/useUserEvents";
import {User} from "../../../auth/types/user";
import {Button, Drawer, Typography} from "@material-ui/core";
import React, {useCallback, useState} from "react";
import EventsTimeline from "../../../content-manager/components/EventsTimeline";
import moment from "moment";
import {useDispatch} from "react-redux";
import {setProgress} from "../../../ui/store/actions";
import {makeStyles} from "@material-ui/core/styles";

const useStyle = makeStyles({
    drawer: {
        padding: '20px 10px'
    }
})

const UserEvents: React.FC<{user: User}> = ({ user }) => {
    const [fetch, events, fetching] = useUserEvents(user)
    const [show, setShow] = useState(false)
    const dispatch = useDispatch()
    const classes = useStyle()

    const onButtonClick = useCallback(async () => {
       if(!show) {
           dispatch(setProgress(true, 'Ottengo lo storico dell\'utente'))
           await fetch()
           dispatch(setProgress(false))
           setShow(true)
       } else {
           setShow(false)
       }
    },[dispatch, fetch, show])

    return <>
        <Button disabled={fetching} onClick={onButtonClick}>Storico azioni</Button>
        <Drawer anchor='right' open={show} onClose={() => setShow(false)}>
            <div className={classes.drawer}>
                {events.length > 0 ?
                    <>
                        <EventsTimeline events={events.sort((a,b) => moment(b.createdAt).diff(moment(a.createdAt)))} />
                    </> : <Typography>L'utente non ha effettuato nessuna azione</Typography>}
            </div>
        </Drawer>
        </>
}

export default UserEvents
