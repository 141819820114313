import React from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@material-ui/core";
import {DirectoryRequest} from "../../store/actions";

type Props = {
    open: boolean,
    canSubmit: boolean
    directory: DirectoryRequest,
    onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => any,
    onDialogClose: () => any,
    onConfirm: () => any,
    onCancel: () => any,
}

const View = ({open, directory, onDialogClose, onInputChange, onConfirm, onCancel, canSubmit}: Props) => {
    return (
            <Dialog open={open} onClose={onDialogClose}>
                <form onSubmit={e => e.preventDefault()}>
                <DialogTitle>Crea una nuova cartella</DialogTitle>
                <DialogContent>
                    <TextField autoFocus value={directory.name} onChange={onInputChange} label={'Nome'} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel}>Cancella</Button>
                    <Button type='submit' disabled={!canSubmit} onClick={onConfirm}>Conferma</Button>
                </DialogActions>
                </form>
            </Dialog>
    )
}

export default View
