import {MessageAction, MessageType} from '../../ui/store/actions'
import {useSelector} from 'react-redux'
import {isOnline} from '../store/selectors'

export const showOfflineMessage = (): MessageAction => ({
  type: 'ui/message',
  payload: {
    message: 'Attenzione, persa connettività alla rete',
    type: MessageType.error,
  }
})

export const showOnlineMessage = (): MessageAction => ({
  type: 'ui/message',
  payload: {
    message: 'Connettività alla rete ripristinata',
    type: MessageType.success,
    timeout: 3000
  }
})

export const useIsOnline = () => useSelector(isOnline)
