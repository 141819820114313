import { createSelector } from "reselect";
import {RootState} from "../../../App";

const state = (state: RootState) => state["content-manager/tags"]

export const getSelectedItem = createSelector(
    state,
    (state) => state.selectedItem
);
export const getTags = createSelector(
    state,
    (state) => state.tags
);
export const getIsChoosingTags = createSelector(
    state,
    (state) => state.isChoosingTags
);
export const getSelectedTags = createSelector(
    state,
    (state) => state.selectedTags
);
export const getSelectedTagsIds = createSelector(
    state,
    (state) => state.selectedTags.flatMap(t => t._id)
);
export const getIsModifyingTag = createSelector(
    state,
    (state) => state.isModifyingTag
);
export const getTagToModify = createSelector(
    state,
    (state) => state.tagToModify
);
export const getIsCreatingTag = createSelector(
    state,
    (state) => state.isCreatingTag
);
export const getCurrentStep = createSelector(
    state,
    (state) => {
        if(state.isModifyingTag) {
            return 3    //  modifying
        } else if (state.isCreatingTag) {
            return 2    //  creating
        } else if (state.isChoosingTags) {
            return 1    //  choosing
        } else {
            return 0    //  nothing
        }
    }
);
export const getDefaultColors = createSelector(
    state,
    (state) => state.defaultColors
);
export const getIsSaving = createSelector(
    state,
    (state) => state.isSaving
)
