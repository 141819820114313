import initialState from "./store";
import { Actions } from "./actions";
import { List } from "immutable";

const reducer = (
    state = initialState,
    action: Actions,
): typeof initialState => {
    switch (action.type) {
        case "content-manager/setCurrentDirectory":
            return state.set("currentDirectory", action.payload).set('selectedFiles', List([]))
        case "content-manager/setDirectories":
            return state.set("directories", action.payload);
        case "content-manager/setDirectoryToCreate":
            return state.set("directoryToCreate", action.payload);
        case "content-manager/setIsCreatingDirectory":
            return state.set("isCreating", action.payload);
        case "content-manager/addDirectories":
            const idMap = action.payload.map((i) => i._id);
            return state.set("directories", [
                ...state.directories.filter((d) => !idMap.includes(d._id)),
                ...action.payload,
            ]);
        case "content-manager/setDirectoryToUpdate":
            return state.set("directoryToUpdate", action.payload)
        case "content-manager/setIsUpdatingDirectory":
            return state.set('isUpdatingDirectory', action.payload)
        case "content-manager/setSelectedFiles":
            return state.set("selectedFiles", List(action.payload));
        case "content-manager/addFiles":
            const newFilesIds = action.payload.map(i => i._id)
            return state.set('files', [
                ...state.files.filter(f => !newFilesIds.includes(f._id)),
                ...action.payload
            ])
        case "content-manager/setFiles":
            return state.set('files', action.payload)
        case "content-manager/setCopiedFile":
            return state.set('copiedFile', List(action.payload)).set('cuttedFile', List())
        case "content-manager/setCuttedFile":
            return state.set('cuttedFile', List(action.payload)).set('copiedFile', List())
        case "content-manager/setSharedIds":
            return state.set('sharedIds', action.payload)
        case "content-manager/resetStore":
            return state.set('directories', [])
                .set('files', [])
                .set('selectedFiles', List([]))
                .set('directoryToCreate', {files: [], name: "", user: undefined, parent: undefined})
                .set('isCreating', false)
                .set('directoryToUpdate', null)
                .set('isUpdatingDirectory', false)
                .set('copiedFile', List())
                .set('cuttedFile', List())
                .set('sharedIds', [])
        case "content-manager/setFolderFilter":
            return state.set('filter', action.payload)
        case "content-manager/toggleBlockedFolder":
            return state.set('showBlocked', !state.get('showBlocked'))
        case 'content-manager/highlightFile':
            return state.set('highlightedFile', action.payload)
        case 'content-manager/scrollListTo':
            return state.set('scrollListTo', action.payload)
        default:
            return state;
    }
};

export default reducer;
