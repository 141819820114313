import View from './view'
import {FILE, IFile} from '../../types/file'
import {cuttedFile, highlightedFile, makeIsSelected} from '../../store/selectors'
import React, {useEffect, useRef, useState} from "react";
import {ThunkDispatch} from 'redux-thunk'
import {RootState} from "../../../App";
import {addFiles, highlightFile, setScrollListTo, toggleFile, updateFile} from "../../store/actions";
import {connect, ConnectedProps, useDispatch, useSelector} from "react-redux";
import {Message, MessageType, setMessage} from "../../../ui/store/actions";
import FileContextMenu from "../FileContextMenu";
import {useDrag} from "react-dnd";
import {useCan} from "../../../acl/hooks/useCan";

type ComponentProps = {file: IFile}

const mapState = (state: RootState, props: ComponentProps) => {
  return {
    selected: makeIsSelected(props.file)(state)
  }
}

const mapActions = (dispatch: ThunkDispatch<any,any,any>) => {
  return {
    toggleFile: (f: IFile) => dispatch(toggleFile(f)),
    updateFile: (f: IFile) => dispatch(updateFile(f)),
    setFile: (f: IFile) => dispatch(addFiles([f])),
    setMessage: (m: Message) => dispatch(setMessage(m))
  }
}

const connector = connect(mapState, mapActions)

type Props = ComponentProps & ConnectedProps<typeof connector>

export type FileDragObject = {
    type: typeof FILE,
    file: IFile
}

const FileItem: React.FC<Props> = ({ file, toggleFile, selected, setFile, updateFile, setMessage }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [, drag] = useDrag({ item: { type: FILE, file }, canDrag: !isEditing})
  const can = useCan(file)
  const highlighted = useSelector(highlightedFile)
  const isHighlighted = highlighted?.includes(file._id ?? '')
  const ref = useRef<HTMLDivElement>(null)

  const onSubmit = async (name: string) => {
      try {
          await updateFile({...file, name})
          setMessage({ message: 'File aggiornato', type: MessageType.success})
      } catch (e) {
          setMessage({ message: `C'è stato un errore nell'aggiornamento del file: ${e}`, type: MessageType.error})
      }
  }

  useEffect(() => {
    drag(ref)
  }, [drag, ref])

  const onChange = () => {
      toggleFile(file)
  }

  const cutted = useSelector(cuttedFile).findIndex(v => v.id === file.id) !== -1

  // @ts-ignore
  return <FileContextMenu file={file}><View
    isEditing={isEditing}
    setIsEditing={setIsEditing}
    canEdit={can.write}
    ref={ref}
    onSubmit={onSubmit}
    file={file}
    onChange={onChange}
    selected={selected}
    cutted={cutted}
    highlighted={!!isHighlighted}
  /></FileContextMenu>
}

export default connector(FileItem)
