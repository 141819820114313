import {Button, ButtonProps} from "@material-ui/core";
import React, {useCallback, useState} from "react";

const ConfirmButton: React.FC<ButtonProps & {onConfirm: () => any}> = ({onConfirm, children, ...props}) => {
    const [open, setOpen] = useState(false)
    const onConfirmClick = useCallback(() => {
        setOpen(false)
        onConfirm()
    }, [setOpen, onConfirm])

    return !open ? <Button {...props} onClick={() => setOpen(true)}>
        {children}
    </Button> : <div>
            <Button {...props} disabled>Sei sicuro ?</Button>
            <Button {...props} onClick={onConfirmClick}>Si</Button>
            <Button {...props} onClick={() => setOpen(false)}>No</Button>
    </div>
}

export default ConfirmButton
