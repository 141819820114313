import initialState from "./store";
import {Actions} from './actions';

export default function reducer(state = initialState, action: Actions): typeof initialState {
    switch (action.type) {
        case "acl-group/setCurrentGroup":
            return state.set('currentGroup', action.payload)
        case "acl-group/setGroups":
            return state.set('aclGroups', action.payload)
        default:
            return state
    }
}
