import {RootState} from "../../App";
import {createSelector} from 'reselect'

const state = (state: RootState) => state.events

export const getEvents = createSelector(
    state,
    state => state.events
)

export const getPerPage = createSelector(
    state,
    state => state.perPage
)

export const getPage = createSelector(
    state,
    state => state.page
)

export const getFrom = createSelector(
    state,
    state => state.from
)

export const getTo = createSelector(
    state,
    state => state.to
)

export const getCount = createSelector(
    state,
    state => state.count
)
