import {UserWithRel} from "../types/user";
import axios from 'axios'
import {AppThunk} from "../../App";
import {fetchRoleById} from "../../roles-and-permissions/store/actions";
import {MessageType, setMessage} from "../../ui/store/actions";

export const JWT_TOKEN_KEY = 'jwt.token'

export type SetMe = {
    type: 'auth/setMe',
    payload: UserWithRel | undefined
}

const setMe = (u: UserWithRel | undefined): SetMe => {
    return {
        type: 'auth/setMe',
        payload: u
    }
}

export type SetToken = {
    type: 'auth/setToken',
    payload: string | null
}

export const setToken = (token: string | null): SetToken => {
    return {
        type: 'auth/setToken',
        payload: token
    }
}

export type SetRemember = {
    type: 'auth/setRemember',
    payload: boolean
}

export const setRemember = (v: boolean): SetRemember => {
    return {
        type: 'auth/setRemember',
        payload: v
    }
}

export const getMe = (): AppThunk => async (dispatch, getState) => {
    const res = await axios.get('/users/me')
    dispatch(setMe(res.data))
}

export type Login = { username: string, password: string }
export const login = ({username, password}: Login): AppThunk<Promise<SetMe | undefined>> => async (dispatch, getState) => {
    const res = await axios.post('/auth/local', { identifier: username, password })
    const user = res.data.user as UserWithRel
    const state = getState()
    if(state.auth.remember) {
        window.localStorage.setItem(JWT_TOKEN_KEY, res.data.jwt)
    }
    dispatch(setToken(res.data.jwt))
    dispatch(fetchRoleById({id: user.role?.id || ''}))
    return dispatch(setMe(user))
}

// type Register = { username: string, password: string, email: string}
// export const register = ({username, password, email}: Register): AppThunk<Promise<SetMe>> => async (dispatch, getState) => {
//     const res = await axios.post('/auth/local/register', {
//         username,
//         email,
//         password
//     })
//     const {confirmed, blocked} = res.data
//     const state = getState()
//     if(state.auth.remember) {
//         window.localStorage.setItem(JWT_TOKEN_KEY, res.data.jwt)
//     }
//     dispatch(setToken(res.data.jwt))
//     return dispatch(setMe({blocked, confirmed, email: res.data.email, password: res.data.password, username: res.data.username}))
// }

export const logout = (): AppThunk => async dispatch => {
    await dispatch(setToken(null))
    await dispatch(setMe(undefined))
    await dispatch(setRemember(false))
}

export type Actions = SetToken | SetMe | SetRemember
