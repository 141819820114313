import {useEffect} from "react";
import {fetchAclGroups} from "../store/actions";
import {useDispatch, useSelector} from "react-redux";
import {aclGroups} from "../store/selectors";

export const useAclGroups = () => {
    const groups = useSelector(aclGroups).toJS()
    const dispatch = useDispatch()
    useEffect(() => {
        if(groups.length === 0) {
            dispatch(fetchAclGroups())
        }
    }, [dispatch, groups])

    return groups
}
