import {Actions} from "./actions";
import store from "./store";
import {List} from 'immutable'
import moment from "moment";

const reducer = (state: typeof store = store, action: Actions): typeof store => {
    switch (action.type) {
        case "events/setEvents":
            return state.set('events', List(action.payload))
        case "events/setFrom":
            return state.set('from', action.payload)
        case "events/setTo":
            return state.set('to', action.payload)
        case "events/setPage":
            return state.set('page', action.payload)
        case "events/setPerPage":
            return state.set('perPage', action.payload)
        case "events/setCount":
            return state.set('count', action.payload)
        case "events/resetStore":
            return state.set('events', List([]))
                .set('from', moment().startOf('day'))
                .set('to', moment().add(1, 'day').startOf('day'))
                .set('page', 1)
                .set('perPage', 20)
                .set('count', 0)
        default:
            return state
    }
}

export default reducer
