import React, {useCallback} from "react";
import {getCurrentDirectory} from "../../store/events/directory/selectors";
import {useDispatch, useSelector} from "react-redux";
import {Drawer} from "@material-ui/core";
import {setCurrentDirectory} from "../../store/events/directory/actions";
import {getCurrentFile} from "../../store/events/file/selectors";
import {setCurrentFile} from "../../store/events/file/actions";
import EventsTimeline from "../EventsTimeline";
import {getCurrentDirectoryAndFileEvents} from "../../store/events/selectors";


const EventsSidebar: React.FC = () => {
    const directory = useSelector(getCurrentDirectory)
    const file = useSelector(getCurrentFile)
    const dispatch = useDispatch()

    const close = useCallback(() => {
        directory !== null ? dispatch(setCurrentDirectory(null)) : dispatch(setCurrentFile(null))
    }, [dispatch, directory])


    const events = useSelector(getCurrentDirectoryAndFileEvents).toJS()

    const show = !!(directory || file)

    const firstItem = file ? { label: 'File Creato', date: file.createdAt as string } : directory ? { label: 'Cartella Creata', date: directory.createdAt as string} : false

    return <Drawer anchor={'right'} open={show} onClose={close}>
        <div>
            {firstItem ? <EventsTimeline firstItem={firstItem} events={events} /> : <EventsTimeline events={events} /> }
        </div>
    </Drawer>
}

export default EventsSidebar
