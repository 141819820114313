import React, {memo, useEffect, useState} from 'react'
import {getSharedDirectories, getUserDirectories} from "../../store/selectors";
import {useDispatch, useSelector} from "react-redux";
import {Divider, IconButton, List, Typography} from "@material-ui/core";
import FileTreeDirectoryItem from "../FileTreeDirectoryItem";
import {fetchSharedDirectories, fetchUserDirectories} from "../../store/actions";
import {Directory} from "../../types/directory";
import {Refresh} from "@material-ui/icons";
import {MessageType, setMessage} from "../../../ui/store/actions";
import {useCurrentUserCan} from "../../../roles-and-permissions/hooks/useCurrentUserCan";
import {fetchTags} from "../../store/tags/actions";
import {makeStyles} from "@material-ui/core/styles";
import SharedDirectoriesOptions from "../SharedDirectoriesOptions";
import {currentUser} from '../../../auth/store/selectors'

const useStyles = makeStyles({
    tree: {
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        '& .top': {
            flex: '0 1 auto',
        },
        '& .shares': {
            '& .heading': {
                flex: '0 1 auto',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
            },
            '& .content': {
                flex: '1 1 auto',
                overflowY: 'auto',
            },
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto'
        },
    }
})

const Directories: React.FC<{directories: Directory[]}> = memo(({ directories }) => {
    return <> {directories
        .filter(d => !d.parent)
        .sort((a,b) => (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0)
        .sort((a,b) => ['routine', 'ricerca e sviluppo'].includes(a.name.toLowerCase()) ? -1 : 1)
        .map( directory => <FileTreeDirectoryItem key={directory._id} directory={directory} /> )}
        </>
})

const FileTree: React.FC = () => {
    const directories = useSelector(getUserDirectories)
    const shares = useSelector(getSharedDirectories)
    const canSeeDirectories = useCurrentUserCan('application', 'directory', 'find')
    const canSeeShares = useCurrentUserCan('application', 'directory', 'shared')

    const { tree } = useStyles()
    const dispatch = useDispatch()
    useEffect(() => {
        const fetch = async () => {
            await dispatch(fetchTags())
            if(canSeeDirectories) dispatch(fetchUserDirectories())
            if (canSeeShares) dispatch(fetchSharedDirectories())
        }

        fetch()
    }, [canSeeDirectories, canSeeShares, dispatch])

    const user = useSelector(currentUser)

    return (
        <List className={tree}>
            <div className="top">
                {canSeeDirectories && directories.length > 0 && <>
                    <Typography>Cartelle</Typography>
                    <Directories directories={directories} />
                </>}
                <Divider />
            </div>
                { canSeeShares && user?.role.type !== 'cliente' &&
                <div className="shares">
                    <div className="heading">
                        { canSeeDirectories && <>
                            <Typography>Condivisioni</Typography>
                            <SharedDirectoriesOptions/>
                        </>}
                    </div>
                    <div className="content">
                        <Directories directories={shares}/>
                    </div>
                </div>
                }
        </List>
    )
}

export default FileTree
