import React from 'react'
import {Button, DialogActions, DialogContent, Divider, Grid, List} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import TagListItem from "./TagListItem";
import {getIsSaving, getSelectedItem, getSelectedTags, getTags} from "../../../store/tags/selectors";
import {tag} from "../../../types/tag";
import {
    closeTagDialog, fetchTags,
    setIsChoosingTags,
    setIsCreatingTag,
    setIsModifyingTag, setIsSaving, setSelectedTags
} from "../../../store/tags/actions";
import {isFile} from "../../../helpers/typeGuards";
import {updateFile} from "../../../store/actions";

const TagsList: React.FC = () => {
    const dispatch = useDispatch()
    const tags: tag[] = useSelector(getTags)
    const selectedTags = useSelector(getSelectedTags)
    const selectedItem = useSelector(getSelectedItem)

    const isSaving = useSelector(getIsSaving)

    const onclick = async (e: any) => {
        await dispatch(setIsSaving(true))
        if(selectedItem !== null && !!selectedTags) {
            let o = selectedItem
            o.tags = [...selectedTags]
            if(isFile(o)) await dispatch(updateFile(o, true))
            await dispatch(fetchTags())
            await closeDialog()
        }
        await dispatch(setIsSaving(false))
    }
    const closeDialog = async () => {
        await dispatch(setSelectedTags([]))
        await dispatch(closeTagDialog())
    }
    const activeIsCreatingTag = async () => {
        dispatch(setIsChoosingTags(false))
        dispatch(setIsCreatingTag(true))
        dispatch(setIsModifyingTag(false))
    }

    return (
        <>
            <DialogContent style={{display: 'flex', flexDirection: 'column', height: '300px', overflow: 'auto', width: '400px'}}>
                <Grid item xs={12}>
                    <div>
                        <List>
                            {tags.length
                                ?
                                <>{
                                    tags.map(tag => <TagListItem key={tag._id} tag={tag} /> )
                                }</>
                                :
                                <span>Non ci sono tag</span>
                            }
                        </List>
                    </div>
                </Grid>
            </DialogContent>
            <Divider style={{marginBottom: '10px'}} />
            <DialogActions>
                <Button disabled={isSaving} onClick={closeDialog}>Chiudi</Button>
                <Button disabled={isSaving} onClick={activeIsCreatingTag}>Crea una nuova tag</Button>
                <Button disabled={isSaving} onClick={(e) => onclick(e)}>Salva</Button>
            </DialogActions>
        </>
    )
}

export default TagsList;
