import {RootState} from "../../App";
import { createSelector } from 'reselect'

export const state = (state: RootState) => state.acl

export const getGroups = createSelector(
    state,
    state => state.groups
)

export const getCurrentGroupId = createSelector(
    state,
    state => state.currentGroupId
)

export const getCurrentGroup = createSelector(
    [getGroups, getCurrentGroupId],
    (groups, id) => groups.find(group => group.id === id)
)

export const makeGetGroupById = (id: string) => createSelector(
    getGroups,
    groups => groups.find(g => g.id === id)
)

export const getPermissions = createSelector(
    state,
    state => state.permissions
)

export const makeGetPermissionByDirId = ({id}: {id: string}) => createSelector(
    getPermissions,
    permissions => permissions[id]
)
