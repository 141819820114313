import {FieldProps} from "formik";
import React from "react";
import {FormControlLabel, Switch as MiSwitch} from "@material-ui/core";

const Switch: React.FC<
    FieldProps & {
    label: string,
    color: 'primary' | 'secondary' | 'default'
}> = ({ field, label, color = 'primary'}) => {
    return <FormControlLabel label={label} control={<MiSwitch color={color} checked={field.value} {...field} />} />
}

export default Switch
