import React, {memo} from "react";
import { Add, Delete, Edit, GetApp} from "@material-ui/icons";
import { Typography} from "@material-ui/core";
import {isFileEvent} from "../../../content-manager/helpers/typeGuards";
import {Event} from "../../../content-manager/types/event";
import {makeStyles} from "@material-ui/core/styles";

const useStyle = makeStyles({
    main: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& svg' : {
            marginRight: '10px'
        }
    }
})

type Props = { event: Event }
const EventDescription: React.FC<Props> = ({ event }) => {

    let content, icon
    const classes = useStyle()

    if(isFileEvent(event)) {
        switch (event.action) {
            case "download":
                content = <Typography>Scaricato il file: "{event.value.name}"</Typography>
                icon = <GetApp />
                break
            case "edit":
                content = <Typography>Modificato il nome del file da"{event.value.before}" a
                    "{event.value.after}"</Typography>
                icon = <Edit />
                break
            default:
                content = <Typography>???</Typography>
                break;
        }
    } else {
        switch (event.action) {
            case "create_child":
                content = <Typography>Creata la cartella: {event.value.name}</Typography>
                icon = <Add />
                break
            case "create_file":
                content = <Typography>Creato il file: {event.value.name}</Typography>
                icon = <Add />
                break
            case "delete_child":
                content = <Typography>Eliminata la cartella: {event.value.name}</Typography>
                icon = <Delete />
                break
            case "delete_file":
                content = <Typography>Eliminato il file: {event.value.name}</Typography>
                icon = <Delete />
                break
            case "download":
                icon = <GetApp />
                content = <Typography>Scaricata la cartella: {event.value.name}</Typography>
                break
            case "edit":
                content = <Typography>Modificato il nome da della cartella "{event.value.before}" a "{event.value.after}"</Typography>
                icon = <Edit />
                break
            default:
                content = <Typography>???</Typography>
                break;
        }
    }


    return <div className={classes.main}>
            {icon}
            {content}
        </div>
}

export default memo(EventDescription)
