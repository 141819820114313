import {Directory} from "../types/directory";
import {AppThunk} from "../../App";
import axios from "axios";
import {User} from "../../auth/types/user";
import {IFile} from "../types/file";
import {MessageType, setMessage, setProgress} from "../../ui/store/actions";
import jsFileDownload from "js-file-download";

export type SetCurrentDirectory = {
    type: "content-manager/setCurrentDirectory";
    payload: Directory;
};

export type SetDirectories = {
    type: "content-manager/setDirectories";
    payload: Directory[];
};

export type SetDirectoryToCreate = {
    type: "content-manager/setDirectoryToCreate";
    payload: DirectoryRequest;
};

export type SetIsCreatingDirectory = {
    type: "content-manager/setIsCreatingDirectory";
    payload: boolean;
};

export type AddDirectories = {
    type: "content-manager/addDirectories";
    payload: Directory[];
};

export type SetSelectedFiles = {
    type: "content-manager/setSelectedFiles";
    payload: IFile[];
};

export type SetDirectoryToUpdate = {
    type: "content-manager/setDirectoryToUpdate";
    payload: Directory | null
};

export type SetIsUpdatingDirectory = {
    type: "content-manager/setIsUpdatingDirectory";
    payload: boolean
};

export type SetFiles = {
    type: 'content-manager/setFiles',
    payload: IFile[]
}

export type AddFiles = {
    type: 'content-manager/addFiles',
    payload: IFile[]
}

export type CutFile = {
    type: 'content-manager/setCuttedFile',
    payload: IFile[]
}

export type CopyFile = {
    type: 'content-manager/setCopiedFile',
    payload: IFile[]
}

export type SetSharedIds = {
    type: 'content-manager/setSharedIds',
    payload: string[]
}

export type ToggleBlockedFolders = {
    type: 'content-manager/toggleBlockedFolder'
}

export type SetFolderFilter = {
    type: 'content-manager/setFolderFilter'
    payload: string
}

export type DirectoryRequest = {
    files?: string[];
    name: string;
    user?: string;
    parent?: string;
};

export type HighlightFile = {
    type: 'content-manager/highlightFile',
    payload: string[] | undefined
}

export type ScrollListTo = {
    type: 'content-manager/scrollListTo',
    payload: number | undefined
}

type ResetStore = {
    type: 'content-manager/resetStore',
    payload: boolean
}

export const resetContentManagerStore = (payload: boolean): ResetStore => ({
    type: "content-manager/resetStore",
    payload
})

export const setCurrentDirectory = (d: Directory): SetCurrentDirectory => {
    return {
        type: "content-manager/setCurrentDirectory",
        payload: d,
    };
};

export const setDirectories = (d: Directory[]): AppThunk => dispatch => {
    const files = d.flatMap(d => d.files)
    dispatch(addFiles(files))
    dispatch({
        type: "content-manager/setDirectories",
        payload: d,
    })
};

export const setDirectoryToCreate = (
    d: DirectoryRequest,
): SetDirectoryToCreate => {
    return {
        type: "content-manager/setDirectoryToCreate",
        payload: d,
    };
};

export const setIsCreatingDirectory = (v: boolean): SetIsCreatingDirectory => {
    return {
        type: "content-manager/setIsCreatingDirectory",
        payload: v,
    };
};

export const addDirectories = (d: Directory[]): AppThunk => dispatch => {
    const files = d.flatMap(d => d.files)
    dispatch(addFiles(files))
    dispatch({
        type: "content-manager/addDirectories",
        payload: d,
    })
};

export const setSelectedFiles = (
    f: IFile[],
): SetSelectedFiles => {
    return {
        type: "content-manager/setSelectedFiles",
        payload: f,
    };
};

export const setDirectoryToUpdate = (d: Directory | null): SetDirectoryToUpdate => {
    return {
        type: "content-manager/setDirectoryToUpdate",
        payload: d
    }
}

export const setIsUpdatingDirectory = (v: boolean): SetIsUpdatingDirectory => {
    return {
        type: "content-manager/setIsUpdatingDirectory",
        payload: v
    }
}

export const setFiles = (f: IFile[]): SetFiles => ({
    type: "content-manager/setFiles",
    payload: f
})

export const addFiles = (f: IFile[]): AddFiles => ({
    type: "content-manager/addFiles",
    payload: f
})

export const setCuttedFile = (f: IFile[]): CutFile => ({
    type: "content-manager/setCuttedFile",
    payload: f
})

export const setCopiedFile = (f: IFile[]): CopyFile => ({
    type: "content-manager/setCopiedFile",
    payload: f
})

export const setSharedIds = (payload: string[]): SetSharedIds => ({
    type: "content-manager/setSharedIds",
    payload
})

export const toggleBlockedFolder = (): ToggleBlockedFolders => ({
    type: "content-manager/toggleBlockedFolder"
})

export const setFolderFilter = (payload: string): SetFolderFilter => ({
    type: "content-manager/setFolderFilter",
    payload
})

export const highlightFile = (payload: string[] | undefined ): HighlightFile => ({
    type: 'content-manager/highlightFile',
    payload
})

export const setScrollListTo = (payload: number | undefined): ScrollListTo => ({
    type: 'content-manager/scrollListTo',
    payload
})

export const toggleFile = (f: IFile): AppThunk => (
    dispatch,
    getState,
) => {
    const files = getState()["content-manager"].selectedFiles;
    if (!files.some((file) => file._id === f._id)) {
        dispatch(setSelectedFiles([...files.toJS(), f]));
    } else {
        dispatch(
            setSelectedFiles(
                files.toJS().filter((file) => file._id !== f._id),
            ),
        );
    }
};

export const createDirectory = (d: DirectoryRequest): AppThunk => async (
    dispatch,
) => {
    await axios.post("/directories", d);
    if (d.parent) {
        await dispatch(
            fetchChildrenDirectories({ _id: d.parent, files: [], name: "" }),
        );
    }
    return;
};

export const updateDirectory = (d: Directory): AppThunk => async (dispatch) => {
    const {data} = await axios.put(`/directories/${d._id}`, d);
    dispatch(addDirectories([data]))
};

export const deleteDirectory = (d: Directory): AppThunk => async (dispatch, getState) => {
    await axios.delete(`/directories/${d._id}`);
    const state = getState()
    dispatch(setDirectories(state["content-manager"].directories.filter(dir => dir._id !== d._id)))
};

export const createUserDirectory = (
    name: string,
    u: User | null = null,
): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const user = u || state.auth.me;
    if (!user?._id) {
        throw Error('Can\'t create a directory for a user without "_id" ');
    }
    await dispatch(createDirectory({ name, user: user._id }));
    await dispatch(fetchUserDirectories());
};

export const fetchUserDirectories = (u: User | null = null): AppThunk => async (
    dispatch,
    getState,
) => {
    const state = getState();
    const user = u || state.auth.me;
    if (!user?._id) {
        throw Error('Can\'t create a directory for a user without "_id" ');
    }

    let res;

    // @ts-ignore
    if(user.role.type === 'superadmin') {
        res = await axios.get('/directories');
    } else {
        res = await axios.get(`/directories?user_eq=${user._id}`);
    }


    let data: Directory[] = []

    if(!!res)
        data = res.data;
    // const missingDirectories = state["content-manager"].directories.filter(
    //     (dir) => {
    //         // Remove all directories with a user associated
    //         return !dir.user;
    //     },
    // );
    dispatch(addDirectories(data));
};

export const fetchChildrenDirectories = (d: Directory): AppThunk => async (
    dispatch,
) => {
    const res = await axios.get(`/directories?parent_eq=${d._id}`);
    const data: Directory[] = res.data;
    dispatch(addDirectories(data));
};

export const fetchDirectoryById = (id: string): AppThunk => async (dispatch) => {
    const { data } = await axios.get(`/directories/${id}`)
    dispatch(addDirectories([data]))
}

export const fetchCurrentDirectory = (): AppThunk => async (dispatch, getState) => {
    const state = getState()
    const currentDirectory = state["content-manager"].currentDirectory
    if(currentDirectory) {
        dispatch(fetchDirectoryById(currentDirectory._id))
    } else {
        dispatch(setMessage({ message: 'Nessuna cartella selezionata' , type: MessageType.error}))
    }

}

export const uploadFile = (f: File, d: Directory, refresh: boolean = true, name: string | null = null): AppThunk => async (
    dispatch,
) => {
    if(!name) {
        const parts = f.name.split('.')
        parts.pop() // remove ext
        name = parts.join('.')
    }
    const res = await axios.post("/file-items", {
        name: name,
        directory: d._id,
    });

    const fileItem = res.data;

    const formData = new FormData();
    formData.append("files", f);
    formData.append("ref", "file-item");
    formData.append("refId", fileItem._id);
    formData.append("field", "file");

    await axios.post("/upload", formData);
    if(refresh) {
        // Refresh directory data
        const { data } = await axios.get(`/directories/${d._id}`);
        dispatch(addDirectories([data]));
    }
};

export const updateFile = (f: IFile, update: boolean = true): AppThunk => async dispatch => {
    await axios.put(`/file-items/${f._id}`, f)
    if(update) {
        await dispatch(fetchDirectoryById(f.directory))
        if(f._id !== undefined) {
            dispatch(highlightFile([f._id]))
        }
    }
}

export const deleteFile = (f: IFile, fetchAfter: boolean = true): AppThunk => async (dispatch, getState) => {
    const state = getState()
    try {
        dispatch(setProgress(true, 'Elimino ' + f.name))
        await axios.delete('/file-items/' + f._id)
        dispatch(setFiles(state["content-manager"].files.filter(i => i._id !== f._id)))
        if(fetchAfter) {
            dispatch(fetchDirectoryById(f.directory))
        }
    } finally {
        dispatch(setProgress(false))
    }
}

export const deleteSelectedFiles = (): AppThunk => async (dispatch, getState) => {
    const state = getState()
    const selectedFiles = state["content-manager"].selectedFiles.toJS()
    const directoryIdsToRefetch = selectedFiles.reduce<string[]>((a, file) => {
        if(!a.includes(file.directory)) {
            a.push(file.directory)
        }
        return a
    }, [])


    for (const i in selectedFiles) {
        const file = selectedFiles[i]
        await dispatch(deleteFile(file, false))
    }

    directoryIdsToRefetch.forEach(id => {
        dispatch(fetchDirectoryById(id))
    })

    dispatch(setSelectedFiles([]))
}

export const moveFile = (file: IFile, directory: Directory, refresh: boolean = true): AppThunk => async dispatch => {
    return dispatch(updateFile({...file, directory: directory._id}, refresh))
}

export const copyFiles = (files: IFile[], directory: Directory, refresh: boolean = true): AppThunk => async dispatch => {
    const { data } = await axios.post<string[]>('/file-items/copy', {
        files,
        directory
    })
    await dispatch(fetchDirectoryById(directory._id))
    dispatch(setMessage({ message: 'File incollato', type: MessageType.info}))
    return dispatch(highlightFile(data))
}

export const pasteFiles = (directory: Directory): AppThunk => async (dispatch, getState) => {
    const state = getState()
    if(state["content-manager"].cuttedFile.size) {
        // for(const file of state['content-manager'].cuttedFile.toJS()) {
        //     await dispatch(moveFile(file, directory, false))
        // }
        await axios.post('/file-items/cut', {
            files: state['content-manager'].cuttedFile.toJS(),
            directory
        })
        dispatch(setCuttedFile([]))
    } else if(state["content-manager"].copiedFile.size) {
        dispatch(copyFiles(state['content-manager'].copiedFile.toJS(), directory))
        dispatch(setCopiedFile([]))
    }
    dispatch(fetchDirectoryById(directory._id))
}

export const downloadDirectory = (directory: Directory, onDownloadProgress: (progressEvent: any) => any = () => {}): AppThunk => async () => {
    const res = await axios.get(`/directories/${directory._id}/download`, { responseType: "arraybuffer", onDownloadProgress: onDownloadProgress})
    jsFileDownload(res.data, `${directory.name}.zip`)
}

export const downloadFile = (file: IFile, onDownloadProgress: (progressEvent: any) => any = () => {}): AppThunk => async () => {
    const res = await axios.get(`/file-items/${file._id}/download`, { responseType: "arraybuffer", onDownloadProgress: onDownloadProgress})
    jsFileDownload(res.data, `${file.name}.zip`)
}

export const fetchSharedDirectories = (): AppThunk => async dispatch => {
    const { data } = await axios.get<Directory[]>(`/directories/shared`)
    dispatch(addDirectories(data))
    dispatch(setSharedIds(data.map(i => i._id)))
}

export type Actions =
    | SetCurrentDirectory
    | SetDirectories
    | SetDirectoryToCreate
    | SetIsCreatingDirectory
    | SetSelectedFiles
    | SetIsUpdatingDirectory
    | SetDirectoryToUpdate
    | SetFiles
    | AddFiles
    | CopyFile
    | CutFile
    | SetSharedIds
    | AddDirectories
    | ToggleBlockedFolders
    | SetFolderFilter
    | HighlightFile
    | ScrollListTo
    | ResetStore;
