import React, {memo} from "react";
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot, TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator
} from '@material-ui/lab'
import {Typography} from "@material-ui/core";
import moment from "moment";
import EventsTimelineItem from "../EventsTimelineItem";
import { Event } from 'content-manager/types/event'

type Props = {
    events: Event[]
    firstItem?: {
        label: string,
        date: string
    }
}

const EventsTimeline: React.FC<Props> = ({ events, firstItem }) => {
    return <Timeline align={'left'} >
        {firstItem && <TimelineItem>
            <TimelineOppositeContent>
                <Typography color="textSecondary">{moment(firstItem.date).format('LLLL')}</Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
                <TimelineDot />
                {events.length > 0 && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>
                <Typography>{firstItem.label}</Typography>
            </TimelineContent>
        </TimelineItem> }
        {events.map((event, index) => <EventsTimelineItem
            key={event._id}
            event={event}
            isLast={events.length === index + 1}
        />)}
    </Timeline>
}

export default memo(EventsTimeline)
