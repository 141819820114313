import {Button, Card, IconButton, Popper, TextField} from "@material-ui/core";
import {Settings} from "@material-ui/icons";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchTags} from "../../store/tags/actions";
import {fetchSharedDirectories, fetchUserDirectories, setFolderFilter, toggleBlockedFolder} from "../../store/actions";
import {MessageType, setMessage} from "../../../ui/store/actions";
import {useCurrentUserCan} from "../../../roles-and-permissions/hooks/useCurrentUserCan";
import {makeStyles} from "@material-ui/core/styles";
import {folderFilter, showBlocked} from "../../store/selectors";
import {useIsOnline} from '../../../network/utils'

const useStyles = makeStyles({
    options: {
        display: 'flex',
        flexDirection: 'column',
        padding: '5px'
    }
})

const SharedDirectoriesOptions = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { options } = useStyles()
    const canViewUsers = useCurrentUserCan('users-permissions', 'user', 'find')

    // @ts-ignore
    const handleClick = (e) => {
        setAnchorEl(anchorEl ? null : e.currentTarget);
    };

    const open = Boolean(anchorEl)
    const id = open ? 'shared-directories-options' : undefined
    const canSeeShares = useCurrentUserCan('application', 'directory', 'shared')
    const dispatch = useDispatch()
    const [filter, setFilter] = [useSelector(folderFilter), (e: string) => dispatch(setFolderFilter(e))]
    const [sBlocked, toggleSBlocked] = [useSelector(showBlocked), () => dispatch(toggleBlockedFolder())]

    const [fetching, setFetching] = useState(false)

    const onRefresh = async () => {
        if(canSeeShares) {
            setFetching(true)
            await dispatch(fetchSharedDirectories())
            setFetching(false)
            dispatch(setMessage({ message: 'Condivisioni aggiornate', type: MessageType.success}))
        }
    }

    const online = useIsOnline()

    return <>
        <IconButton onClick={handleClick} aria-describedby={id} size='small' >
            <Settings />
        </IconButton>
        <Popper open={open} id={id} placement='right' anchorEl={anchorEl} >
            <Card className={options}>
                <TextField value={filter} onChange={e => setFilter(e.target.value)} variant='outlined' size='small' placeholder='cerca...' />
                {online && <Button disabled={fetching} onClick={onRefresh}>
                    {fetching ? 'Aggiornando...' : 'Aggiorna'}
                </Button> }
                {canViewUsers && <Button onClick={toggleSBlocked}>
                    {sBlocked ? 'Nascondi utenti bloccati' : 'Mostra utenti bloccati'}
                </Button>}
            </Card>
        </Popper>
        </>
}

export default SharedDirectoriesOptions
