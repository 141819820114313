import {AppThunk} from "../../App";
import axios from "axios";
import {User} from "../../auth/types/user";

export const JWT_TOKEN_KEY = 'jwt.token'

export type SetUsers = {
    type: 'user/setUsers',
    payload: User[]
}

export type AddUsers = {
    type: 'user/addUsers',
    payload: User[]
}

export type RemoveUser = {
    type: 'user/removeUser',
    payload: User
}

type ResetStore = {
    type: 'user/resetStore',
    payload: boolean
}

export const resetUsersStore = (payload: boolean): ResetStore => ({
    type: "user/resetStore",
    payload
})

export const setUsers = (u: User[]): SetUsers => ({
    type: 'user/setUsers',
    payload: u
})

export const addUsers = (u: User | User[]): AddUsers => {
    const payload = Array.isArray(u) ? u : [u]
    return {
        type: "user/addUsers",
        payload
    }
}

export const removeUser = (payload: User): RemoveUser => ({
    type: "user/removeUser",
    payload
})

export const fetchUsers = (): AppThunk => async (dispatch) => {
    const res = await axios.get(`/users`)
    const data: User[] = res.data
    dispatch(setUsers([...data]))
}

export const fetchUserById = (id: string): AppThunk => async dispatch => {
    const { data } = await axios.get<User>(`/users/${id}`)
    dispatch(addUsers(data))
}

export const updateUser = (u: User): AppThunk => async dispatch => {
    const { data } = await axios.put<User>(`/users/${u._id}`, u)
    dispatch(addUsers(data))
}

export const createUser = (u: User): AppThunk<Promise<User>> => async (dispatch) => {
    const { data } = await axios.post<User>('/users', u)
    dispatch(addUsers(data))
    return data
}


export const deleteUser = (u: User): AppThunk => async dispatch => {
    if(u._id) {
        await axios.delete(`/users/${u._id}`)
    }
    dispatch(removeUser(u))
}

export type Actions = SetUsers | AddUsers | RemoveUser | ResetStore
