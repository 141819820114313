import {AclGroup} from "../types/aclGroup";
import {Record, List} from 'immutable';

export interface State {
    aclGroups: List<AclGroup>
    currentGroup?: AclGroup | null,
}

class InitialState extends Record<State>({
    aclGroups: List([]),
    currentGroup: null,
}) {}

const initialState = new InitialState()
export default initialState
