import React, {useEffect} from "react";
import {RootState} from "../../../App";
import {currentGroup} from "../../store/selectors";
import {connect, ConnectedProps} from "react-redux";
import styled from "styled-components";
import TransferList from "../TransferList";
import {ThunkDispatch} from "redux-thunk";
import {fetchUsers} from "../../../users/userStore/actions";

const mapState = (state: RootState) => {
    return {
        currentGroup: currentGroup(state)
    }
}

const mapActions = (dispatch: ThunkDispatch<any,any,any>) => {
    return {
        fetchUsers: () => dispatch(fetchUsers()),
    }
}

const connector = connect(mapState, mapActions)
type Props = ConnectedProps<typeof connector>

const ContentContainer = styled.div`
display:flex;
flex-flow: column;
justify-content: flex-start;
align-items: center;
width: 100%;
.title {
    text-transform: uppercase;
}
 .title__container {
        display: flex;
        flex-flow: row;
        justify-content: center;
        white-space: nowrap;
    }
`

const SidebarContent = ({ currentGroup, fetchUsers }: Props) => {
    useEffect(() => { fetchUsers()}, [fetchUsers])

    if(!!currentGroup){
        return (
            <ContentContainer>
                <h2 className="title">{currentGroup.name}</h2>
                <TransferList key={currentGroup._id} currentGroup={currentGroup} />
            </ContentContainer>
        )
    } else {
        return (
            <ContentContainer>
                <h2>
                    SELEZIONA UN GRUPPO
                </h2>
            </ContentContainer>
        )
    }

}

export default connector(SidebarContent)
