import {Record} from 'immutable'
import {Share} from "../../types/share";
import {Directory} from "../../types/directory";

type State = {
    currentShare: Share | null,
    showSidebar: boolean,
    shares: Directory[]
}

class InitialState extends Record<State>({
    currentShare: null,
    showSidebar: false,
    shares: []
}) {}

const initialState = new InitialState()

export default initialState
