import {useDispatch, useSelector} from "react-redux";
import {getConfirmation} from "../../store/selectors";
import React, {useCallback} from "react";
import ConfirmationDialog from "../ConfirmationDialog";
import {setConfirmation} from "../../store/actions";

const Confirmation = () => {
    const confirmation = useSelector(getConfirmation)
    const dispatch = useDispatch()
    const onConfirm = useCallback(async () => {
        if(confirmation) {
            await confirmation.onConfirm()
        }
        dispatch(setConfirmation(null))
    }, [confirmation, dispatch])

    return <ConfirmationDialog
        open={!!confirmation}
        onClose={() => dispatch(setConfirmation(null))}
        onConfirm={onConfirm}
        onCancel={() => dispatch(setConfirmation(null))}
    />
}

export default Confirmation
