import React from "react";
import {Divider, List} from "@material-ui/core";
import styled from "styled-components";
import CreateGroup from "../CreateGroup";
import {aclGroups as getAclGroups} from "../../store/selectors";
import {useSelector} from "react-redux";
import SidebarAclGroupItem from "../SidebarAclGroupItem";

const SidebarContainer = styled.div`
width: 100%;
min-width: 200px;
display: flex;
flex-flow: column;
justify-content: space-between;
align-items: center;
height: 100%;
`
const SidebarDivider = styled(Divider)`
margin-top: 10px !important;
`

const PermissionsSidebar: React.FC = () => {
    const aclGroups = useSelector(getAclGroups)
    return (
        <SidebarContainer>
            <div>
                <CreateGroup />
                <SidebarDivider />
                <List>
                    {aclGroups.map((group, index) => (<SidebarAclGroupItem group={group} key={index} />))}
                </List>
            </div>
        </SidebarContainer>
    )
}

export default PermissionsSidebar
