import {connect, ConnectedProps} from "react-redux";
import {ThunkDispatch} from 'redux-thunk'
import React, {useCallback, useEffect, useState} from "react";
import Backdrop from "@material-ui/core/Backdrop";
import {Card, Button, CardContent, TextField, CircularProgress, Checkbox, FormControlLabel} from "@material-ui/core";
import styled from 'styled-components'
import {getMe, JWT_TOKEN_KEY, login, Login as ILogin, logout, setRemember, setToken} from "../../store/actions";
import {currentUser, remember} from "../../store/selectors";
import {RootState} from "../../../App";
import {Message, MessageType, setMessage} from "../../../ui/store/actions";
// @ts-ignore
import privacyPolicy from '../../../assets/informativa-privacy.pdf'

const getActions = (dispatch: ThunkDispatch<any, any, any> ) => {
    return {
        login: (p: ILogin) => dispatch(login(p)),
        showMessage: (p: Message) => dispatch(setMessage(p)),
        setRemember: (v: boolean) => dispatch(setRemember(v)),
        getMe: () => dispatch(getMe()),
        logout: () => dispatch(logout())
    }
}

const getState = (state: RootState) => {
    return {
        currentUser: currentUser(state),
        remember: remember(state)
    }
}

const connector = connect(getState, getActions)

type Props = ConnectedProps<typeof connector>

const Wrapper = styled.div`
  .card-content form {
    display: flex;
    flex-direction: column;
    & > input {
      margin-top: 10px;
    }
    
    & > button {
      margin-top: 20px;
    }
  }
`

const Login = ({currentUser, login, showMessage, setRemember, remember, getMe, logout}:Props) => {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [email, setEmail] = useState('')
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isLoggingIn, setIsLogginIn] = useState(true)
    const [loading, setLoading] = useState(false)
    const [approvePrivacy, setApprovePrivacy] = useState(true)

    const canLogin = username.length > 0 && password.length > 0 && approvePrivacy
    const canRegister = canLogin && email.length > 0

    useEffect(() => {
        if(remember) {
            try {
                setLoading(true)
                getMe()
            } finally {
                setLoading(false)
            }
        }
    }, [])

    const onClick = async () => {
        try {
            setLoading(true)
            if(isLoggingIn) await login({username, password})
        } catch (e) {
            console.log(e);
            showMessage({
                type: MessageType.error,
                message: <span>Username o password errati</span>,
            })
        } finally {
            setLoading(false)
        }
    }

    return (
    <Wrapper>
        <Backdrop open={!currentUser}>
            <Card>
                <CardContent className='card-content'>
                    <span>Benvenuto, effettua il login</span>
                    <br/>
                    <form onSubmit={e => e.preventDefault()}>
                        <TextField autoComplete="off" value={username} onChange={e => setUsername(e.target.value)} label='username'/>
                        { !isLoggingIn && <TextField value={email} onChange={e => setEmail(e.target.value)} label={'email'} /> }
                        <TextField autoComplete="off" label='password' type='password' value={password} onChange={e => setPassword(e.target.value)}/>

                        <FormControlLabel
                            control={<Checkbox disabled={username.length === 0 || password.length === 0} checked={remember || false} onChange={(e) => setRemember(e.target.checked)} />}
                            label={'Ricordami'}
                        />
                      {/*<FormControlLabel*/}
                      {/*  control={<Checkbox checked={approvePrivacy} onChange={(e) => setApprovePrivacy(e.target.checked)} />}*/}
                      {/*  label={<span>Accetto la <a target="_blank" href={privacyPolicy}>privacy policy</a></span>}*/}
                      {/*/>*/}
                      <Button type='submit' disabled={isLoggingIn ? !canLogin : !canRegister} onClick={onClick} color='primary' variant='contained'>{isLoggingIn ? 'Log In' : 'Registrati'}{loading && <CircularProgress color='inherit' size={13} />}</Button>
                    </form>
                    {/*<Button color='primary' onClick={onToggleClick}>{isLoggingIn ? 'Registrati' : 'Log In'}</Button>*/}
                </CardContent>
            </Card>
        </Backdrop>
    </Wrapper>
    )
}

export default connector(Login)
